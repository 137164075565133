import React, { useState, useEffect, useCallback } from 'react';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import RoadModelTable from './RoadModelTable';

import './RoadModel.css';

import { request, formatTime, sortBy } from '../utils';

const menu = [{
  label: 'Roadway',
  key: 'rws',
}, {
  label: 'O/D Pairs',
  key: 'ods',
}, {
  label: 'Tolling Zones',
  key: 'tzs',
}];

const RoadModel = ({ history }) => {
  const env = window.localStorage.getItem('env');
  const s3bucketName = `s3://got-${env}-asset-road-models`;

  const [currentAssetId, setCurrentAssetId] = useState(null);
  const [assetIds, setAssetIds] = useState([]);
  const [list, setList] = useState([]);
  const [listAll, setListAll] = useState([]);
  const [roadModelFilename, setRoadModelFilename] = useState(null);
  const [data, setData] = useState({
    links: [],
    nodes: [],
    roadways: [],
    pricinZones: [],
    tollingZones: [],
  });

  const [checkbox, setCheckbox] = useState({
    rws: false,
    ods: true,
    tzs: false,
    pzs: false,
  });

  const load = useCallback(async () => {
    const queryParams = queryString.parse(history.location.search);
    const options = {
      api: `/api/roadModels`,
      method: 'GET',
      mode: 'cors',
    };

    const { data } = await request(options);

    const assetIds = [];
    const listAll = [];
    data
      .sort(sortBy('LastModified', true))
      .forEach((item) => {
        const id = item.Key.split('-road-model-')[0];
        if (!assetIds.includes(id)) {
          assetIds.push(id);
        }
        listAll.push(item);
      });


    setAssetIds(assetIds.sort((a, b) => a > b ? 1 : -1));
    setListAll(listAll);
    setCurrentAssetId(queryParams.assetId || assetIds[0]);
  }, [history]);

  useEffect(() => {
    (async () => {
      if (currentAssetId) {
        const queryParams = queryString.parse(history.location.search);
        queryParams.assetId = currentAssetId;

        history.push({
          pathname: history.location.pathname,
          search: queryString.stringify(queryParams),
        });

        const list = listAll.filter((item) => item.Key.startsWith(currentAssetId));
        setList(list);
        setRoadModelFilename(list[0].Key);
      }
    })();
  }, [currentAssetId, history, listAll]);

  useEffect(() => {
    (async () => {
      if (roadModelFilename) {
        const options = {
          api: `/api/roadModels`,
          query: {
            key: roadModelFilename,
          },
          method: 'GET',
          mode: 'cors',
        };

        const { data } = await request(options);
        setData({
          links: data.links,
          nodes: data.nodes,
          roadways: data.nodes.filter(({ type }) => type === 'roadway'),
          tollingZones: data.nodes.filter(({ type }) => type === 'tolling_zone'),
        });
      }
    })();
  }, [roadModelFilename]);

  useEffect(() => {
    (async () => {
      await load();
    })();
  }, [load]);

  useEffect(() => {
    console.log('re-render');
    console.log('checkbox', checkbox);
  });

  if (!currentAssetId || !roadModelFilename) return null;

  return (
    <Grid container className="main" justify="center" spacing={2} style={{ padding: 16 }}>
      <Grid item xs={12}>
        <Paper className="app-container">
          <Grid item xs={12}>
            <Typography variant="h5">
              Road Models
            </Typography>
          </Grid>

          <Grid container>
            <Grid item xs={12} sm={2}>
              <FormControl fullWidth>
                <TextField
                  id="source"
                  label="Source Bucket"
                  value={s3bucketName}
                  margin="normal"
                  disabled={true}
                  fullWidth
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={5}>
              <FormControl fullWidth className="select-align-container">
                <InputLabel htmlFor="age-native-simple">Asset IDs</InputLabel>
                <Select
                  native
                  value={currentAssetId}
                  onChange={(event)=>{
                    setCurrentAssetId(event.target.value);
                  }}
                  inputProps={{
                    name: 'assetId',
                    id: 'asset-id',
                  }}
                >
                  {assetIds.map((listItem)=>{
                    return (
                      <option
                        key={listItem}
                        value={listItem}
                        disabled={!listItem.includes('road-model.json')}>
                        {listItem}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={5}>
              <FormControl fullWidth className="select-align-container">
                <InputLabel htmlFor="age-native-simple">Road Model JSON (versions)</InputLabel>
                <Select
                  native
                  value={roadModelFilename}
                  onChange={(event)=>{
                    setRoadModelFilename(event.target.value);
                  }}
                  inputProps={{
                    name: 'roadModel',
                    id: 'roadModel-id',
                  }}
                >
                  {list
                    .map((listItem)=>{
                      return (
                        <option
                          key={listItem.Key}
                          value={listItem.Key}
                        >
                          {listItem.Key} ({formatTime(listItem.LastModified)})
                        </option>);
                    })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <FormGroup row className="space">
            {
              menu.map((menuItem, index)=>{
                const { key, label } = menuItem;
                return (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        checked={checkbox[key]}
                        onChange={()=>{
                          setCheckbox({
                            ...checkbox,
                            [key]: !checkbox[key],
                          });
                        }}
                        color="primary"
                      />
                    }
                    label={label}
                  />
                );
              })
            }
          </FormGroup>
        </Paper>
      </Grid>
      {checkbox.rws &&
        <Grid item xs={12}>
          <RoadModelTable title="Roadway" data={data.roadways} />
        </Grid>
      }
      {checkbox.ods &&
        <Grid item xs={12}>
          <RoadModelTable
            title="OD"
            data={data.links}
          />
          <br/>
          <br/>
        </Grid>
      }
      {checkbox.tzs &&
        <Grid item xs={12}>
          <RoadModelTable title="Tolling Zones" data={data.tollingZones} />
        </Grid>
      }
    </Grid>);
};

RoadModel.propTypes = {
  history: PropTypes.object,
};

export default RoadModel;
