/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { validate as uuidValidate } from 'uuid';

import {
  request,
} from '../utils';

const CaseAccountIcon = ({ caseItem }) => {
  if (!caseItem.accountID) {
    return null;
  }

  return (
    <IconButton
      className="open-in-new-button"
      color="inherit"
      onClick={() => window.open(`/accountDetail?env=${window.localStorage.getItem('env')}&accountId=${caseItem.accountID}`, '_blank')}
      aria-label="open"
      disabled={!caseItem.accountID}>
      <OpenInNewIcon />
    </IconButton>
  );
};

function ReadOnlyTextField(props) {
  return (
    <TextField
      readOnly={true}
      fullWidth
      InputLabelProps={{ shrink: true }}
      {...props}
    />
  );
}

const CaseAccountContent = ({ account, caseItem, onUpdate }) => {
  const [candidates, setCandidates] = useState([]);
  const [accountID, setAccountID] = useState(caseItem.accountID || '');
  const [errorMsg, setErrorMsg] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const handleCaseAccountUpdate = async (accountID) => {
    setIsSaving(true);
    try {
      const options = {
        api: `/api/customerCases/${caseItem.caseID}/account`,
        method: 'PUT',
        body: JSON.stringify({ accountID }),
        mode: 'cors',
      };

      const res = await request(options);
      console.log(res);

      if (onUpdate) {
        onUpdate(accountID);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    (async () => {
      console.log(caseItem);
      const options = {
        api: `/api/accountByEmail`,
        query: {
          email: caseItem.email,
        },
        method: 'GET',
        mode: 'cors',
      };

      const { data } = await request(options);
      if (data) {
        setCandidates([data]);
        // setAccountID(data.accountId);
      }
    })();
  }, [caseItem]);

  if (!caseItem.accountID) {
    // The case has not been assigned to a customer account.
    return (
      <Grid container spacing={1}>
        <Grid item xs={8}>
          <Autocomplete
            id="accountId"
            freeSolo
            fullWidth
            options={candidates.map(({ accountId }) => accountId)}
            // getOptionLabel={({ accountId, firstName, lastName }) => `${firstName} ${lastName} ${accountId}`}
            onChange={(e, value)=> {
              if (!uuidValidate(value)) {
                setErrorMsg('Not a valid account Id');
              } else {
                setAccountID(value);
                setErrorMsg();
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                readOnly={false}
                label="Account ID"
                value={accountID}
                error={errorMsg ? true : false}
                helperText={errorMsg}
                disabled={isSaving}
                // onChange={updateAccountId}
                onChange={(e, value)=> {
                  if (!uuidValidate(e.target.value)) {
                    setErrorMsg('Not a valid account Id');
                  } else {
                    setAccountID(e.target.value);
                    setErrorMsg();
                  }
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            disabled={isSaving || !accountID || (errorMsg ? true : false)}
            onClick={() => handleCaseAccountUpdate(accountID)}
          >
            Assign
          </Button>
        </Grid>
        <Grid item xs={12}>
          <ReadOnlyTextField label="Name" value={caseItem.name || ' '} />
        </Grid>
        <Grid item xs={12}>
          <ReadOnlyTextField label="Email" value={caseItem.email || ' '} />
        </Grid>
      </Grid>
    );
  }

  // The case has been assigned to a customer account.
  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <ReadOnlyTextField label="Account ID" value={caseItem.accountID || ' '} />
      </Grid>
      <Grid item xs={6}>
        <ReadOnlyTextField label="Okta ID" value={account.oktaId || ' '} />
      </Grid>
      <Grid item xs={12}>
        <ReadOnlyTextField label="Status" value={account.status || ' '} />
      </Grid>
      <Grid item xs={12}>
        <ReadOnlyTextField label="Name" value={`${account.firstName} ${account.lastName}`} />
      </Grid>
      <Grid item xs={12}>
        <ReadOnlyTextField label="Email" value={account.email || ' '} />
      </Grid>
      <Grid item xs={6}>
        <ReadOnlyTextField label="Platform" value={account.platform || ' '} />
      </Grid>
      <Grid item xs={6}>
        <ReadOnlyTextField label="Device" value={account.device ? account.device.name : ' '} />
      </Grid>
      <Grid item xs={6}>
        <ReadOnlyTextField label="App Version" value={account.app ? account.app.version : ' '} />
      </Grid>
      <Grid item xs={6}>
        <ReadOnlyTextField label="App Build" value={account.app ? account.app.build : ' '} />
      </Grid>
    </Grid>
  );
};

export default function CaseAccount({ account, caseItem, onUpdate }) {
  return (
    <Card className="case-details-card">
      <CaseAccountIcon caseItem={caseItem} />
      <CardHeader title="Account Details" />
      <CardContent>
        <CaseAccountContent account={account} caseItem={caseItem} onUpdate={onUpdate} />
      </CardContent>
    </Card>
  );
}
