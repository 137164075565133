import openSocket from 'socket.io-client';

let io;
export default {
  register(inDomain) {
    io = openSocket(inDomain);

    return io;
  },

  getIo() {
    return io;
  },
};
