import React, { useState, useEffect } from 'react';

import {
  TagFaces,
} from '@material-ui/icons';

import './Users.css';

import socket from '../socket';

import Table from '../components/Table';

const Users = () => {
  const [data, setData] = useState([]);

  const refresh = () => {
    console.log('refresh');
    const io = socket.getIo();
    io.on('onlineUsers', (message) => {
      setData(message);
    });
    io.emit('refreshOnlineUsers');
  };

  useEffect(() => {
    setTimeout(() => {
      refresh();
    }, 2000);
  }, []);

  const headers = [{
    name: 'isOnline',
    label: 'Online Status',
    options: {
      filter: false,
      customBodyRender(value) {
        return (value ? <TagFaces /> : '');
      },
    },
  }, {
    name: 'team',
    label: 'Team',
    options: {
      filter: true,
      customBodyRender(value) {
        return (`${value}`.toUpperCase());
      },
    },
  }, {
    name: 'role',
    label: 'Role',
    options: {
      filter: true,
      customBodyRender(value) {
        return (`${value}`.toUpperCase());
      },
    },
  }, {
    name: 'name',
    label: 'Name',
    options: {
      filter: false,
    },
  }, {
    name: 'nameAlias',
    label: 'Alias Name',
    options: {
      filter: false,
    },
  }, {
    name: 'email',
    label: 'Email',
    options: {
      filter: false,
    },
  }, {
    name: 'env',
    label: 'Env',
    options: {
      filter: false,
    },
  }, {
    name: 'postmanUserId',
    label: 'Postman User ID',
    options: {
      filter: false,
    },
  }, {
    name: 'slackId',
    label: 'Slack ID',
    options: {
      filter: false,
    },
  }, {
    name: 'isActive',
    label: 'Active',
  }];

  const onItemClick = (rowData, rowMeta) => {
    const item = data[rowMeta.dataIndex];
    console.log(item);
  };

  const options = {
    filterType: 'checkbox',
    onRowClick: onItemClick,
    rowsPerPageOptions: [10, 100, 200],
    rowsPerPage: 100,
    expandableRows: true,
    isRowExpandable: () => false,
  };

  return (
    <Table
      title={'Users'}
      data={data}
      columns={headers}
      options={options}
      onRefresh={refresh}
    />);
};

export default Users;
