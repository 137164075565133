import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import DeleteForever from '@material-ui/icons/DeleteForever';

import { withRouter } from 'react-router-dom';
import { request } from '../utils';

const defaultMenu = [
  // { label: 'account', value: 'accounts-accounts', isChecked: false, isDisabled: true },
  { label: 'notifications', value: 'communication-notifications', isChecked: true },
  { label: 'cases', value: 'communication-customer-cases', isChecked: true },
  { label: 'cases messages', value: 'communication-customer-messages', isChecked: true },
  { label: 'vdot transactions', value: 'external-vdot-transactions', isChecked: true },
  { label: 'chase cards', value: 'payments-chase-profiles', isChecked: true },
  { label: 'chase transactions', value: 'payments-chase-transactions', isChecked: true },
  { label: 'trips', value: 'trips-trips', isChecked: true },
  { label: 'vehicle changes', value: 'vehicles-vehicle-changes', isChecked: true },
  { label: 'vechicle', value: 'vehicles-vehicles', isChecked: true },
  { label: 'promotion credits', value: 'promotion-credits', isChecked: true },
];

const PurgeAccountModal = ({ accountId, account = {}, onUpdate, history }) => {
  const env = window.localStorage.getItem('env');

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [shouldUnregister, setShouldUnregister] = useState(false);
  const [menu, setMenu] = useState(defaultMenu);


  const purgeAccount = async () => {
    if (env === 'prd') return null;

    if (!window.confirm('Are you sure to purge this account?')) {
      return;
    }

    setLoading(true);

    const ignoreList = menu
      .filter((item) => !item.isChecked)
      .map((item) => item.value);

    const options = {
      api: `/api/accountCleanup`,
      query: {
        accountId: accountId || account.mobile,
        phone: account.mobile.replace('+1', ''),
        ignores: ignoreList,
      },
      method: 'GET',
      mode: 'cors',
    };

    await request(options);

    if (shouldUnregister) {
      return unregister();
    }

    if (onUpdate) {
      onUpdate();
    }

    setLoading(false);
    setOpen(false);
  };

  const unregister = async () => {
    if (env === 'prd') return null;

    setLoading(true);

    const options = {
      api: `/api/accountUnregister`,
      query: {
        phone: account.mobile.replace('+1', ''),
      },
      method: 'GET',
      mode: 'cors',
    };

    await request(options);

    history.push(`/account`);
  };

  useEffect(() => {
    if (shouldUnregister) {
      menu.forEach((item) => {
        item.isChecked = true;
      });
      setMenu(menu);
    }
  }, [shouldUnregister, menu]);

  if (env === 'prd') return null;

  return (
    <Fragment>
      <Button
        variant="contained"
        color="secondary"
        onClick={()=> setOpen(true)}
        disabled={loading || !env || !account}>
        <DeleteForever /> Purge
      </Button>
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={open}
        onClose={()=> setOpen(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Purge Account Data
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12}>
              <FormGroup row className="space">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={shouldUnregister}
                      onChange={(event)=>setShouldUnregister(!shouldUnregister)}
                      color="primary"
                    />
                  }
                  label="Unregister & Purge all data"
                />
              </FormGroup>
              <FormGroup row className="space">
                {
                  menu.map((item, index)=>{
                    return (
                      <FormControlLabel
                        key={index}
                        control={
                          <Checkbox
                            disabled={shouldUnregister}
                            checked={item.isChecked}
                            onChange={(event)=>{
                              item.isChecked = !item.isChecked;
                              setMenu([...menu]);
                            }}
                            color="primary"
                          />
                        }
                        label={item.label}
                      />
                    );
                  })
                }
              </FormGroup>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {/* <Typography variant="body1" color="error">
            {errorMsg}
          </Typography> */}
          <Button onClick={()=>setOpen(false)} color="default" disabled={loading}>
            Cancel
          </Button>
          <Button variant="contained" onClick={purgeAccount} color="secondary" disabled={loading}>
            Purge
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

PurgeAccountModal.propTypes = {
  accountId: PropTypes.string,
  account: PropTypes.object,
  onUpdate: PropTypes.func,
  history: PropTypes.object,
};

export default withRouter(PurgeAccountModal);
