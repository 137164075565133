import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Table from './Table';
import { request } from 'utils';

export default function VendorAccountVehicleTable({ vendor, account }) {
  const [data, setData] = useState([]);
  const onItemClick = (rowData, rowMeta) => {
    const item = data[rowMeta.dataIndex];
    console.log(item);
  };

  const headers = [{
    name: 'vendor',
    label: 'Vendor',
    options: {
      filter: true,
      sort: true,
    },
  }, {
    name: 'vendorAccount',
    label: 'Account',
    options: {
      filter: true,
      sort: true,
    },
  }, {
    name: 'lpnState',
    label: 'LPN State',
    options: {
      filter: false,
      sort: true,
    },
  }, {
    name: 'createdDate',
    label: 'Created At',
    options: {
      filter: false,
      sort: true,
    },
  }];

  const options = {
    filterType: 'checkbox',
    onRowClick: onItemClick,
    rowsPerPageOptions: [10, 50, 100],
    rowsPerPage: 10,
  };

  useEffect(() => {
    if (!vendor || !account) return;
    (async () => {
      const options = {
        api: `/api/vendor/${vendor}/account/${account}/vehicles`,
        method: 'GET',
        mode: 'cors',
      };
      const { data } = await request(options);
      setData(data);
    })();
  }, [vendor, account]);

  return (
    <Table
      title={'Vendor Account Vehicles'}
      data={data}
      columns={headers}
      options={options}
      nested={true}
    />);
}

VendorAccountVehicleTable.propTypes = {
  vendor: PropTypes.string,
  account: PropTypes.string,
};
