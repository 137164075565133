import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import Table from './Table';
import HistoryTable from './HistoryTable';
import {
  tableCellTimeRenderer,
  tableCellAccountIdRenderer,
  request,
} from '../utils';

const ReferralTable = ({
  title = 'Referrals',
  accountId,
  nested,
}) => {
  const [referrals, setReferrals] = useState([]);

  useEffect(() => {
    (async () => {
      if (accountId) {
        const options = {
          api: `/api/account/${accountId}/referrals`,
          method: 'GET',
          mode: 'cors',
        };
        const { data } = await request(options);
        setReferrals(data);
      }
    })();
  }, [accountId]);

  return (
    <Grid item xs={12}>
      {renderTable(title, referrals, nested)}
    </Grid>);
};

ReferralTable.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
  accountId: PropTypes.string,
  nested: PropTypes.bool,
};

export default ReferralTable;

const renderTable = (title, data, nested) => {
  const headers = [{
    name: 'id',
    label: 'ID',
    options: {
      filter: false,
    },
  }, {
    name: 'referralCode',
    label: 'Referral Code',
    options: {
      filter: false,
    },
  }, {
    name: 'referrerAccountId',
    label: 'Referrer Account ID',
    options: {
      filter: false,
      customBodyRender: tableCellAccountIdRenderer(),
    },
  }, {
    name: 'referredAccountId',
    label: 'Referred Account ID',
    options: {
      filter: false,
      customBodyRender: tableCellAccountIdRenderer(),
    },
  }, {
    name: 'status',
    label: 'Status',
    options: {
      filter: true,
    },
  }, {
    name: 'redeemedAt',
    label: 'Redeemed At',
    options: {
      filter: false,
      customBodyRender: tableCellTimeRenderer(true),
    },
  }, {
    name: 'fulfilledAt',
    label: 'Fulfilled At',
    options: {
      filter: false,
      customBodyRender: tableCellTimeRenderer(true),
    },
  }];

  const onItemClick = (rowData, rowMeta) => {
    const item = data[rowMeta.dataIndex];
    console.log(item);
  };

  const options = {
    filterType: 'checkbox',
    onRowClick: onItemClick,
    rowsPerPageOptions: [10, 50, 100],
    rowsPerPage: 10,
    expandableRows: true,
    renderExpandableRow: (rowData, rowMeta) => {
      console.log('renderExpandableRow', rowData, rowMeta);
      const item = data[rowMeta.dataIndex];

      return (
        <TableRow>
          <TableCell colSpan={headers.length + 1} className="nested-table-container">
            <HistoryTable referral={item} nested={true} />
          </TableCell>
        </TableRow>
      );
    },
  };

  return (
    <Table
      title={title}
      data={data}
      columns={headers}
      options={options}
      nested={nested}
    />);
};
