import React, { useState, useEffect, useCallback, Fragment } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import Table from './Table';

import {
  tableCellTimeRenderer,
  tableCellAccountIdRenderer,
  tableCellBooleanRenderer,
  tableCellStatusRenderer,
  tableCellImageRenderer,
  tableCellCurrencyRenderer,
  tableCellTripIdRenderer,
  sortBy,
  request,
  formatCurrency,
} from '../utils';

import './TripTable.css';
import TripTableDetails from './TripTableDetails';


const TripTable = ({
  title = 'Trips',
  data: inData,
  target = 'trips',
  tripId,
  accountId,
  nested,
}) => {
  const env = window.localStorage.getItem('env');
  const urlPrefix = `https://s3.amazonaws.com/got-${env}-static/images/routes_ods`;
  const [trips, setTrips] = useState([]);

  const load = useCallback(async () => {
    if (tripId) {
      const options = {
        api: `/api/trips/${tripId}`,
        method: 'GET',
        mode: 'cors',
      };
      const { data } = await request(options);
      setTrips([data]);
    } else
    if (accountId) {
      const options = {
        api: `/api/account/${accountId}/${target}`,
        method: 'GET',
        mode: 'cors',
      };
      const { data } = await request(options);
      setTrips(data);
    }
  }, [accountId, target, tripId]);

  useEffect(() => {
    if (inData) {
      setTrips(inData);
    } else {
      load();
    }
  }, [inData, load]);

  const headers = [{
    name: 'tripId',
    label: 'ID',
    options: {
      filter: false,
      customBodyRender: tableCellTripIdRenderer(),
    },
  }, {
    name: 'accountId',
    label: 'Account ID',
    options: {
      filter: false,
      customBodyRender: tableCellAccountIdRenderer(),
    },
  }, {
    name: 'imgUrl',
    label: 'Image',
    options: {
      filter: false,
      display: true,
      customBodyRender: tableCellImageRenderer(),
    },
  }, {
    name: 'lpn',
    label: 'LPN',
    options: {
      filter: false,
      display: false,
    },
  }, {
    name: 'tripStatus',
    label: 'Status',
    options: {
      customBodyRender: tableCellStatusRenderer({
        PENDING: 0,
        PENDING_GPS_MERGED: 0,
        REFUNDED: 1,
        PAID: 1,
        PAID_GPS_MERGED: 1,
        REJECTED: 2,
        PENDING_DUE: 2,
      }),
    },
  }, {
    name: 'processStatus',
    label: 'Process',
    options: {
      customBodyRender: tableCellStatusRenderer({
        0: 0,
        1: 2,
        2: 2,
        99: 1,
      }),
    },
  }, {
    name: ' ',
    label: 'Process At',
    options: {
      filter: false,
      display: false,
      customBodyRender: tableCellTimeRenderer(true),
    },
  }, {
    name: 'isAudited',
    label: 'Audited',
    options: {
      display: false,
      customBodyRender: tableCellStatusRenderer(),
    },
  }, {
    name: 'lifecycleCode',
    label: 'Lifecycle Code',
    options: {
      display: false,
      customBodyRender: tableCellStatusRenderer({
        new_trip: 0,
        new_gps_trip: 0,
        new_asset_trip: 0,
        new_asset_trip_reminder: 0,
        new_asset_trip_payment: 1,
        new_gps_trip_incomplete: 1,
      }),
    },
  }, {
    name: 'origin',
    label: 'Origin',
  }, {
    name: 'assetId',
    label: 'Asset ID',
  }, {
    name: 'assetSerialNo',
    label: 'Asset Serial No',
    options: {
      filter: false,
      display: false,
    },
  }, {
    name: 'direction',
    label: 'Direction',
    options: {
      filter: true,
      display: true,
    },
  }, {
    name: 'odId',
    label: 'OD Id',
    options: {
      filter: false,
      display: true,
    },
  }, {
    name: 'firstToll',
    label: 'Toll Way',
    options: {
      filter: false,
      display: true,
      customBodyRender(value = {}) {
        // "Toll Way" should be what the customer sees on the mobile application. The trip card
        // displays only the first toll's `tollWay` value **or** the exit display name. Since we
        // only have access to the `tolls` property here we substitute the exit display name with
        // the toll's display name as a fallback.
        const location = (value || {}).location || {};
        const tollWay = location.tollWay || location.displayName || 'Unknown';
        if (tollWay) {
          return (<div>{tollWay}</div>);
        }
        return null;
      },
    },
  }, {
    name: 'tolls',
    label: 'Tolls',
    options: {
      filter: false,
      display: false,
      customBodyRender(value = []) {
        return (
          <Fragment>
            {value.map(({ odId, location, cost }, index)=>(
              <Typography variant="body2" color="textSecondary" key={index}>
                {odId || location.displayName} {formatCurrency(cost)}
              </Typography>
            ))}
          </Fragment>
        );
      },
    },
  }, {
    name: 'entryName',
    label: 'Entry Plaza',
    options: {
      filter: false,
      display: false,
    },
  }, {
    name: 'entryTollWay',
    label: 'Entry Toll Way',
    options: {
      filter: false,
      // display: false,
    },
  }, {
    name: 'exitName',
    label: 'Exit Plaza',
    options: {
      filter: false,
      display: false,
    },
  }, {
    name: 'exitTollWay',
    label: 'Exit Toll Way',
    options: {
      filter: false,
      // display: false,
    },
  }, {
    name: 'entryTime',
    label: 'Entry Time',
    options: {
      filter: false,
      customBodyRender: tableCellTimeRenderer(true),
    },
  }, {
    name: 'exitTime',
    label: 'Exit Time',
    options: {
      filter: false,
      customBodyRender: tableCellTimeRenderer(true),
    },
  }, {
    name: 'payTime',
    label: 'Pay Time',
    options: {
      filter: false,
      display: false,
      customBodyRender: tableCellTimeRenderer(true),
    },
  }, {
    name: 'refundTime',
    label: 'Refund Time',
    options: {
      display: false,
      filter: false,
      customBodyRender: tableCellTimeRenderer(true),
    },
  }, {
    name: 'rejectReason',
    label: 'Reject Reason',
    options: {
      display: false,
      filter: false,
    },
  }, {
    name: 'rejectTime',
    label: 'Reject Time',
    options: {
      display: false,
      filter: false,
      customBodyRender: tableCellTimeRenderer(true),
    },
  }, {
    name: 'transactionId',
    label: 'Transaction ID',
    options: {
      filter: false,
      display: false,
    },
  }, {
    name: 'costTrip',
    label: 'Cost',
    options: {
      filter: false,
      display: false,
      customBodyRender: tableCellCurrencyRenderer(),
    },
  }, {
    name: 'costAdmin',
    label: 'Fee',
    options: {
      filter: false,
      display: false,
      customBodyRender: tableCellCurrencyRenderer(),
    },
  }, {
    name: 'costTotal',
    label: 'Total',
    options: {
      filter: false,
      customBodyRender: tableCellCurrencyRenderer(),
    },
  }, {
    name: 'rejectable',
    label: 'Rejectable',
    options: {
      filter: false,
      customBodyRender: tableCellBooleanRenderer(),
    },
  }, {
    name: 'updatedAt',
    label: 'Updated At',
    options: {
      filter: false,
      customBodyRender: tableCellTimeRenderer(true),
    },
  }, {
    name: 'createdAt',
    label: 'Created At',
    options: {
      filter: false,
      customBodyRender: tableCellTimeRenderer(true),
    },
  }, {
    name: 'tripId',
    label: 'View',
    options: {
      filter: false,
      customBodyRender: tableCellTripIdRenderer('View'),
    },
  }].map((item) => {
    if (accountId) {
      if (['accountId'].includes(item.name)) {
        item.options = {
          display: false,
          filter: false,
        };
      }
    }
    return item;
  });

  const data = trips
    .sort(sortBy('createdAt', true))
    .sort(sortBy('updatedAt', true))
    .map((item) => {
      item.rawData = JSON.parse(JSON.stringify(item));
      if (item.cost) {
        item.costTrip = item.cost.trip;
        item.costAdmin = item.cost.admin;
        item.costTotal = item.cost.total;
      }

      if (item.entry && item.exit) {
        item.entryTime = item.entry.time;
        item.exitTime = item.exit.time;
        item.entryTollWay = item.entry.tollWay;
        item.exitTollWay = item.exit.tollWay;
        item.entryName = item.entry.displayName;
        item.exitName = item.exit.displayName;
        item.imgUrl = `${urlPrefix}/${item.assetId}__${item.entry.tollWay}__${item.exit.tollWay}.png`;
      }

      if (item.tolls) {
        item.firstToll = (item.tolls || [])[0];
      }

      headers.forEach((header) => {
        item[header.name] = (item[header.name] !== undefined) ? item[header.name] : '';
      });
      return item;
    })
    .sort(sortBy('entryTime', true));

  const options = {
    expandableRows: true,
    renderExpandableRow(rowData, rowMeta) {
      console.log('renderExpandableRow', rowData, rowMeta);
      const item = data[rowMeta.dataIndex];

      return (
        <TableRow>
          <TableCell colSpan={headers.length + 1} className="nested-table-container">
            <TripTableDetails trip={item} />
          </TableCell>
        </TableRow>
      );
    },
  };

  return (
    <Grid item xs={12}>
      <Table
        title={title}
        data={data}
        columns={headers}
        options={options}
        nested={nested}
      />
    </Grid>);
};


TripTable.propTypes = {
  title: PropTypes.string,
  data: PropTypes.object,
  target: PropTypes.string,
  tripId: PropTypes.string,
  accountId: PropTypes.string,
  nested: PropTypes.bool,
};

export default TripTable;
