import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';

import Code from 'components/Code';
import CandidateListItem from './CandidateListItem';
import RelatedTransactionListItem from './RelatedTransactionListItem';
import { request, sortBy, tableCellTripIdRenderer, diffTime } from '../utils';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: 'rgb(230, 230, 230)',
  },
  card: {
    padding: theme.spacing(2),
  },
}));

function GridPanel({ children }) {
  const classes = useStyles();

  return (<Grid item xs={12}>
    <Paper className={classes.card}>
      {children}
    </Paper>
  </Grid>);
}

GridPanel.propTypes = {
  children: PropTypes.any,
};

export default function FailedAssetTransactionHandler({
  transaction,
  relatedTransactions = [],
  containerStyle = {},
  onUpdate,
}) {
  const [note, setNote] = useState('');
  const [reason, setReason] = useState('');
  const [candidates, setCandidates] = useState([]);
  const [trips, setTrips] = useState([]);
  const [checked, setChecked] = useState([]);
  const [checkedRelatedTransactions, setCheckedRelatedTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [canEdit, setCanEdit] = useState(true);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const handleToggleRelatedTransactions = (value) => () => {
    const currentIndex = checkedRelatedTransactions.indexOf(value);
    const newChecked = [...checkedRelatedTransactions];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setCheckedRelatedTransactions(newChecked);
  };

  const getCandidates = useCallback(async () => {
    setIsLoading(true);
    setCandidates([]);

    const options = {
      api: '/api/queryVehiclesByLpnByTimestamp',
      method: 'GET',
      mode: 'cors',
      query: {
        vehicleLpnState: transaction.tx_license_no,
      },
    };
    const res = await request(options);

    const mapping = {};
    res.data
      .sort(sortBy('registerDate'))
      .forEach((item) => {
        const { accountId, registerDate, vehicleLpnState, vehicleLpn, vehicleState } = item;
        mapping[accountId] = mapping[accountId] || {
          accountId,
          vehicleLpnState,
          vehicleLpn,
          vehicleState,
          registerDate,
          records: [],
        };

        mapping[accountId].registerDate = registerDate;
        mapping[accountId].records.push(item);
      });

    setCandidates(Object.keys(mapping).map((key) => mapping[key]));

    setIsLoading(false);
  }, [transaction.tx_license_no]);

  const getTrips = useCallback(async () => {
    if (!transaction.tx_serial_no) return;

    setIsLoading(true);
    setTrips([]);

    const options = {
      api: `/api/assetTransactionTrips`,
      query: {
        txSerialNo: transaction.tx_serial_no,
      },
      method: 'GET',
      mode: 'cors',
    };
    const res = await request(options);
    setTrips(res.data || []);

    setIsLoading(false);
  }, [transaction.tx_serial_no]);

  const handleSubmit = async (action, txStatus, txStatusMsg = '') => {
    let term = '';

    switch (action) {
    case 'fix':
      term = 'Are you sure to fix this asset transaction?';
      break;
    case 'assign':
      term = 'Are you sure to assign this asset transaction to users?';
      break;
    case 'disregard':
      term = 'Are you sure to disregard this asset transaction?';
      break;
    }
    const result = window.confirm(term);
    if (result) {
      return submit(action, txStatus, txStatusMsg);
    }
  };

  const submit = async (action, txStatus, txStatusMsg = '') => {
    setIsLoading(true);
    const transactions = [
      transaction,
      ...checkedRelatedTransactions.map((index) => relatedTransactions[index]),
    ];
    try {
      const options = {
        api: '/api/processAssetTransactions',
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify({
          action,
          txStatus,
          txStatusMsg: `${txStatusMsg || ''} by ${window.localStorage.getItem('name')}`,
          tripId: (trips[0] || {}).tripId,
          transactions,
          forceAssignToVehicles: checked.map((index) => {
            const { accountId, vehicleLpnState, registerDate, vehicleLpn, vehicleState } = candidates[index];
            return { accountId, vehicleLpnState, registerDate, vehicleLpn, vehicleState };
          }),
        }),
      };
      await request(options);

      onUpdate(transactions);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (transaction) {
      setTrips([]);
      setCandidates([]);
      setNote('');
      setReason('');
      setChecked([]);
      setCheckedRelatedTransactions([]);

      getCandidates();
      getTrips();

      if ([1, 3, 20].includes(transaction.tx_status)) {
        setCanEdit(false);
      } else {
        setCanEdit(true);
      }
    }
  }, [transaction, getCandidates, getTrips]);

  return (
    <Grid container spacing={2} style={containerStyle}>
      <Grid item xs={12} md={6}>
        <GridPanel>
          <Code code={transaction} defaultLanguage="list" />
        </GridPanel>
      </Grid>
      <Grid item xs={12} md={6}>
        {!canEdit &&
          <GridPanel>
            This transaction has been processed.
          </GridPanel>}
        {canEdit && <Grid container spacing={2}>
          <GridPanel>
            <Typography variant="h5" gutterBottom>
              Existing Trip
            </Typography>
            {trips.length === 0 && 'No record'}
            {trips[0] && tableCellTripIdRenderer(trips[0].tripId, '_blank')(trips[0].tripId)}

            <FormControl margin="normal" fullWidth>
              <Button
                variant="contained"
                color="primary"
                disabled={isLoading || trips.length === 0 || !canEdit}
                onClick={() => handleSubmit('fix', 1, 'Data fix')}
              >
                Fix Status
              </Button>
              <FormHelperText>
                Fix the asset transaction data by changing the tx_status to 1 and add the tripId to the record
              </FormHelperText>
            </FormControl>
          </GridPanel>
          <GridPanel>
            <Typography variant="h5">
              Assign to Accounts
            </Typography>
            <FormControl
              fullWidth
              margin="normal"
            >
              <TextField
                label="Should be processed at"
                value={`${transaction.shouldBeProcessedAt} (${diffTime(transaction.shouldBeProcessedAt)})`}
                disabled={true}
              />
            </FormControl>
            <FormControl
              fullWidth
              margin="normal"
            >
              <TextField
                label="LPN State"
                value={transaction.tx_license_no}
                disabled={true}
              />
            </FormControl>
            <FormControl
              fullWidth
              margin="normal"
            >
              <TextField
                label="Entry Time"
                value={transaction.tx_entry_datetime}
                disabled={true}
              />
            </FormControl>
            <FormControl
              fullWidth
              margin="normal"
            >
              <TextField
                label="Exit Time"
                value={transaction.tx_exit_datetime}
                disabled={true}
              />
            </FormControl>

            {candidates.length === 0 && 'There is no matching vehicles.'}
            <List
              subheader={
                <ListSubheader component="div" id="nested-list-subheader" disableGutters>
                  Vehicles
                </ListSubheader>
              }
            >
              {candidates.map((item, index) => {
                return (
                  <CandidateListItem
                    key={index}
                    index={index}
                    isChecked={checked.indexOf(index) !== -1}
                    item={item}
                    tripEntryTime={transaction.tx_entry_datetime}
                    onClick={handleToggle(index)}
                  />
                );
              })}
            </List>

            <List
              subheader={
                <ListSubheader component="div" disableGutters>
                  Related Failed Asset Transactions
                </ListSubheader>
              }
            >
              {relatedTransactions.map((item, index) => {
                return (
                  <RelatedTransactionListItem
                    key={index}
                    index={index}
                    isChecked={checkedRelatedTransactions.indexOf(index) !== -1}
                    item={item}
                    tripEntryTime={transaction.tx_entry_datetime}
                    onClick={handleToggleRelatedTransactions(index)}
                  />
                );
              })}
            </List>

            <FormControl
              fullWidth
              margin="normal"
            >
              <TextField
                label="Note"
                value={note}
                onChange={(e) => setNote(e.target.value)}
                disabled={isLoading || !canEdit}
              />
            </FormControl>

            <FormControl margin="normal" fullWidth>
              <Button
                variant="contained"
                color="primary"
                disabled={isLoading || !note || checked.length === 0 || !canEdit}
                onClick={() => handleSubmit('assign', 1, note)}
              >
                Confirm
              </Button>
              <FormHelperText>
                Trip assignment will assign the selected accounts to the selected asset transactions disregarding the vehicle registration dates.
              </FormHelperText>
            </FormControl>
          </GridPanel>
          <GridPanel>
            <Typography variant="h5">
              Disregard
            </Typography>

            <FormControl
              fullWidth
              margin="normal"
            >
              <TextField
                label="Reason"
                value={reason}
                disabled={isLoading || !canEdit}
                onChange={(e) => setReason(e.target.value)}
              />
            </FormControl>
            <FormControl margin="normal" fullWidth>
              <Button
                variant="contained"
                color="secondary"
                disabled={isLoading || !reason || !canEdit}
                onClick={() => handleSubmit('disregard', 3, reason)}
              >
                Disregard
              </Button>
              <FormHelperText>
                Disregard the asset transaction by changing the tx_status to 3
              </FormHelperText>
            </FormControl>
          </GridPanel>
        </Grid>}
      </Grid>
    </Grid>
  );
}

FailedAssetTransactionHandler.propTypes = {
  transaction: PropTypes.object.isRequired,
  relatedTransactions: PropTypes.array,
  containerStyle: PropTypes.any,
  onUpdate: PropTypes.func,
};
