import React, { Fragment, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';

import { request } from '../utils';

const statuses = [
  { text: 'Active', value: 'ACTIVE', synonyms: [] },
  { text: 'Blocked', value: 'BLOCKED', synonyms: [] },
  { text: 'Deactivate', value: 'PendingVehiclesUnreg', synonyms: ['Deactivated', 'DeactivatedOnHold', 'Deleted'] },
];

const AccountStatus = ({ accountId, accountStatus, onUpdate }) => {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  if (!accountId || !accountStatus) {
    return null;
  }

  const handleClick = async () => {
    try {
      const status = statuses[selectedIndex].value;
      const options = {
        api: `/api/account/${accountId}/status`,
        method: 'POST',
        body: JSON.stringify({
          status,
        }),
        mode: 'cors',
      };
      await request(options);

      if (onUpdate) {
        onUpdate();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <Fragment>
      <ButtonGroup variant="outlined" color="secondary" ref={anchorRef} className="space" aria-label="account status">
        <Button onClick={handleClick}>Set as {statuses[selectedIndex].text}</Button>
        <Button
          color="secondary"
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select account status"
          aria-haspopup="menu"
          onClick={handleToggle}>
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper className="popper" open={open} anchorEl={anchorRef.current} role={undefined} transition style={{ zIndex: 999 }}>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="status-button-menu">
                  {statuses.map((status, index) => (
                    <MenuItem
                      key={status.value}
                      disabled={status.value === accountStatus || status.synonyms.includes(accountStatus)}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}>
                      {status.text}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Fragment>
  );
};

AccountStatus.propTypes = {
  accountId: PropTypes.string,
  accountStatus: PropTypes.string,
  onUpdate: PropTypes.func,
};

export default AccountStatus;
