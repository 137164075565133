import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import moment from 'moment';

import { tableCellAccountIdRenderer } from '../utils';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(8),
  },
}));

export default function CandidateListItem({ index, isChecked, tripEntryTime, item, onClick }) {
  const classes = useStyles();
  const [open, setOpen] = useState(true);

  const handleToggle = () => {
    setOpen(!open);
  };

  const labelId = `checkbox-list-label-${index}`;

  const {
    accountId,
    registerDate,
    records,
  } = item;

  const diffTime = (inTime) => {
    return moment(inTime).from(tripEntryTime).replace('ago', 'before the entry time');
  };

  return (
    <Fragment>
      <ListItem
        alignItems="flex-start"
        selected={isChecked}
      >
        <ListItemIcon onClick={onClick} style={{ minWidth: 24 }}>
          <Checkbox
            edge="start"
            checked={isChecked}
            tabIndex={2}
            disableRipple
            inputProps={{ 'aria-labelledby': labelId }}
          />
        </ListItemIcon>
        <ListItemText
          id={labelId}
          primary={<span>
            Account ID: {tableCellAccountIdRenderer(null, '_blank', { variant: 'body1' })(accountId)}
          </span>}
          secondary={`${registerDate} (${diffTime(registerDate)})`}
        />
        <ListItemSecondaryAction>
          <IconButton edge="end" aria-label="expand" onClick={handleToggle}>
            {open ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding dense>
          {records.map((record, index)=>(
            <ListItem className={classes.nested} key={index} onClick={() => console.log(record)}>
              <ListItemText
                primary={`
                  ${record.blockedStatus === 1 ? 'BLOCKED' : (record.activeStatus === 1 ? 'ADD' : 'DELETE')}
                  ${record.isRental ? ' - [RENTAL]' : ''}
                `}
                secondary={`${record.registerDate} (${diffTime(record.registerDate)})`}
              />
            </ListItem>
          ))}
        </List>
      </Collapse>
      <Divider />
    </Fragment>);
}

CandidateListItem.propTypes = {
  index: PropTypes.number.isRequired,
  isChecked: PropTypes.bool,
  tripEntryTime: PropTypes.string,
  item: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};
