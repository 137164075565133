import moment from 'moment';

export default {
  fields: [{
    key: 'id',
    type: 'String',
    label: 'ID',
    isDisabled: true,
  },
  {
    key: 'type',
    type: 'String',
    label: 'Type',
    formType: 'Radio',
    formOptions: [
      { label: 'discount', value: 'discount' },
      { label: 'referral', value: 'referral' },
    ],
  },
  {
    key: 'title',
    type: 'String',
    label: 'Title',
  },
  {
    key: 'titleCustomer',
    type: 'String',
    label: 'Title for customer',
  },
  {
    key: 'description',
    type: 'String',
    label: 'Program rules',
    isRequired: false,
  },
  {
    key: 'url',
    type: 'String',
    label: 'T&C URL',
    isRequired: false,
  },
  {
    key: 'dateStart',
    type: 'String',
    label: 'Date Start (UTC)',
    formType: 'Date',
    formFormatFunction(value) {
      return moment(value).format('YYYY-MM-DD');
    },
    formParseFunction(value) {
      return moment(value).toISOString();
    },
  },
  {
    key: 'dateEnd',
    type: 'String',
    label: 'Date End (UTC)',
    formType: 'Date',
    formFormatFunction(value) {
      return moment(value).format('YYYY-MM-DD');
    },
    formParseFunction(value) {
      return moment(value).toISOString();
    },
  },
  {
    key: 'countUses',
    type: 'Number',
    label: 'Count Uses',
    isDisabled: true,
  },
  {
    key: 'usesMax',
    type: 'Number',
    label: 'Max Uses',
  },
  {
    key: 'creditType',
    type: 'String',
    label: 'Credit Type',
    formType: 'Radio',
    formOptions: [
      { label: 'cent', value: 'cent' },
      { label: 'percentage', value: 'percentage' },
      { label: 'trip', value: 'trip' },
    ],
  },
  {
    key: 'creditValue',
    type: 'Number',
    label: 'Credit Value',
  },
  {
    key: 'activeStatus',
    type: 'String',
    label: 'Active',
    formType: 'Radio',
    formOptions: [
      { label: 'No', value: 0 },
      { label: 'Yes', value: 1 },
    ],
  },
  {
    key: 'firstTimeUserOnly',
    type: 'String',
    label: 'First Time Use Only',
    formType: 'Radio',
    formOptions: [
      { label: 'No', value: 0 },
      { label: 'Yes', value: 1 },
    ],
  },
  {
    key: 'firstTimeRedeemOnly',
    type: 'String',
    label: 'First Time Redeem Only',
    formType: 'Radio',
    formOptions: [
      { label: 'No', value: 0 },
      { label: 'Yes', value: 1 },
    ],
  },
  {
    key: 'ignoreTripTime',
    type: 'String',
    label: 'Ignore Trip Trime',
    formType: 'Radio',
    formOptions: [
      { label: 'No', value: 0 },
      { label: 'Yes', value: 1 },
    ],
  },
  {
    key: 'assetIds',
    type: 'String',
    label: 'Selected Roadways',
    formType: 'checkbox',
    formOptions: [],
  },
  ],
};
