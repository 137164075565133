import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import moment from 'moment';

export default function RelatedTransactionListItem({ index, isChecked, tripEntryTime, item, onClick }) {
  const labelId = `checkbox-list-label-${index}`;

  const {
    tx_entry_datetime: txEntryDatetime,
    tx_entry_plaza: txEntryPlaza,
  } = item;

  const diffTime = (inTime) => {
    const string = moment(inTime).from(tripEntryTime);
    if (string.startsWith('in')) {
      return string.replace('in ', '') + ' after the entry time';
    } else {
      return string.replace('ago', 'before the entry time');
    }
  };

  return (
    <Fragment>
      <ListItem
        alignItems="flex-start"
        selected={isChecked}
        divider
      >
        <ListItemIcon onClick={onClick} style={{ minWidth: 24 }}>
          <Checkbox
            edge="start"
            checked={isChecked}
            tabIndex={2}
            disableRipple
            inputProps={{ 'aria-labelledby': labelId }}
          />
        </ListItemIcon>
        <ListItemText
          id={labelId}
          primary={<span>
            Entry Plaza: {txEntryPlaza}
          </span>}
          secondary={`${txEntryDatetime} (${diffTime(txEntryDatetime)})`}
        />
      </ListItem>
    </Fragment>);
}

RelatedTransactionListItem.propTypes = {
  index: PropTypes.number.isRequired,
  isChecked: PropTypes.bool,
  tripEntryTime: PropTypes.string,
  item: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};
