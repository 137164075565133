import React, { Fragment, useState, useEffect } from 'react';
import {
  Router,
  Route,
} from 'react-router-dom';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import DocumentTitle from 'react-document-title';
import { createBrowserHistory } from 'history';
import queryString from 'query-string';

import { Provider } from 'react-redux';
import ReduxToastr from 'react-redux-toastr';
import SplitPane from 'react-split-pane';

import 'leaflet/dist/leaflet.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import 'react-image-lightbox/style.css';

import './App.css';

// import NavBar from './navbar'
import Sidebar from './navbar/Sidebar';

import Error from './auth/Error';
import Login from './auth/Login';

import store from './App.reducer';

import routes from './routes';

import { request } from './utils';
import { register } from './config';

momentDurationFormatSetup(moment);
const history = createBrowserHistory();

const App = () => {
  const [state, setState] = useState({
    isLoading: true,
    shouldLogin: false,
    hasEnvProps: false,
    notAutenticated: true,
    open: true,
  });
  const [sideBardSize, setSideBardSize] = useState(150);

  useEffect(() => {
    if (
      !state.notAutenticated &&
      !state.shouldLogin &&
      window.localStorage.getItem('accessToken')
    ) {
      (async () => {
        const { data } = await request({ api: '/api/config/google', method: 'GET' });
        register({ googlekey: data.key });
      })();
    }
  }, [state.notAutenticated, state.shouldLogin]);

  useEffect(() => {
    // const isFirefox = typeof InstallTrigger !== 'undefined';
    // const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
    // if (!isChrome && !isFirefox) {
    //   return window.alert('Please use Google Chrome or Firefox.');
    // }

    console.log(history.location);
    let pathname = history.location.pathname;
    const hashParams = queryString.parse(history.location.hash);
    console.log('hashParams', hashParams);

    if (hashParams.access_token) {
      window.localStorage.setItem('accessToken', hashParams.access_token);
      window.localStorage.setItem('accessTokenType', hashParams.token_type);
      window.localStorage.setItem('accessTokenExpiredIn', hashParams.expires_in);
      window.localStorage.setItem('idToken', hashParams.id_token);

      if (hashParams.state !== 'state') {
        pathname = hashParams.state;
      }
    }

    // hashParams.error = 'access_denied';
    // hashParams.error_description = 'User is not assigned to the client application.';

    const accessToken = hashParams.access_token || window.localStorage.getItem('accessToken');
    const shouldLogin = (!accessToken) ? true : false;
    const notAutenticated = (hashParams.error || hashParams.error_description) ? hashParams.error_description || hashParams.error : false;
    console.log({ accessToken, shouldLogin, notAutenticated });

    const queryParams = queryString.parse(history.location.search);
    console.log('queryParams', queryParams);
    const env = queryParams.env || window.localStorage.getItem('env') || 'prd';
    queryParams.env = env;
    const hideSidebar = queryParams.hideSidebar ? true : false;
    // delete queryParams.env;
    delete hashParams.access_token;
    delete hashParams.token_type;
    delete hashParams.expires_in;
    delete hashParams.id_token;

    history.push({
      pathname,
      search: queryString.stringify(queryParams),
      hash: queryString.stringify(hashParams),
    });

    window.localStorage.setItem('env', env);


    setState({
      isLoading: false,
      shouldLogin,
      hasEnvProps: true,
      notAutenticated,
      open: !hideSidebar,
    });
  }, []);

  useEffect(() => {
    setSideBardSize(parseInt(window.localStorage.getItem('splitPos'), 10) || sideBardSize);
  }, [sideBardSize]);

  const { isLoading, shouldLogin, hasEnvProps, notAutenticated, open } = state;
  return (
    <Router history={history}>
      <Fragment>
        <Provider store={store}>
          <div>
            <ReduxToastr
              timeOut={10000}
              newestOnTop={false}
              preventDuplicates
              position='top-right'
              transitionIn='fadeIn'
              transitionOut='fadeOut'
              progressBar
              closeOnToastrClick={false}/>
          </div>
        </Provider>

        {
          !isLoading && notAutenticated &&
          <Route path='/' component={Error}/>
        }
        {
          !isLoading && !notAutenticated && shouldLogin &&
          <Route path='/' component={Login}/>
        }
        {!isLoading && !notAutenticated && !shouldLogin && hasEnvProps &&
          <div className='root'>
            <SplitPane
              split='vertical'
              minSize={!open? '100%' : 50}
              maxSize={!open ? '100%' : 150}
              step={100}
              defaultSize={!open ? '100%' : sideBardSize}
              onChange={(size)=>{
                window.localStorage.setItem('splitPos', size);
                setSideBardSize(size);
              }}
              allowResize={true}
            >
              {open &&
                <Sidebar
                  className='sidebar'
                  iconOnly={sideBardSize === 50}
                />
              }
              <div className={`app-main-container ${open ? 'sidebar-open' : 'sidebar-close' }`}>
                {
                  routes.map((route, index)=>(
                    <Fragment key={index}>
                      {route.paths.map((path)=>(
                        <Route key={path} exact path={path} render={ (props) => {
                          return (<DocumentTitle title={`${route.label} | GoToll Admin`}>
                            <route.component {...props} />
                          </DocumentTitle>);
                        }}/>
                      ))}
                    </Fragment>
                  ))
                }
              </div>
            </SplitPane>
            {/* <Route exact path='/' component={dashboard}/> */}
            {/* <Route component={NotFound}/> */}
          </div>
        }
      </Fragment>
    </Router>
  );
};

export default App;
