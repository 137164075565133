import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
// import TableRow from '@material-ui/core/TableRow';
// import TableCell from '@material-ui/core/TableCell';

import Table from './Table';
import {
  sortBy,
  request,
  tableCellTimeRenderer,
  tableCellStatusRenderer,
  tableCellAccountIdRenderer,
  tableCellCaseIdRenderer,
} from '../utils';

// import CaseMessageTable from './CaseMessageTable';
// import CaseMessages from './CaseMessages';

const defaultHeaders = [{
  name: 'caseID',
  label: 'ID',
  options: {
    display: true,
    filter: false,
    customBodyRender: tableCellCaseIdRenderer(),
  },
}, {
  name: 'accountID',
  label: 'Account ID',
  options: {
    display: true,
    filter: false,
    customBodyRender: tableCellAccountIdRenderer(),
  },
}, {
  name: 'category',
  label: 'Category',
  options: {
    display: true,
  },
}, {
  name: 'status',
  label: 'Status',
  options: {
    display: true,
    customBodyRender: tableCellStatusRenderer({
      open: 0,
      resolved: 1,
    }),
  },
}, {
  name: 'unread',
  label: 'Unread',
  options: {
    display: true,
    customBodyRender: tableCellStatusRenderer({
      0: 1,
      1: 2,
    }),
  },
}, {
  name: 'title',
  label: 'Title',
  options: {
    display: true,
    filter: false,
  },
}, {
  name: 'description',
  label: 'Description',
  options: {
    display: true,
    filter: false,
  },
}, {
  name: 'resolveddAt',
  label: 'Resolved At',
  options: {
    display: true,
    filter: false,
    customBodyRender: tableCellTimeRenderer(true),
  },
}, {
  name: 'updatedAt',
  label: 'Updated At',
  options: {
    display: true,
    filter: false,
    customBodyRender: tableCellTimeRenderer(true),
  },
}, {
  name: 'createdAt',
  label: 'Created At',
  options: {
    display: true,
    filter: false,
    customBodyRender: tableCellTimeRenderer(true),
  },
}];

export default function CaseTable({ data: inData, accountId, nested }) {
  const [data, setData] = useState([]);
  const [headers, setHeaders] = useState([]);

  useEffect(() => {
    (async () => {
      let records = [];
      if (inData) {
        records = inData;
      } else
      if (accountId) {
        const options = {
          api: `/api/account/${accountId}/cases`,
          method: 'GET',
          mode: 'cors',
        };
        const { data } = await request(options);
        records = data;
      }

      setData(records
        .sort(sortBy('createdAt', true))
        .sort(sortBy('updatedAt', true))
        .map((item) => {
          defaultHeaders.forEach((header) => {
            item[header.name] = (item[header.name] !== undefined) ? item[header.name] : '';
          });
          return item;
        }));
    })();
  }, [inData, accountId]);

  useEffect(() => {
    setHeaders(defaultHeaders.map((item) => {
      if (accountId) {
        if (['accountID'].includes(item.name)) {
          item.options = {
            filter: false,
            display: false,
          };
        }
      }
      return item;
    }));
  }, [accountId]);

  const options = {
    expandableRows: false,
    // renderExpandableRow: (rowData, rowMeta) => {
    //   const item = data[rowMeta.dataIndex];
    //   console.log(item);

    //   return (
    //     <TableRow>
    //       <TableCell colSpan={headers.length + 1} className="nested-table-container">
    //         <CaseMessages caseItem={item} />
    //       </TableCell>
    //     </TableRow>
    //   );
    // },
  };

  return (
    <Grid item xs={12}>
      <Table
        title={ 'Cases'}
        data={data}
        columns={headers}
        options={options}
        nested={nested}
      />
    </Grid>);
}

CaseTable.propTypes = {
  data: PropTypes.array,
  accountId: PropTypes.string,
  nested: PropTypes.bool,
};
