import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import Grow from '@material-ui/core/Grow';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ExitToApp from '@material-ui/icons/ExitToApp';

import { request } from '../utils';

let anchorEl;
const SidebarAccount = ({ user, iconOnly = true }) => {
  const [open, setOpen] = useState(false);
  const menu = [{
    icon: ExitToApp,
    label: 'Logout',
    action: async () => {
      const options = {
        api: '/api/logout',
        method: 'GET',
        mode: 'cors',
      };
      const { data } = await request(options);
      window.localStorage.removeItem('accessToken');
      window.localStorage.removeItem('accessTokenType');
      window.localStorage.removeItem('accessTokenExpiredIn');
      window.localStorage.removeItem('idToken');
      window.open(data.logoutUrl, '_self');
    },
  }];

  const handleClose = (event) => {
    if (anchorEl.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <Fragment>
      <ListItem
        button
        dense
        buttonRef={(node) => anchorEl = node}
        aria-owns={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={()=> setOpen(!open)}
      >
        <ListItemIcon><AccountCircle /></ListItemIcon>
        {!iconOnly &&
          <ListItemText primary={user.name}/>}
      </ListItem>

      <Popper
        open={open}
        anchorEl={anchorEl}
        transition
        disablePortal
        placement="left-end"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            id="menu-list-grow"
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  {
                    menu.map((item) => (
                      <MenuItem
                        key={item.label}
                        className="nav-bar-menu-item"
                        onClick={item.action}>
                        {item.icon && <item.icon />} {item.label}
                      </MenuItem>
                    ))
                  }
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Fragment>
  );
};

SidebarAccount.propTypes = {
  user: PropTypes.object,
  iconOnly: PropTypes.bool,
};

export default SidebarAccount;
