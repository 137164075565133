import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';

import Table from './Table';
import {
  request,
  sortBy,
  tableCellTimeRenderer,
} from '../utils';

export default class VehicleExterrnalStatusTable extends Component {
  state = {
    data: [],
  }

  componentDidMount = async () => {
    await this.load();
  }

  load = async () => {
    if (!this.props.lpnState) return;
    try {
      const options = {
        api: `/api/vehicle/${this.props.lpnState}/externalStatus`,
        method: 'GET',
        mode: 'cors',
      };
      const res = await request(options);
      this.setState({
        data: res.data,
      });
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    const headers = [{
      name: 'vendor',
      label: 'vendor',
      options: {
        filter: true,
      },
    }, {
      name: 'vendorAccount',
      label: 'Account',
      options: {
        filter: true,
      },
    }, {
      name: 'lpnState',
      label: 'LPN State',
      options: {
        filter: false,
      },
    }, {
      name: 'itemUpdatedAt',
      label: 'UpdatedAt',
      options: {
        filter: false,
        customBodyRender: tableCellTimeRenderer(true),
      },
    }, {
      name: 'createdDate',
      label: 'Created At',
      options: {
        filter: false,
        customBodyRender: tableCellTimeRenderer(true),
      },
    }];

    const onItemClick = (rowData, rowMeta) => {
      const item = this.state.data[rowMeta.dataIndex];
      console.log(item);
    };

    const options = {
      filterType: 'checkbox',
      onRowClick: onItemClick,
      rowsPerPageOptions: [10, 50, 100],
      rowsPerPage: 10,
    };

    const data = (this.state.data)
      .sort(sortBy('createdDate', true));

    return (
      <Grid item xs={12}>
        <Table
          title={this.props.title || 'External Vehicle Status'}
          data={data}
          columns={headers}
          options={options}
          nested={this.props.nested}
        />
      </Grid>);
  }
}
