import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import { sortObject } from '../utils';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  listItem: {
    padding: 0,
    // paddingTop: 0,
    // paddingBottom: 0,
    // paddingRight: 0,
  },
  header: {
    backgroundColor: '#f2f2f2',
    color: '#666666',
    padding: 5,
    paddingLeft: 8,
  },
  text: {
    color: '#000000',
    padding: 5,
    paddingLeft: 8,
  },
  nested: {
    paddingLeft: theme.spacing(2),
    width: '100%',
  },
}));

const JsonViewer = ({ jsonObject }) => {
  const classes = useStyles();

  const [items, setItems] = useState([]);

  useEffect(() => {
    const sorted = sortObject(jsonObject);
    // console.log(sorteds);
    const rows = [];
    Object.keys(sorted).forEach((key) => {
      const value = sorted[key];
      if (typeof value !== 'object') {
        rows.push({
          key,
          value: `${value}`,
        });
      }
      if (typeof value === 'object') {
        rows.push({
          key,
          obj: value,
        });
      }
    });

    setItems(rows);
  }, [jsonObject]);

  return (
    <React.Fragment>
      <List className={classes.root}>
        {items.map(({ key, value, obj }, index)=>{
          if (obj) {
            return (
              <div key={index} className={classes.nested}>
                <div className={classes.header}>
                  {key}
                </div>
                <JsonViewer jsonObject={obj} />
              </div>);
          }
          return (
            <ListItem key={index} className={classes.listItem} dense>
              <ListItemText
                primary={key}
                primaryTypographyProps={{ className: classes.header }}
                secondary={value}
                secondaryTypographyProps={{ className: classes.text }}
              />
            </ListItem>);
        })}
      </List>
    </React.Fragment>
  );
};

JsonViewer.propTypes = {
  jsonObject: PropTypes.object,
};

export default JsonViewer;
