import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import Table from '../components/Table';

import './Database.css';
import {
  sortByList,
  tableCellAccountIdRenderer,
  tableCellTripIdRenderer,
} from '../utils';

export default class ResultTable extends Component {
  state = {}

  componentDidMount() {}

  render() {
    const {
      title: inTitle,
      data: inData,
    } = this.props;

    const headers = Object.keys(inData[0] || {})
      .sort(sortByList(['id', 'accountId', 'accountID']))
      .map((key) => {
        let customBodyRender;
        switch (key) {
        case 'accountId':
        case 'accountID':
          customBodyRender = tableCellAccountIdRenderer(null, '_blank');
          break;
        case 'tripId':
        case 'tripID':
        case 'mergedTripId':
          customBodyRender = tableCellTripIdRenderer(null, '_blank');
          break;
        default:
        }
        const config = {
          name: key,
          label: key,
          options: {
            display: (typeof ((inData[0] || {})[key]) === 'object') ? false : true,
            filter: false,
            customBodyRender,
          },
        };

        return config;
      });

    const data = inData.map((item) => {
      headers.forEach((header) => {
        item[header.name] = (item[header.name] !== undefined) ? item[header.name] : '';

        if (typeof (item[header.name]) === 'object') {
          item[header.name] = JSON.stringify(item[header.name], null, 2);
        }
        if (typeof (item[header.name]) === 'boolean') {
          item[header.name] = (item[header.name] === true || item[header.name] === 'true') ? 'true' : 'false';
        }
      });
      return item;
    });

    const onItemClick = (rowData, rowMeta) => {
      const item = data[rowMeta.dataIndex];
      console.log(item);
    };

    const options = {
      filterType: 'checkbox',
      onRowClick: onItemClick,
      rowsPerPageOptions: [10, 100, 200],
      rowsPerPage: 10,
      expandableRows: true,
      renderExpandableRow: (rowData, rowMeta) => {
        const item = data[rowMeta.dataIndex];
        const sortedObj = Object.keys(item)
          .sort((a, b) => a > b ? 1 : -1)
          .reduce((obj, key) => {
            try {
              obj[key] = JSON.parse(item[key]);
            } catch (e) {
              obj[key] = item[key];
            }
            return obj;
          }, {});

        return (
          <TableRow>
            <TableCell colSpan={headers.length + 1} className="nested-table-container">
              <Paper>
                <pre>{JSON.stringify(sortedObj, null, 4)}</pre>
              </Paper>
            </TableCell>
          </TableRow>
        );
      },
    };

    return (
      <Grid item xs={12}>
        <Table
          title={inTitle}
          data={data}
          columns={headers}
          options={options}
          useCacheColumns={false}
          nested={true}
        />
      </Grid>);
  }
}
