import React from 'react';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import Table from './Table';
import VendorAccountVehicleTable from './VendorAccountVehicleTable';
import {
  tableCellTimeRenderer,
  tableCellStatusRenderer,
  tableCellBooleanRenderer,
} from '../utils';

export default function VendorAgencyTable({ accounts = [] }) {
  const onItemClick = (rowData, rowMeta) => {
    const item = accounts[rowMeta.dataIndex];
    console.log(item);
  };

  const headers = [{
    name: 'category',
    label: 'Category',
    options: {
      filter: true,
    },
  }, {
    name: 'name',
    label: 'Name',
    options: {
      filter: true,
    },
  }, {
    name: 'description',
    label: 'Description',
    options: {
      filter: false,
    },
  }, {
    name: 'isActive',
    label: 'Active',
    options: {
      filter: true,
      customBodyRender: tableCellStatusRenderer(),
    },
  }, {
    name: 'secretsManagerKey',
    label: 'Key',
    options: {
      filter: false,
      display: false,
    },
  }, {
    name: 'activeVehicles',
    label: 'Active Vehicles',
    options: {
      filter: false,
    },
  }, {
    name: 'maxVehicles',
    label: 'MAX Vehicles',
    options: {
      filter: false,
    },
  }, {
    name: 'releaseCutOffDate',
    label: 'Release Cuf Off Date',
    options: {
      filter: false,
      customBodyRender: tableCellTimeRenderer(true),
    },
  }, {
    name: 'lastSyncVehiclesAt',
    label: 'Sync Vehicles At',
    options: {
      filter: false,
      customBodyRender: tableCellTimeRenderer(true),
    },
  }, {
    name: 'lastSyncVehicleChangesAt',
    label: 'Sync Vehicles Changes At',
    options: {
      filter: false,
      customBodyRender: tableCellTimeRenderer(true),
    },
  }, {
    name: 'lastSyncTransactionsAt',
    label: 'Sync Transactions At',
    options: {
      filter: false,
      customBodyRender: tableCellTimeRenderer(true),
    },
  }, {
    name: 'dailyReportBufferDays',
    label: 'Daily report buffer days',
    options: {
      filter: false,
    },
  }, {
    name: 'dailyReportAutoFix',
    label: 'Daily report auto fix',
    options: {
      filter: false,
      customBodyRender: tableCellBooleanRenderer(),
    },
  }];

  const options = {
    filterType: 'checkbox',
    onRowClick: onItemClick,
    rowsPerPageOptions: [10, 50, 100],
    rowsPerPage: 10,
    expandableRows: true,
    renderExpandableRow: (rowData, rowMeta) => {
      console.log('renderExpandableRow', rowData, rowMeta);
      const { vendor, name } = accounts[rowMeta.dataIndex];

      return (
        <TableRow>
          <TableCell colSpan={headers.length + 1} className="nested-table-container">
            <VendorAccountVehicleTable vendor={vendor} account={name}/>
          </TableCell>
        </TableRow>
      );
    },
  };

  return (
    <Table
      title={'Accounts'}
      data={accounts}
      columns={headers}
      options={options}
      nested={true}
    />);
}

VendorAgencyTable.propTypes = {
  accounts: PropTypes.array,
};
