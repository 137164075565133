import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import { toastr } from 'react-redux-toastr';
import { NavLink, withRouter } from 'react-router-dom';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

import { request } from '../utils';

import SidebarEnv from './Sidebar.env';
import SidebarAccount from './Sidebar.account';

import './Sidebar.css';

import routes from '../routes';

import socket from '../socket';

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiDrawer: {
      paper: {
        position: 'absolute',
        width: '100%',
        zIndex: 1,
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: '24px!important',
        width: '24px',
        height: '24px',
        marginRight: '10px',
        fontSize: '18px',
        alignItems: 'center',
      },
    },
  },
});

// https://material-ui.com/components/buttons/#third-party-routing-library
// const CollisionLink = React.forwardRef((props, ref) => (
//   <Link innerRef={ref} to="/getting-started/installation/" {...props} />
// ));

const Sidebar = ({ history, iconOnly = true }) => {
  const env = window.localStorage.getItem('env');
  // const [appVersion, setAppVersion] = useState('');
  const [envMenu, setEnvMenu] = useState([]);
  const [user, setUser] = useState({});
  // const [onlineUsers, setOnlineUsers] = useState([]);

  const getVersionPromise = async () => {
    const options = {
      api: '/api/version',
      method: 'GET',
      mode: 'cors',
    };
    const { data: { version, availableEnvs } } = await request(options);
    console.log(version, availableEnvs);
    // setAppVersion(version);
    setEnvMenu(availableEnvs);
  };

  const getAccountPromise = async () => {
    const options = {
      api: '/api/me',
      method: 'GET',
      mode: 'cors',
    };
    const { data } = await request(options);
    window.localStorage.setItem('email', data.email);
    window.localStorage.setItem('name', data.name);

    setUser(data);

    const io = socket.register(data.domain);
    io.on('connect', () => {
      io.emit('register', { email: data.email, id: io.id });
    });
    // io.on('onlineUsers', (data) => {
    //   setOnlineUsers(data);
    // });
    io.on('serviceDownWarning', () => {
      toastr.warning('Site will be down soon for a new update. Please save your works.');
    });
  };

  // const { pathname: currentPath } = history.location;

  const menu = routes
    .filter((item) => item.category === 'basic')
    .filter((item) => !item.hideFromNavbar)
    .filter((item) => !(env === 'prd' && item.hideFromProduction))
    .filter((item) => (item.display !== undefined) ? item.display : true);

  const opsMenu = routes
    .filter((item) => item.category === 'ops')
    .filter((item) => !item.hideFromNavbar)
    .filter((item) => !(env === 'prd' && item.hideFromProduction))
    .filter((item) => (item.display !== undefined) ? item.display : true);

  const devMenu = routes
    .filter((item) => item.category === 'dev')
    .filter((item) => !item.hideFromNavbar)
    .filter((item) => !(env === 'prd' && item.hideFromProduction))
    .filter((item) => (item.display !== undefined) ? item.display : true);

  const setttingsMenu = routes
    .filter((item) => item.category === 'settings')
    .filter((item) => !item.hideFromNavbar)
    .filter((item) => !(env === 'prd' && item.hideFromProduction))
    .filter((item) => (item.display !== undefined) ? item.display : true);

  useEffect(() => {
    (async () => {
      await Promise.all([
        getVersionPromise(),
        getAccountPromise(),
      ]);
    })();
  }, []);

  const renderMenuList = (inMenu) => {
    return (
      <List>
        {inMenu.map((item, index) => (
          <ListItem
            key={index}
            button
            dense
            component={
              React.forwardRef((props, ref) =>
                <Tooltip title={item.label} placement="right" arrow>
                  <NavLink innerRef={ref} {...props} />
                </Tooltip>)
            }
            to={item.paths[0]}
            // className={`${item.paths.includes(currentPath)? 'sidebar-list-item-selected': ''}`}
          >
            <ListItemIcon><item.icon/></ListItemIcon>
            {!iconOnly && <ListItemText primary={item.label}/>}
          </ListItem>
        ))}
      </List>
    );
  };

  return (
    <MuiThemeProvider theme={theme}>
      <Drawer
        variant="persistent"
        anchor="left"
        className="sidebar-drawer"
        open={true}
      >
        <Grid container justify="center" alignItems="center" style={{ height: 50 }}>
          <img
            src={'logo.png'}
            alt="GoToll"
            style={{ width: 50 }}
          />
        </Grid>

        <Divider />

        <SidebarEnv list={envMenu} iconOnly={iconOnly} />

        <Divider />

        {renderMenuList(menu)}

        <Divider />

        {renderMenuList(opsMenu)}

        <Divider />

        {renderMenuList(devMenu)}

        <div className="expand"></div>

        {renderMenuList(setttingsMenu)}
        {/*
        <Tooltip title={`${this.state.onlineUsers.join(', ')}`} placement="right">
          <ListItem dense>
            <ListItemIcon><People /></ListItemIcon>
            <ListItemText primary={`Online Users: ${this.state.onlineUsers.length}`} />
          </ListItem>
        </Tooltip> */}

        <Divider />

        <SidebarAccount user={user} iconOnly={iconOnly} />

      </Drawer>
    </MuiThemeProvider>
  );
};

Sidebar.propTypes = {
  history: PropTypes.object,
  iconOnly: PropTypes.bool,
};


export default withRouter(Sidebar);
