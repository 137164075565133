export const comparators = [
  '=',
  '<',
  '<=',
  '>',
  '>=',
  '<>',
  'attribute_exists',
  'attribute_not_exists', // attribute_not_exists(accountId)
  'contains', // contains(vehicle.accountIds, :accountId)
  'begins_with',
  'IN', // tripId IN (${tripIdSets.map((item)=>item.key).join(', ')}) // { key: ':tripId', value: String }
  // 'size =',
  // 'size <',
  // 'size <=',
  // 'size >',
  // 'size >=',
];

export const joins = [
  'AND',
  'OR',
  'NOT',
];
