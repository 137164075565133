import React, { Component, Fragment } from 'react';

import {
  request,
  tableCellStatusRenderer,
} from '../utils';

import Table from '../components/Table';

import './Permission.css';

export default class Permission extends Component {
  state = {
    data: [],
  }

  componentDidMount = () => {
    this.getPermissions();
  }

  getPermissions = () => {
    this.setState({ loading: true });
    return Promise.resolve()
      .then(() => {
        const options = {
          api: `/api/permissions`,
          method: 'GET',
          mode: 'cors',
        };
        return request(options);
      })
      .then((res) => {
        this.setState({
          data: res.data,
        });
      })
      .catch(console.error)
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  render() {
    const headers = [{
      name: 'event',
      label: 'Event',
      options: {
        filter: true,
      },
    }, {
      name: 'env',
      label: 'Environment',
      options: {
        filter: true,
      },
    }, {
      name: 'team',
      label: 'Team',
      options: {
        filter: true,
      },
    }, {
      name: 'role',
      label: 'Role',
      options: {
        filter: true,
      },
    }, {
      name: 'isAuthorized',
      label: 'Authorized',
      options: {
        filter: true,
        customBodyRender: tableCellStatusRenderer({
          YES: 1,
          NO: 2,
        }),
      },
    }, {
      name: 'users',
      label: 'Users',
      options: {
        filter: false,
        display: false,
        customBodyRender: (value)=>{
          return (
            <Fragment>
              {value.map((item, index)=>(
                <Fragment key={index}>{item}<br/></Fragment>
              ))}
            </Fragment>
          );
        },
      },
    }];

    const onItemClick = (rowData, rowMeta) => {
      const item = this.state.data[rowMeta.dataIndex];
      console.log(item);
    };

    const data = this.state.data;
    const options = {
      filterType: 'checkbox',
      onRowClick: onItemClick,
      rowsPerPageOptions: [50, 100, 200],
      rowsPerPage: 50,
      expandableRows: false,
    };

    return (
      <Fragment>
        <Table
          title={'Permissions'}
          data={data}
          columns={headers}
          options={options}
        />
      </Fragment>);
  }
}
