import React from 'react';
import PropTypes from 'prop-types';

const ASSET_TRANSACTION_TX_STATUS = {
  0: {
    label: 'PENDING',
    color: 'grey',
  },
  1: {
    label: 'SUCCESS',
    color: 'green',
  },
  2: {
    label: 'FAILED',
    color: 'red',
  },
  3: {
    label: 'DISREGARD',
    color: 'black',
  },
  20: {
    label: 'FREE_TOLL',
    color: 'blue',
  },
};

export default function StatusBadge({ status }) {
  const matched = ASSET_TRANSACTION_TX_STATUS[status] || { label: 'UNKNOWN', color: 'orange' };
  const style = {
    color: 'white',
    backgroundColor: matched.color,
    paddingTop: 1,
    paddingBottom: 1,
    paddingLeft: 4,
    paddingRight: 4,
    borderRadius: 4,
    fontWeight: 'bold',
    fontSize: 12,
    height: 18,
  };

  return (
    <div style={style}>
      {matched.label}
    </div>
  );
}

StatusBadge.propTypes = {
  status: PropTypes.number.isRequired,
};
