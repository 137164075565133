import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DirectionsCar from '@material-ui/icons/DirectionsCar';
import DetailForm from 'react-material-final-form';
import moment from 'moment';

import { request } from '../utils';

const metadata = {
  fields: [{
    key: 'lpn',
    label: 'License Plate',
    type: 'String',
    isRequired: true,
  }, {
    key: 'state',
    label: 'State',
    type: 'String',
    isRequired: true,
  }, {
    key: 'nickname',
    label: 'Nickname',
    type: 'String',
    isRequired: false,
  }, {
    key: 'registerDate',
    label: 'Register Date',
    type: 'String',
    formType: 'date',
    formFormatFunction(value) {
      return moment(value).format('YYYY-MM-DD');
    },
    formParseFunction(value) {
      return moment(value).toISOString();
    },
  }],
};

const defaultData = {
  state: 'VA',
  registerDate: moment(Date.now() - 7 * 24 * 60 * 60 * 1000).format('YYYY-MM-DD'),
};

export default function AddVehicleModal({ accountId, onUpdate }) {
  const env = window.localStorage.getItem('env');

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const submit = async (data) => {
    if (env === 'prd') {
      return console.log('Can not purge data in prd.');
    }

    console.log(data);
    setLoading(true);

    const options = {
      api: `/api/account/${accountId}/addVehicle`,
      query: data,
      method: 'GET',
      mode: 'cors',
    };

    await request(options);

    setLoading(false);
    setOpen(false);

    onUpdate && onUpdate();
  };

  return (
    <Fragment>
      <Button color="primary" onClick={()=>setOpen(true)} disabled={loading}>
        <DirectionsCar /> Add a vehicle
      </Button>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={open}
        onClose={()=>setOpen(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Add a Vehicle
        </DialogTitle>
        <DialogContent>
          <DetailForm
            metadata={metadata}
            data={defaultData}
            isLoading={loading}
            usePristine={false}
            onSubmit={submit}
            submitButtonText={'Submit'}
            submitButtonProps={{
              variant: 'contained',
              color: 'primary',
              type: 'submit',
              fullWidth: true,
            }}
          />
        </DialogContent>
      </Dialog>
    </Fragment>
  );
}

AddVehicleModal.propTypes = {
  accountId: PropTypes.string,
  onUpdate: PropTypes.func,
};
