import React, { Component } from 'react';
import moment from 'moment';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import { request, formatCurrency } from '../utils';

export default class AssetDetails extends Component {
  state = {
    timestamp: Date.now(), // - 60 * 60 * 1000,
  }

  componentDidMount() {
    this.getPricing();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.imgUrl !== this.props.imgUrl) {
      this.getPricing();
    }
  }

  getPricing = () => {
    const { od = {} } = this.props;
    const { assetId, id } = od;
    if (!assetId || !id) {
      return;
    }

    return Promise.resolve()
      .then(() => {
        const options = {
          api: `/api/assets/${assetId}/roadModels/${id}/pricing`,
          query: {
            timestamp: this.props.timestamp || this.state.timestamp,
          },
          method: 'GET',
          mode: 'cors',
        };

        return request(options);
      })
      .then((res) => {
        const pricingData = res.data;
        if (pricingData) {
          this.setState({
            priceString: `$${pricingData.price} - ${pricingData.pricingMethod} - ${pricingData.status}`,
            price: pricingData.price,
            pricingData,
          });
        } else {
          this.setState({ price: null, priceString: 'No Pricing Data' });
        }
      });
  }

  render() {
    const { imgUrl = '', od = { details: {} }, origin = {}, destination = {} } = this.props;

    return (<Paper className="app-container">
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h5">
            {od.name} ({od.id})
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} className="img-container">
          <img src={imgUrl} alt=""/>
          <p>
            <a href={imgUrl} target="_blank" rel="noopener noreferrer">{imgUrl}</a>
          </p>
          <Typography variant="h6">Asset: {od.assetId}</Typography>
          <Typography variant="h6">Name: {od.name}</Typography>
          <Typography variant="h6">Status: {(this.state.pricingData || {}).status}</Typography>
          <Typography variant="h6">Price: {formatCurrency((this.state.pricingData || {}).price)}</Typography>
          <Typography variant="h6">Origin: {(od || {}).name_source}</Typography>
          <Typography variant="h6">Destination: {(od || {}).name_source}</Typography>
          <hr/>
          <Typography variant="body1">OD</Typography>
          <pre>{JSON.stringify(od, null, 2)}</pre>
        </Grid>
        <Grid item xs={12} md={6} className="detais-scroll-container">
          <Typography variant="body1">Pricing @ {moment(this.state.timestamp).format('YYYY-MM-DDTHH:mm')}</Typography>
          <pre>{JSON.stringify(this.state.pricingData, null, 2)}</pre>
          <Typography variant="body1">Origin</Typography>
          <pre>{JSON.stringify(origin, null, 2)}</pre>
          <Typography variant="body1">Destination</Typography>
          <pre>{JSON.stringify(destination, null, 2)}</pre>
        </Grid>
      </Grid>
    </Paper>);
  }
}
