import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';

import Table from './Table';
import {
  sortBy,
  request,
  tableCellTimeRenderer,
  tableCellStatusRenderer,
  tableCellAccountIdRenderer,
  tableCellTripIdRenderer,
} from '../utils';

export default class NotificationTable extends Component {
  state = {
    data: [],
  }

  componentDidMount() {
    this.load();
  }

  load = () => {
    if (this.props.data) return;
    if (this.props.accountId) {
      return Promise.resolve()
        .then(() => {
          const options = {
            api: `/api/account/${this.props.accountId}/notifications`,
            method: 'GET',
            mode: 'cors',
          };
          return request(options);
        })
        .then((res) => {
          this.setState({
            data: res.data,
          });
        })
        .catch(console.log);
    } else
    if (this.props.tripId) {
      return Promise.resolve()
        .then(() => {
          const options = {
            api: `/api/trip/${this.props.tripId}/notifications`,
            method: 'GET',
            mode: 'cors',
          };
          return request(options);
        })
        .then((res) => {
          this.setState({
            data: res.data,
          });
        })
        .catch(console.log);
    }
  }

  render = () => {
    const headers = [{
      name: 'notificationID',
      label: 'ID',
      options: {
        filter: false,
      },
    }, {
      name: 'code',
      label: 'Code',
    }, {
      name: 'accountID',
      label: 'Account ID',
      options: {
        filter: false,
        customBodyRender: tableCellAccountIdRenderer(),
      },
    }, {
      name: 'tripId',
      label: 'Trip ID',
      options: {
        filter: false,
        customBodyRender: tableCellTripIdRenderer(),
      },
    }, {
      name: 'email',
      label: 'Email',
      options: {
        filter: false,
      },
    }, {
      name: 'notificationType',
      label: 'Type',
    }, {
      name: 'title',
      label: 'Title',
      options: {
        filter: false,
      },
    }, {
      name: 'message',
      label: 'Message',
      options: {
        filter: false,
      },
    }, {
      name: 'note',
      label: 'Note',
      options: {
        filter: false,
        display: false,
      },
    }, {
      name: 'unread',
      label: 'Unread',
      options: {
        customBodyRender: tableCellStatusRenderer({
          0: 1,
          1: 2,
        }),
      },
    }, {
      name: 'createdDate',
      label: 'Created At',
      options: {
        filter: false,
        customBodyRender: tableCellTimeRenderer(true),
      },
    }].map((item) => {
      if (this.props.accountId) {
        if (['accountID'].includes(item.name)) {
          item.options = {
            filter: false,
            display: false,
          };
        }
      }
      return item;
    });

    const data = (this.props.data || this.state.data)
      .sort(sortBy('createdAt', true))
      .sort(sortBy('updatedAt', true))
      .map((item) => {
        headers.forEach((header) => {
          item[header.name] = (item[header.name] !== undefined) ? item[header.name] : '';
        });
        return item;
      });

    const onItemClick = (rowData, rowMeta) => {
      const item = data[rowMeta.dataIndex];
      console.log(item);
    };

    const options = {
      filterType: 'checkbox',
      onRowClick: onItemClick,
      rowsPerPageOptions: [10, 50, 100],
      rowsPerPage: 10,
    };

    data
      .sort(sortBy('createdDate', true));

    return (
      <Grid item xs={12}>
        <Table
          title={this.props.title || 'Transactions'}
          data={data}
          columns={headers}
          options={options}
          nested={this.props.nested}
        />
      </Grid>);
  }
}
