import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import FailedAssetTransactionHandler from './FailedAssetTransactionHandler';

export default function FailedAssetTransactionHandlerDialog({
  onClose,
  ...args
}) {
  return (
    <Dialog
      fullWidth={true}
      maxWidth="lg"
      open={true}
      onClose={() => {}}
      aria-labelledby="form-dialog-title"
    >
      <DialogContent style={{ backgroundColor: 'rgb(230, 230, 230)' }}>
        <FailedAssetTransactionHandler {...args} />
      </DialogContent>
      <DialogActions style={{ padding: 16 }}>
        <Button
          onClick={onClose}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

FailedAssetTransactionHandlerDialog.propTypes = {
  onClose: PropTypes.func,
};
