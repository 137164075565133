import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import convert from 'convert-units';
import { saveAs } from 'file-saver';

import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';

import moment from 'moment';
import metadata from './metadata';

const AdapterNavLink = React.forwardRef((props, ref) => <NavLink innerRef={ref} {...props} />);

/**
 * formatTime
 * @param {String|Number} inTime
 * @return {String}
 */
export function formatTime(inTime) {
  return inTime ? moment(inTime).local().format('YYYY-MM-DD HH:mm:ss') : 'N/A';
}

/**
 * Get time difference
 * @param {String|Number} inTime
 * @return {String}
 */
export function diffTime(inTime) {
  if (!inTime) return '';

  return moment(inTime).fromNow();
}

/**
 * sort array by field
 * @param {String} inField
 * @param {Boolean} inReverse
 * @return {Function};
 */
export function sortBy(inField, inReverse = false) {
  return (a, b) => {
    const val = (!inReverse) ? 1 : -1;
    if ((a[inField] === b[inField])) return 0;
    return (a[inField] > b[inField]) ? 1 * val : 1 * -val;
  };
}

/**
 * Table Renderer
 * @param {String} align
 * @return {Function}
 */
export function tableRenderer(align = 'right') {
  /* eslint-disable-next-line react/display-name */
  return (value) => {
    return (
      <Typography variant="body2" align={align}>
        {`${value}`}
      </Typography>);
  };
}

/**
 * Table Renderer: Time
 * @param {Boolean} withDiff
 * @return {Function}
 */
export function tableCellTimeRenderer(withDiff = true) {
  /* eslint-disable-next-line react/display-name */
  return (value) => {
    return (
      <Fragment>
        {formatTime(value)}
        { withDiff &&
          <Fragment>
            <br/>
            <Typography variant="body2" color="textSecondary">{diffTime(value)}</Typography>
          </Fragment>}
      </Fragment>);
  };
}

/**
 * Table Renderer: Boolean
 * @param {String} align
 * @return {Function}
 */
export function tableCellBooleanRenderer(align = 'center') {
  /* eslint-disable-next-line react/display-name */
  return (value) => {
    let color = 'textSecondary';
    switch (value) {
    case true:
      color = 'textPrimary';
      break;
    case false:
    default:
      color = 'error';
      break;
    }
    return (
      <Typography variant="body2" color={color} align={align}>
        {`${value}`}
      </Typography>);
  };
}

/**
 * Table Renderer: Image
 * @param {Boolean} sm
 * @return {Function}
 */
export function tableCellImageRenderer(sm) {
  /* eslint-disable-next-line react/display-name */
  return (value) => {
    return (
      <div className={sm?'table-image-container-small':'table-image-container'}>
        <img src={value} alt="Img is missing or not a valid OD"/>
      </div>);
  };
}

/**
 * Table Renderer: Currency
 * @param {String} align
 * @return {Function}
 */
export function tableCellCurrencyRenderer(align = 'right') {
  /* eslint-disable-next-line react/display-name */
  return (value) => {
    return (
      <Typography variant="body2" align={align}>
        {`${formatCurrency(value)}`}
      </Typography>);
  };
}


/**
 * Table Renderer: Image
 * @param {Object} statusMapping
 * @param {String} align
 * @return {Function}
 */
export function tableCellStatusRenderer(statusMapping = {}, align = 'center') {
  /* eslint-disable-next-line react/display-name */
  return (value) => {
    let color = 'textSecondary';
    const mappedValue = (statusMapping[value] !== void(0)) ? statusMapping[value] : value;
    switch (mappedValue) {
    case 0:
      color = 'textSecondary';
      break;
    case 1:
      color = 'textPrimary';
      break;
    default:
      color = 'error';
    }
    return (
      <Typography variant="body2" color={color} align={align}>
        {value}
      </Typography>
    );
  };
}
/**
 * @param {String|Number} inMeters
 * @return {String}
 */
export function displayDistance(inMeters) {
  const ft = convert(inMeters).from('m').to('ft-us').toFixed(2);

  return `${ft} ft (${inMeters.toFixed(2)} m)`;
}

/**
 * Table Renderer: AccountId
 * @param {String} inButtonTitle
 * @param {String} target
 * @param {Object} options
 * @return {Function}
 */
export function tableCellAccountIdRenderer(inButtonTitle, target = '_self', options = {}) {
  const env = window.localStorage.getItem('env');
  const { variant = 'body2' } = options;
  /* eslint-disable-next-line react/display-name */
  return (value) => {
    if (!inButtonTitle) {
      return (<Link
        variant={variant}
        component={AdapterNavLink}
        target={target}
        to={`/account/${value}?env=${env}`}
      >
        {value || ''}
      </Link>);
    } else {
      return (
        <Button
          variant="outlined"
          color="primary"
          size="small"
          component={AdapterNavLink}
          target={target}
          to={`/account/${value}?env=${env}`}
        >
          {inButtonTitle}
        </Button>);
    }
  };
}

/**
 * Table Renderer: CaseId
 * @param {String} target
 * @param {Object} options
 * @return {Function}
 */
export function tableCellCaseIdRenderer(target = '_blank', options = {}) {
  const { variant = 'body2' } = options;
  /* eslint-disable-next-line react/display-name */
  return (value) => {
    return (<Link
      variant={variant}
      component={AdapterNavLink}
      target={target}
      to={`/case?id=${value}`}
    >
      {value || ''}
    </Link>);
  };
}

/**
 * Table Renderer: Trip
 * @param {String} inButtonTitle
 * @param {String} target
 * @return {Function}
 */
export function tableCellTripIdRenderer(inButtonTitle, target = '_self') {
  const env = window.localStorage.getItem('env');
  /* eslint-disable-next-line react/display-name */
  return (value) => {
    if (!inButtonTitle) {
      return (<Link
        variant="body2"
        component={AdapterNavLink}
        target={target}
        to={`/trip/${value}?env=${env}`}
      >
        {value || ''}
      </Link>);
    } else {
      return (
        <Button
          variant="outlined"
          color="primary"
          size="small"
          component={AdapterNavLink}
          target={target}
          to={`/trip/${value}?env=${env}`}
        >
          {inButtonTitle}
        </Button>);
    }
  };
}

/**
 * Table Renderer: Program
 * @param {String} inButtonTitle
 * @param {String} target
 * @return {Function}
 */
export function tableCellProgramIdRenderer(inButtonTitle, target = '_self') {
  const env = window.localStorage.getItem('env');
  /* eslint-disable-next-line react/display-name */
  return (value) => {
    if (!inButtonTitle) {
      return (<Link
        variant="body2"
        component={AdapterNavLink}
        target={target}
        to={`/program/${value}?env=${env}`}
      >
        {value || ''}
      </Link>);
    } else {
      return (
        <Button
          variant="outlined"
          color="primary"
          size="small"
          component={AdapterNavLink}
          target={target}
          to={`/program/${value}?env=${env}`}
        >
          {inButtonTitle}
        </Button>);
    }
  };
}

/**
 * Request
 * @param {Object} inOptions
 * @param {Boolean} isFile
 * @return {Promise}
 */
export function request(inOptions, isFile = false) {
  const { api = '/', query = {} } = inOptions;

  query.env = query.env || window.localStorage.getItem('env');

  delete inOptions.url;
  delete inOptions.query;

  const url = `${metadata.api.endpoint}${api}?${Object.keys(query).map((key)=>`${key}=${query[key]}`).join('&')}`
    .replace(/\/\//g, '/');

  Object.assign(inOptions, {
    headers: Object.assign({
      'x-access-token': window.localStorage.getItem('accessToken'),
      'x-id-token': window.localStorage.getItem('idToken'),
      'x-state-name': window.location.pathname,
      'Content-Type': 'application/json',
    }, (inOptions.headers || {})),
  });
  if (isFile) {
    delete inOptions.headers['Content-Type'];
  }
  console.log(url, inOptions);
  return fetch(url, inOptions)
    .then(async (response) => {
      console.log(response);
      // redirect to the login page
      if (response.status === 401) {
        toastr.warning('You are not authorized.', 'Please use your .tech credentials to login.');
        return response.json()
          .then((res) => {
            const loginUrl = res.data.loginUrl.replace('state=state', `state=${window.location.pathname}`);
            return window.open(loginUrl, '_self');
          });
      } else
      if (response.status === 403) {
        return response.json()
          .then((res) => {
            toastr.warning('Sorry, you are not allowed to perform this action.');
            return Promise.reject(res);
          });
      } else
      if (response.status !== 200) {
        return response.json()
          .then((error) => {
            toastr.error(`${inOptions.method.toUpperCase()}: ${inOptions.api}`, `${response.status} ${JSON.stringify(error.message || error)}`);
            error.status = response.status;
            return Promise.reject(error);
          });
      }
      try {
        // toastr.success('The title', 'The message');
        const data = await response.clone().json();
        return data;
      } catch (e) {
        return response;
      }
    });
}

/**
 * @return {String} Random hex color
 */
export function randomHexColor() {
  return '#' + (Math.random() * 0xFFFFFF << 0).toString(16);
}

/**
 * formatCurrency
 * @param {Number} inCents
 * @return {String}
 */
export function formatCurrency(inCents = 0) {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(inCents / 100);
}

export function formatNumber(inNumber = 0) {
  return `${(inNumber || 0).toLocaleString()}`;
}

export function formatDollars(inDollars = 0) {
  return `$${(inDollars || 0).toLocaleString(undefined, { minimumFractionDigits: 2 })}`;
}

/**
 * sortByList
 * @param {Array} inList
 * @return {Array}
 */
export function sortByList(inList = []) {
  return (a, b) => {
    const aIndex = inList.indexOf(a);
    const bIndex = inList.indexOf(b);

    if (aIndex === -1 && bIndex === -1) {
      return (a > b) ? 1 : -1;
    } else
    if (aIndex > -1 && bIndex === -1) {
      return -1;
    } else
    if (aIndex === -1 && bIndex > -1) {
      return 1;
    } else {
      return (aIndex > bIndex) ? 1 : -1;
    }
  };
}

/**
 * @return {String} Return Origin
 */
export function getOrigin() {
  const { origin } = window.location;
  if (origin === 'http://localhost:3000') {
    return 'http://localhost:8888';
  } else {
    return 'https://admin.ops.mbtl.cloud';
  }
}

/**
 * sortObject by key alphabet
 * @param {Object} inObject
 * @return {Object}
 */
export function sortObject(inObject) {
  let sorted = inObject;

  if (!sorted) return sorted;

  if (Array.isArray(sorted)) {
    sorted = sorted.map(sortObject);
  } else
  if (typeof sorted === 'object') {
    sorted = Object.keys(inObject).sort((a, b) => a > b ? 1 : -1).reduce((obj, key) => {
      obj[key] = sortObject(inObject[key]);
      return obj;
    }, {});
  }

  return sorted;
}

export function truncateString(str, num) {
  // If the length of str is less than or equal to num
  // just return str--don't truncate it.
  if (str.length <= num) {
    return str;
  }
  // Return str truncated with '...' concatenated to the end of str.
  return str.slice(0, num) + '...';
}

export const download = (filename, blob) => {
  saveAs(blob, filename);
};
