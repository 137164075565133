import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import SummaryCard from './components/SummaryCard';
import PieCard from './components/PieCard';
import BarCard from './components/BarCard';
import PlotCard from './components/PlotCard';

import { getChartDataMappings } from './charts';
import { request } from '../../utils';

import {
  getStartEndByPeriodName,
  getTodayDate,
} from './helpers';

const periodOptions1 = [{
  label: 'Past 365 days',
  value: 'day_-365_day_-1',
}, {
  label: 'Past 180 days',
  value: 'day_-180_day-1',
}, {
  label: 'Past 90 days',
  value: 'day_-90_day-1',
}, {
  label: 'Past 30 days',
  value: 'day_-30_day_-1',
}, {
  label: 'Past 7 days',
  value: 'day_-7_day_-1',
}, {
  label: 'Past 3 days',
  value: 'day_-3_day_-1',
}];

const periodOptions2 = [{
  label: 'Last Year',
  value: 'year_last',
}, {
  label: 'Last Month',
  value: 'month_last',
}, {
  label: 'Last Week',
  value: 'week_last',
}, {
  label: 'Yesterday',
  value: 'day_-1_day_-1',
}];

const periodOptions3 = [{
  label: 'Year',
  value: 'year',
}, {
  label: 'Month',
  value: 'month',
}, {
  label: 'Week',
  value: 'week',
}, {
  label: `Today ${getTodayDate()}`,
  value: 'day_0_day_0',
}];

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    // backgroundColor: Colors.background.light,
    minHeight: 'calc(100vh - 64px)',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  filterContainer: {
    padding: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  buttonGroup: {
    'display': 'flex',
    'flexDirection': 'column',
    'alignItems': 'center',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

export default function Dashboard() {
  const classes = useStyles();

  const [data, setData] = useState({});
  const [chartData, setChartData] = useState();
  const [period, setPeriod] = useState(window.localStorage.getItem('dashboard:period') || 'day_-1_day_-1');
  const [isLoading, setIsLoading] = useState(true);
  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      window.localStorage.setItem('dashboard:period', period);

      const {
        startDate,
        endDate,
        prevStartDate,
        prevEndDate,
      } = getStartEndByPeriodName(period);

      setTitle(`${startDate} ~ ${endDate}`.replace(/-/g, '/'));
      setSubtitle(`${prevStartDate} ~ ${prevEndDate}`.replace(/-/g, '/'));

      console.log({
        period,
        startDate,
        endDate,
        prevStartDate,
        prevEndDate,
      });

      const [
        { data },
        { data: prevData },
      ] = (await Promise.all([
        request({
          api: '/api/dashboard',
          query: {
            startDate,
            endDate,
            realtime: period === 'day_0_day_0' ? 'true' : undefined,
          },
          method: 'GET',
          mode: 'cors',
        }),
        request({
          api: '/api/dashboard',
          query: {
            startDate: prevStartDate,
            endDate: prevEndDate,
          },
          method: 'GET',
          mode: 'cors',
        }),
      ]));
      console.log(data);

      setData({ data, prevData });

      setIsLoading(false);
    })();
  }, [period]);

  useEffect(() => {
    const chartDataMappings = getChartDataMappings(data);
    const chartData = Object.keys(chartDataMappings)
      .map((key) => chartDataMappings[key]);

    setChartData(chartData);
  }, [data]);

  if (isLoading || !chartData) {
    return (
      <Grid container justifyContent="center" className={classes.container}>
        <CircularProgress color="primary" />
      </Grid>);
  }

  return (
    <Container maxWidth={false} className={classes.container}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper className={classes.filterContainer}>
            <Grid container spacing={2} justifyContent="center" alignItems="center">
              <div style={{ flex: 1, paddingLeft: 8 }}>
                <Typography variant="h5" component="h5" color="textPrimary">
                  {title}
                </Typography>
                <Typography variant="h5" component="h5" color="textSecondary">
                  {subtitle}
                </Typography>
              </div>
              <div className={classes.buttonGroup}>
                <ButtonGroup color="primary" aria-label="outlined primary button group">
                  {periodOptions1.map((item, index)=>(
                    <Button
                      key={index}
                      disabled={isLoading}
                      variant={period === item.value ? 'contained':'outlined'}
                      onClick={() => setPeriod(item.value)}
                    >
                      {item.label}
                    </Button>
                  ))}
                </ButtonGroup>
              </div>
              <div className={classes.buttonGroup}>
                <ButtonGroup color="primary" aria-label="outlined primary button group">
                  {periodOptions2.map((item, index)=>(
                    <Button
                      key={index}
                      disabled={isLoading}
                      variant={period === item.value ? 'contained':'outlined'}
                      onClick={() => setPeriod(item.value)}
                    >
                      {item.label}
                    </Button>
                  ))}
                </ButtonGroup>
              </div>
              <div className={classes.buttonGroup}>
                <ButtonGroup color="primary" aria-label="outlined primary button group">
                  {periodOptions3.map((item, index)=>(
                    <Button
                      key={index}
                      disabled={isLoading}
                      variant={period === item.value ? 'contained':'outlined'}
                      onClick={() => setPeriod(item.value)}
                    >
                      {item.label}
                    </Button>
                  ))}
                </ButtonGroup>

              </div>
            </Grid>
          </Paper>
        </Grid>

        {chartData.map((data, index) => (
          <Grid item xs={12} md={data.width || 2} key={index}>
            {renderChart(data, period)}
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

function renderChart({ type, title, value, prevValue, unit, appendix, data, data2, dataKeys, mode }, period) {
  switch (type) {
  case 'summary':
    return (<SummaryCard title={title} value={value} prevValue={prevValue} unit={unit} appendix={appendix} period={period} />);
  case 'pie':
    return (<PieCard title={title} data={data} data2={data2} />);
  case 'bar':
    return (<BarCard title={title} data={data} dataKeys={dataKeys} mode={mode} />);
  case 'plot':
    return (<PlotCard title={title} data={data} />);
  default:
  }
}
