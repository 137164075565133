import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';

import Table from '../components/Table';
import './Postman.css';
import {
  request,
  sortBy,
  tableCellTimeRenderer,
} from '../utils';

export default class Postman extends Component {
  state = {
    loading: false,
    info: {},
    backups: [],
    collections: [],
  }

  componentDidMount() {
    this.loadInfo();
    this.loadBackups();
    this.loadCollections();
  }

  loadInfo = () => {
    return Promise.resolve()
      .then(() => {
        const options = {
          api: `/api/postman/backupInfo`,
          method: 'GET',
          mode: 'cors',
        };
        return request(options);
      })
      .then((res) => {
        this.setState({ info: res.data });
      })
      .catch(console.log);
  }

  loadBackups = () => {
    return Promise.resolve()
      .then(() => {
        const options = {
          api: `/api/postman/backups`,
          method: 'GET',
          mode: 'cors',
        };
        return request(options);
      })
      .then((res) => {
        this.setState({ backups: res.data });
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  loadCollections = () => {
    return Promise.resolve()
      .then(() => {
        const options = {
          api: `/api/postman/collections`,
          method: 'GET',
          mode: 'cors',
        };
        return request(options);
      })
      .then((res) => {
        this.setState({ collections: res.data });
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  createBackup = () => {
    this.setState({ loading: true });
    return Promise.resolve()
      .then(() => {
        const options = {
          api: `/api/postman/backups`,
          method: 'POST',
          mode: 'cors',
        };
        return request(options);
      })
      .then(() => {
        return this.loadBackups();
      })
      .catch((err) => {
        console.error(err);
      });
  }

  removeTestUsers = () => {
    this.setState({ loading: true });
    return Promise.resolve()
      .then(() => {
        const options = {
          api: `/api/postman/testUsers`,
          method: 'DELETE',
          mode: 'cors',
        };
        return request(options);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  render() {
    return (
      <Grid container className="main" justify="center" spacing={2} style={{ padding: 16 }}>
        <Grid item xs={12}>
          <Paper className="app-container">
            <Grid container>
              <Grid item xs={12} md={8}>
                <Typography variant="h5">
                  Postman
                </Typography>
              </Grid>
            </Grid>
            <br/>
            <Grid container>
              <Grid item xs={12} md={12}>
                <Typography variant="body1">
                  Backup {this.state.info.cronTime} to&nbsp;
                  <a href={this.state.info.link} target="_blank" rel="noopener noreferrer">
                    {this.state.info.bucket}({this.state.info.region})
                  </a>
                </Typography>
              </Grid>
              <br/><br/>
              <Grid item xs={12} md={4}>
                <Button variant="outlined" size="small" disabled={this.state.loading} onClick={this.createBackup}>
                  Create a new backup now
                </Button>
              </Grid>
              <Grid item xs={12} md={4}>
                <Button variant="outlined" size="small" disabled={this.state.loading} onClick={this.removeTestUsers}>
                  Remove Pipeline Test Users from OKTA
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {this.renderTable('Backup History', this.state.backups)}
        {this.renderCollectionTable('Collections', this.state.collections)}
      </Grid>);
  }

  renderTable(inTitle, inData) {
    const headers = [{
      name: 'name',
      label: 'Name',
      options: {
        filter: false,
      },
    }, {
      name: 'category',
      label: 'Category',
    }, {
      name: 'createdAt',
      label: 'Created At',
      options: {
        filter: false,
        customBodyRender: tableCellTimeRenderer(true),
      },
    }, {
      name: 'link',
      label: 'Link',
      options: {
        filter: false,
        display: true,
        customBodyRender: (value, tableMeta) => {
          return (<Button
            variant="outlined"
            size="small"
            onClick={() => {
              window.open(value, '_blank');
            }}>
            S3
          </Button>);
        },
      },
    }];

    const data = (inData || this.state.dataNodes).sort(sortBy('createdAt', true));

    const onItemClick = (rowData, rowMeta) => {
      const item = data[rowMeta.dataIndex];
      console.log(item);
    };

    const options = {
      filterType: 'checkbox',
      onRowClick: onItemClick,
      rowsPerPageOptions: [10, 100, 200],
      rowsPerPage: 10,
    };

    return (
      <Grid item xs={12}>
        <Table
          title={inTitle}
          data={data}
          columns={headers}
          options={options}
          nested={true}
        />
      </Grid>);
  }

  renderCollectionTable(inTitle, inData) {
    const headers = [{
      name: 'id',
      label: 'ID',
      options: {
        filter: false,
      },
    }, {
      name: 'name',
      label: 'Name',
      options: {
        filter: false,
      },
    }, {
      name: 'ownerName',
      label: 'Owner Name',
    }, {
      name: 'owner',
      label: 'Owner ID',
    }, {
      name: 'uid',
      label: 'UID',
      options: {
        filter: false,
      },
    }];

    const data = (inData).sort(sortBy('name', false));

    const onItemClick = (rowData, rowMeta) => {
      const item = data[rowMeta.dataIndex];
      console.log(item);
    };

    const options = {
      filterType: 'checkbox',
      onRowClick: onItemClick,
      rowsPerPageOptions: [10, 100, 200],
      rowsPerPage: 10,
    };

    return (
      <Grid item xs={12}>
        <Table
          title={inTitle}
          data={data}
          columns={headers}
          options={options}
          nested={true}
        />
      </Grid>);
  }
}
