import React, { Component, Fragment } from 'react';


import './Promotion.css';

import { request } from '../utils';
import ProgramTable from '../components/ProgramTable';
import CreateProgramModal from '../components/CreateProgramModal';

export default class Promotion extends Component {
  state = {
    env: window.localStorage.getItem('env'),
    canEdit: true, // window.localStorage.getItem('env') !== 'prd',
    programs: [],
  }
  componentDidMount() {
    this.load();
  }

  load() {
    return Promise.resolve()
      .then(() => {
        const options = {
          api: `/api/promotion/programs`,
          method: 'GET',
          mode: 'cors',
        };
        return request(options);
      })
      .then((res) => {
        this.setState({
          programs: res.data,
        });
      })
      .catch(console.error);
  }

  render() {
    return (
      <Fragment>
        {this.state.canEdit && <CreateProgramModal onUpdate={()=>this.load()}/>}
        <ProgramTable
          data={this.state.programs}
          onUpdate={()=>this.load()}
          maxHeight="calc(100vh - 120px)"/>
      </Fragment>);
  }
}
