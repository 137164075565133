import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Board from 'react-trello';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import { diffTime, truncateString, request, sortBy } from '../utils';
import Case from './Case';
import { caseStatus } from './constants';

import './KanbanBoard.css';

const useStyles = makeStyles((theme) => ({
  openInNewButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
}));

const convertCaseToCard = (caseItem) => {
  return {
    id: caseItem.caseID,
    title: truncateString(caseItem.title, 20),
    description: truncateString(caseItem.description, 50),
    label: diffTime(caseItem.itemUpdatedAt), //  || caseItem.lastMessageAt || caseItem.createdAt
    itemUpdatedAt: caseItem.itemUpdatedAt,
    metadata: caseItem,
    style: {
      backgroundColor: caseItem.isSpam ? 'rgba(255,0,0,0.1)' : undefined,
    },
    tags: [
      {
        bgcolor: 'red',
        color: 'white',
        title: caseItem.isSpam ? 'SPAM' : '',
      },
      {
        bgcolor: 'grey',
        color: 'white',
        title: caseItem.source || '',
      },
      {
        bgcolor: '#008223',
        color: 'white',
        title: caseItem.category,
      },
      {
        bgcolor: '#0079BF',
        color: 'white',
        title: caseItem.name || '',
      },
      {
        bgcolor: '#eeeeee',
        color: '#111111',
        title: moment(caseItem.createdAt).local().format('MM/DD/YYYY HH:mm A'),
      },
    ].filter(({ title }) => title),
  };
};

let updatedSelectedCaseData;

const KanbanBoard = ({ cases: inCases }) => {
  const classes = useStyles();

  const [cases, setCases] = useState([]);
  const [lanes, setLanes] = useState();
  const [selectedCase, setSelectedCase] = useState();

  const handleCardClick = (id, caseData, laneId) => {
    console.log('handleCardClick', caseData);
    setSelectedCase(caseData);
  };

  const handleCardDelete = (card) => {
    console.log('handleCardDelete', card);
  };

  const handleDataChange = (...args) => {
    // console.log('handleDataChange', args);
  };

  const handleDragEnd = async (cardId, sourceLaneId, targetLaneId, position, cardDetails) => {
    if (sourceLaneId === targetLaneId) return;

    try {
      const options = {
        api: `/api/customerCases/${cardId}`,
        method: 'PUT',
        body: JSON.stringify({
          status: targetLaneId,
        }),
        mode: 'cors',
      };

      await request(options);

      const i = cases.findIndex(({ caseID }) => caseID === cardId);
      cases[i].status = targetLaneId;
      setCases([...cases]);
    } catch (e) {
      console.log(e);
    }
  };

  const handleDialogClose = () => {
    setSelectedCase(null);

    if (updatedSelectedCaseData) {
      console.log('updatedSelectedCaseData', updatedSelectedCaseData);
      const i = cases.findIndex(({ caseID }) => caseID === updatedSelectedCaseData.caseID);
      cases[i] = updatedSelectedCaseData;
      setCases([...cases]);
    }

    updatedSelectedCaseData = null;
  };

  useEffect(() => {
    if (inCases) {
      setCases(inCases);
    }
  }, [inCases]);

  useEffect(() => {
    const lanes = caseStatus.map((item) => {
      const matchedCases = cases.filter(({ status }) => status === item);
      return {
        id: item,
        title: item,
        label: matchedCases.length,
        droppable: true,
        cards: matchedCases.map(convertCaseToCard),
      };
    });

    setLanes([...lanes]);
  }, [cases]);

  if (!lanes) return null;

  // https://github.com/rcdexta/react-trello/blob/c4a59657f644ea39303e2f9aeac82ee6cc2724a9/src/controllers/Lane.js#L279
  return <React.Fragment>
    <Board
      draggable={false}
      editable={false}
      onCardClick={handleCardClick}
      onBeforeCardDelete={handleCardDelete}
      onDataChange={handleDataChange}
      handleDragEnd={handleDragEnd}
      data={{ lanes }}
      style={{ height: 'calc(100vh - 64px)' }}
      laneSortFunction={sortBy('itemUpdatedAt', true)}
    />
    {selectedCase &&
      <Dialog
        open={true}
        onClose={handleDialogClose}
        fullScreen
        disableBackdropClick={true}
        // disableEscapeKeyDown={true}
      >
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleDialogClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6">
            Case
          </Typography>
          <IconButton
            className={classes.openInNewButton}
            color="inherit"
            onClick={() => window.open(`case?id=${selectedCase.caseID}`, '_blank')}
            aria-label="open"
          >
            <OpenInNewIcon />
          </IconButton>
        </Toolbar>
        <DialogContent style={{ backgroundColor: 'rgb(230, 230, 230)', padding: 0 }}>
          <Case
            id={selectedCase.caseID}
            messageContainerHeight={`320px`}
            onUpdate={(updatedCaseData) => {
              updatedSelectedCaseData = updatedCaseData;
            }}
          />
        </DialogContent>
      </Dialog>}
  </React.Fragment>;
};

KanbanBoard.propTypes = {
  cases: PropTypes.array,
};

export default KanbanBoard;
