import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Board, { components as ReactTrelloComponents } from 'react-trello';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import { diffTime, sortBy, request } from '../utils';
import FailedAssetTransactionCase from './FailedAssetTransactionCase';

const useStyles = makeStyles((theme) => ({
  openInNewButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
}));

const convertCaseToCard = (caseItem) => {
  return {
    id: caseItem.id,
    title: `${caseItem.name || ''} (${caseItem.agency})`,
    description: `${caseItem.description || ''}\n\nCreated at ${diffTime(caseItem.createdAt)}\nUpdated at ${diffTime(caseItem.updatedAt)}`,
    label: `${caseItem.assetTransactionIds.length}`,
    metadata: caseItem,
  };
};

let updatedSelectedCaseData;

const FailedAssetTransactionCaseKanbanBoard = ({
  caseStatusOptions,
  cases: inCases,
}) => {
  const classes = useStyles();

  const [cases, setCases] = useState([]);
  const [lanes, setLanes] = useState();
  const [selectedCase, setSelectedCase] = useState();
  const [lastUpdatedAt, setLastUpdatedAt] = useState(Date.now());

  const handleCardClick = (id, caseData, laneId) => {
    setSelectedCase(caseData);
  };

  const handleDialogClose = () => {
    setSelectedCase(null);

    if (updatedSelectedCaseData) {
      const i = cases.findIndex(({ id }) => id === updatedSelectedCaseData.id);
      cases[i] = updatedSelectedCaseData;
      setCases([...cases]);
    }

    updatedSelectedCaseData = null;
  };

  const handleDragEnd = async (cardId, sourceLaneId, targetLaneId) => {
    if (sourceLaneId === targetLaneId) return;

    try {
      const options = {
        api: `/api/failedTransactionCase/${cardId}`,
        method: 'PUT',
        body: JSON.stringify({
          status: targetLaneId,
        }),
        mode: 'cors',
      };

      await request(options);

      const i = cases.findIndex(({ id }) => id === cardId);
      cases[i].status = targetLaneId;
      setCases([...cases]);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (inCases) {
      setCases(inCases);
    }
  }, [inCases]);

  useEffect(() => {
    const lanes = caseStatusOptions.map((item) => {
      const matchedCases = cases.filter(({ status }) => status === item);
      return {
        id: item,
        title: item,
        label: matchedCases.length,
        droppable: true,
        cards: matchedCases.map(convertCaseToCard),
      };
    });

    setLanes([...lanes]);
  }, [caseStatusOptions, cases, lastUpdatedAt]);

  if (!lanes) return null;

  // https://github.com/rcdexta/react-trello/blob/c4a59657f644ea39303e2f9aeac82ee6cc2724a9/src/controllers/Lane.js#L279
  return <React.Fragment>
    <Board
      draggable={true}
      editable={false}
      onCardClick={handleCardClick}
      data={{ lanes }}
      style={{ height: 'calc(100vh)' }}
      laneSortFunction={sortBy('updatedAt', true)}
      handleDragEnd={handleDragEnd}
      components={{
        Card: (args) => {
          return <ReactTrelloComponents.Card
            {...args}
            showDeleteButton={false}
          />;
        },
      }}
    />
    {selectedCase &&
      <Dialog
        open={true}
        onClose={handleDialogClose}
        fullScreen
        disableBackdropClick={true}
        // disableEscapeKeyDown={true}
      >
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleDialogClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6">
            Case
          </Typography>
          <IconButton
            className={classes.openInNewButton}
            color="inherit"
            onClick={() => window.open(`failedAssetTransactionCase?id=${selectedCase.id}`, '_blank')}
            aria-label="open"
          >
            <OpenInNewIcon />
          </IconButton>
        </Toolbar>
        <DialogContent style={{ backgroundColor: 'rgb(230, 230, 230)', padding: 0 }}>
          <FailedAssetTransactionCase
            id={selectedCase.id}
            offsetHeight={`150px`}
            onUpdate={(updatedCaseData) => {
              updatedSelectedCaseData = updatedCaseData;
              setLastUpdatedAt(Date.now());
            }}
          />
        </DialogContent>
      </Dialog>}
  </React.Fragment>;
};

FailedAssetTransactionCaseKanbanBoard.propTypes = {
  caseStatusOptions: PropTypes.array,
  cases: PropTypes.array,
};

export default FailedAssetTransactionCaseKanbanBoard;
