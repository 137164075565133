import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';

import { request } from '../utils';
import TransactionTable from './TransactionTable';
import AssetTransactionTable from './AssetTransactionTable';
import InternalAPIMenuButton from './InternalAPIMenuButton';
import NotificationTable from './NotificationTable';
import TripTable from './TripTable';
import HistoryTable from './HistoryTable';
import RefundTripModal from './RefundTripModal';
import Code from './Code';

const useStyles = makeStyles((theme) => ({
  header: {
    height: 40,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  container: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  imgContainer: {
    paddingRight: theme.spacing(2),
    // height: 600,
  },
  img: {
    width: '100%',
    height: 'auto',
  },
  detailsContainer: {
    maxHeight: 600,
    overflow: 'auto',
  },
  tableContainer: {
    marginBottom: theme.spacing(2),
  },
}));

const tripItemMenu = {
  // details: {
  //   label: 'Details',
  //   status: true,
  // },
  mergedAssetTransactions: {
    label: 'Merged Asset Transactions',
    origin: 'MOBILE',
    required: ['mergedTripId'],
    status: false,
  },
  assetTransactions: {
    label: 'Asset Transactions',
    origin: 'VIDEO',
    status: true,
  },
  transactions: {
    label: 'Transactions',
    status: true,
  },
  notifications: {
    label: 'Notifications',
    status: false,
  },
  history: {
    label: 'History',
    status: false,
  },
};

export default function TripTableDetails({ trip: inTrip, match, onUpdate }) {
  const classes = useStyles();

  const env = window.localStorage.getItem('env');
  const canEdit = env !== 'prd';

  const [data, setData] = useState(null);
  const [originalData, setOriginalData] = useState(null);
  const [menu, setMenu] = useState(tripItemMenu);

  useEffect(() => {
    if (inTrip) {
      setData(inTrip);
    } else
    if (match.params.tripId) {
      (async () => {
        const urlPrefix = `https://s3.amazonaws.com/got-${env}-static/images/routes_ods`;
        const options = {
          api: `/api/trip/${match.params.tripId}`,
          method: 'GET',
          mode: 'cors',
        };
        const { data: tripData } = await request(options);
        const originalTrip = JSON.parse(JSON.stringify(tripData));
        tripData.imgUrl = `${urlPrefix}/${tripData.assetId}__${tripData.entry.tollWay}__${tripData.exit.tollWay}.png`;

        setData(tripData);
        setOriginalData(originalTrip);
      })();
    }
  }, [inTrip, match, env]);

  if (!data) {
    return (<div></div>);
  }

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} sm={8}>
        <Paper className={classes.header}>
          <FormGroup row className="space">
            {
              Object.keys(menu).map((menuKey, index)=>{
                const { origin, label, required, status } = menu[menuKey];
                const unmatchRequiredFields = (required)? !required.every((field)=>data[field]) : false;
                const shouldDisabled = (origin && origin !== data.origin) || unmatchRequiredFields;
                return (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        disabled={shouldDisabled}
                        checked={status}
                        onChange={()=>{
                          menu[menuKey].status = !menu[menuKey].status;
                          setMenu({ ...menu });
                        }}
                        color="primary"
                      />
                    }
                    label={label}
                  />);
              })
            }
          </FormGroup>
        </Paper>
        {menu.mergedAssetTransactions.status &&
            data.origin === 'MOBILE' &&
            <Grid container className={classes.tableContainer} justify="center">
              <TripTable
                title="Asset Trip"
                tripId={data.mergedTripId}
                nested={true}
              />
            </Grid>}
        {menu.assetTransactions.status &&
            data.origin === 'VIDEO' &&
            <Grid container className={classes.tableContainer} justify="center">
              <AssetTransactionTable
                title="Trip Asset Transactions"
                tripId={data.tripId}
                nested={true}
              />
            </Grid>}
        {menu.transactions.status &&
            <Grid container className={classes.tableContainer} justify="center">
              <TransactionTable
                title="Trip Transactions"
                tripId={data.tripId}
                nested={true}
              />
            </Grid>}
        {menu.notifications.status &&
            <Grid container className={classes.tableContainer} justify="center">
              <NotificationTable
                tripId={data.tripId}
                title="Notifications"
                nested={true}
              />
            </Grid>
        }
        {menu.history.status &&
            <Grid container className={classes.tableContainer} justify="center">
              <HistoryTable
                trip={data}
                nested={true}
              />
            </Grid>
        }
      </Grid>
      <Grid item xs={12} sm={4} style={{ paddingLeft: 16 }}>
        <Paper className={classes.header}>
          {
            canEdit &&
              <Button
                className="space"
                variant="outlined"
                size="small"
                disabled={!['PENDING', 'PENDING_DUE'].includes(data.tripStatus)}
                onClick={() => {
                  fixTripPrice(data.tripId);
                  if (onUpdate) onUpdate();
                }}>
                Fix Price
              </Button>
          }
          {
            canEdit && data.vehicle &&
              <Button
                className="space"
                variant="outlined"
                color="primary"
                size="small"
                disabled={!['PENDING', 'PENDING_DUE'].includes(data.tripStatus)}
                onClick={() => {
                  payTrip(data.tripId);
                  if (onUpdate) onUpdate();
                }}>
                Auto Pay
              </Button>
          }
          {
            ['PAID_GPS_MERGED', 'PAID', 'REFUNDED'].includes(data.tripStatus) &&
              <RefundTripModal
                tripId={data.tripId}
                cost={data.cost}
                onUpdate={onUpdate}
              />
          }
          {canEdit &&
              <InternalAPIMenuButton assetId={data.assetId} onUpdate={onUpdate} />}
        </Paper>
        <Paper className={classes.container}>
          <Grid container className={classes.detailsContainer}>
            <Grid item xs={12} className={classes.imgContainer}>
              <img src={data.imgUrl} alt="" className={classes.img}/>
            </Grid>
            <Grid item xs={12} >
              {/* <Code code={originalData || data.rawData || data} /> */}
              <Code code={originalData || data.rawData || data} defaultLanguage="list" />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}

TripTableDetails.propTypes = {
  trip: PropTypes.object,
  match: PropTypes.object,
  onUpdate: PropTypes.func,
};

const payTrip = async (tripId) => {
  if (!window.confirm('Do you want to pay the current trip?')) {
    return;
  }
  const options = {
    api: `/api/trip/${tripId}/pay`,
    method: 'GET',
    mode: 'cors',
  };
  await request(options);
};

const fixTripPrice = async (tripId) => {
  if (!window.confirm('Do you want to fix the price for the current trip?')) {
    return;
  }

  const options = {
    api: `/api/trip/${tripId}/fixPrice`,
    method: 'GET',
    mode: 'cors',
  };
  await request(options);
};
