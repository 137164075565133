import React, { useState, useEffect, Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import AccountTable from '../components/AccountTable';

import {
  request,
} from '../utils';

export default function AccountSearch() {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState();
  const [accounts, setAccounts] = useState([]);

  const queryAccount = async () => {
    if (!value) return;

    setLoading(true);
    try {
      const options = {
        api: '/api/accountsQuery',
        method: 'GET',
        mode: 'cors',
        query: {
          value,
        },
      };
      const res = await request(options);

      setAccounts(res.data);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {}, []);

  return (
    <Fragment>
      <Grid container style={{ padding: 16 }}>
        <Grid item xs={12}>
          <Paper className="panel">
            <TextField
              fullWidth
              id={`searchField`}
              label={`Email / Name`}
              value={value}
              margin="normal"
              onChange={(e)=>{
                console.log(e);
                setValue(e.target.value);
              }}
            />
            <Button
              variant="outlined"
              size="small"
              disabled={loading || !value}
              onClick={queryAccount}
            >
              Query
            </Button>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <AccountTable
            data={accounts}
            nested={true}
          />
        </Grid>
      </Grid>
    </Fragment>);
}
