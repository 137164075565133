import moment from 'moment-timezone';

const TIME_ZONE = 'America/New_York';
const DATE_FORMAT = 'YYYY-MM-DD';
const MONTH_FORMAT = 'YYYY-MM';
export const APP_START_YEAR_MONTH = '2019-02';
export const LAST_MONTH = moment().add(-1, 'months').format('YYYY-MM');

export function getTodayDate() {
  return moment().tz(TIME_ZONE).format('MM/DD');
}

export function getStartEndByPeriodName(inPeriod) {
  const [fromPeriod, fromOffset, toPeriod, toOffset] = inPeriod.split('_');
  if (fromOffset === 'last') {
    return {
      startDate: moment().tz(TIME_ZONE).add(-1, `${fromPeriod}s`).startOf(fromPeriod).format(DATE_FORMAT),
      endDate: moment().tz(TIME_ZONE).add(-1, `${fromPeriod}s`).endOf(fromPeriod).format(DATE_FORMAT),
      prevStartDate: moment().tz(TIME_ZONE).add(-2, `${fromPeriod}s`).startOf(fromPeriod).format(DATE_FORMAT),
      prevEndDate: moment().tz(TIME_ZONE).add(-2, `${fromPeriod}s`).endOf(fromPeriod).format(DATE_FORMAT),
    };
  } else
  if (fromOffset && toPeriod) {
    const periodLength = parseInt(toOffset, 10) - parseInt(fromOffset, 10) + 1;

    return {
      startDate: moment().tz(TIME_ZONE).add(parseInt(fromOffset), `${fromPeriod}s`).format(DATE_FORMAT),
      endDate: moment().tz(TIME_ZONE).add(parseInt(toOffset), `${toPeriod}s`).format(DATE_FORMAT),
      prevStartDate: moment().tz(TIME_ZONE).add(parseInt(fromOffset) - periodLength, `${fromPeriod}s`).format(DATE_FORMAT),
      prevEndDate: moment().tz(TIME_ZONE).add(parseInt(toOffset) - periodLength, `${toPeriod}s`).format(DATE_FORMAT),
    };
  } else {
    const start = moment().tz(TIME_ZONE).startOf(inPeriod);
    const end = moment().tz(TIME_ZONE).endOf(inPeriod);

    return {
      startDate: start.format(DATE_FORMAT),
      endDate: end.format(DATE_FORMAT),
      prevStartDate: moment().tz(TIME_ZONE).add(-1, `${inPeriod}s`).startOf(inPeriod).format(DATE_FORMAT),
      prevEndDate: moment().tz(TIME_ZONE).add(-1, `${inPeriod}s`).endOf(inPeriod).format(DATE_FORMAT),
    };
  }
}

export function getMonths(from = APP_START_YEAR_MONTH, to) {
  const months = [moment(to).tz(TIME_ZONE).format(MONTH_FORMAT)];

  while (months[0] > from) {
    const prevMonth = moment(months[0]).tz(TIME_ZONE).add(-1, 'month').format(MONTH_FORMAT);
    months.unshift(prevMonth);
  }
  return months;
}
