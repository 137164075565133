import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Menu from '@material-ui/icons/Menu';
import Remove from '@material-ui/icons/Remove';
import queryString from 'query-string';
import { createBrowserHistory } from 'history';

const SidebarEnv = ({ list = [], iconOnly = true }) => {
  const history = createBrowserHistory();

  const env = window.localStorage.getItem('env');
  const [open, setOpen] = useState(false);

  const onSelectEnv = (env, forceDashboard = false) => {
    const path = (forceDashboard) ? '/dashboard' : window.location.pathname;
    window.open(`${path}?env=${env}`, '_self');
  };

  if (list.length === 0) return null;

  console.log('list', list);
  let currrentEnv = list.find((item) => item.key === env);
  if (!currrentEnv) {
    currrentEnv = list[0];

    const newEnv = currrentEnv.key;
    window.localStorage.setItem('env', newEnv);

    const queryParams = queryString.parse(history.location.search);
    queryParams.env = newEnv;

    history.push({
      search: queryString.stringify(queryParams),
    });
  }

  const displayName = iconOnly ? currrentEnv.key.toUpperCase() : currrentEnv.label;

  if (list.length <= 1) return null;

  return (
    <Fragment>
      <ListItem
        button
        dense
        onClick={() => setOpen(!open)}
      >
        {!iconOnly && <ListItemIcon><Menu /></ListItemIcon>}
        <ListItemText primary={displayName} />
      </ListItem>
      {
        open &&
        list.map((item)=>(
          <ListItem
            key={item.key}
            button
            dense
            disabled={item.key === env}
            onClick={(event)=>{
              setOpen(false);
              onSelectEnv(item.key);
            }}>
            {!iconOnly && <ListItemIcon><Remove /></ListItemIcon>}
            <ListItemText primary={iconOnly ? item.key.toUpperCase() : item.label} />
          </ListItem>
        ))
      }
    </Fragment>
  );
};

SidebarEnv.propTypes = {
  list: PropTypes.array,
  iconOnly: PropTypes.bool,
};

export default SidebarEnv;
