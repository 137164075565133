import React, { useState, useEffect, createRef } from 'react';

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import GetAppIcon from '@material-ui/icons/GetApp';
import PrintIcon from '@material-ui/icons/Print';
// import ReactToPrint from 'react-to-print';
import Tooltip from '@material-ui/core/Tooltip';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import FormControl from '@material-ui/core/FormControl';
import stats from 'stats-lite';
import { toastr } from 'react-redux-toastr';

import { useScreenshot, createFileName } from 'use-react-screenshot';

import moment from 'moment';
import { UsaStates } from 'usa-states';

import BarCard from '../dashboard/prd/components/BarCard';
import BarLineCard from '../dashboard/prd/components/BarLineCard';
import SummaryCard from '../dashboard/prd/components/SummaryCard';
import {
  request,
  formatNumber,
  formatDollars,
  // sortBy,
  // tableCellStatusRenderer,
} from '../utils';

import { getMonths, APP_START_YEAR_MONTH, LAST_MONTH } from '../dashboard/prd/helpers';

import hexColors from '../dashboard/hexColors';

import './reportDashboard.css';

const formatMonthName = (month) => {
  return moment(month).format('MMMM YYYY');
};

const convertObjToArray = (inObj) => {
  return Object.keys(inObj).map((key) => inObj[key]);
};

const margin = {
  top: 50,
  right: 60,
  bottom: 100,
  left: 60,
};

const allUsaStates = new UsaStates().states.map(({ name }) => name);

const monthlyRevenueDataKeys = ['Pass-thru toll revenue collected', 'Service fee revenue'];
const defaultMonthlyTripDataKeys = [...allUsaStates.reverse()];

const legendGroupSize = 5;
const getGroupDataKeys = (inDataKeys) => {
  return inDataKeys.reduce((groups, key) => {
    const lastGroup = groups[groups.length - 1];
    if (lastGroup.length < legendGroupSize) {
      lastGroup.push(key);
    } else {
      groups.push([key]);
    }
    return groups;
  }, [[]]);
};

const periodOptions = [{
  label: 'From Launch Date',
  value: 'launch',
}, {
  label: 'Last 24 months',
  value: 'last24',
}, {
  label: 'Last 12 months',
  value: 'last12',
}, {
  label: 'Last 6 months',
  value: 'last6',
}, {
  label: 'Last 3 months',
  value: 'last3',
}];

const monthDataCache = {};
const getMonthData = async (month) => {
  if (monthDataCache[month]) {
    return monthDataCache[month];
  }
  const { data } = await request({
    api: '/api/monthlySummary',
    query: {
      month,
    },
    method: 'GET',
    mode: 'cors',
  });

  monthDataCache[month] = data;

  return data;
};

const ReportDashboard = () => {
  const ref = createRef(null);
  const [image, takeScreenshot] = useScreenshot({
    type: 'image/jpeg',
    quality: 1.0,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [months, setMonths] = useState([]);
  const [period, setPeriod] = useState('last3');
  const [monthlyAccountData, setMonthlyAccountData] = useState([]);
  const [monthlyRevenueData, setMonthlyRevenueData] = useState([]);
  const [monthlyTripDataKeys, setMonthlyTripDataKeys] = useState();
  const [monthlyTripData, setMonthlyTripData] = useState([]);
  const [monthlyTripDataMappings, setMonthlyTripDataMappings] = useState([]);
  const [monthlyUnpaidTollData, setMonthlyUnpaidTollData] = useState([]);
  const [monthlyUnpaidTollDataMappings, setMonthlyUnpaidTollDataMappings] = useState([]);
  const [summaryData, setSummaryData] = useState({});
  const [startMonth, setStartMonth] = useState();
  const [endMonth, setEndMonth] = useState();

  const getImage = () => takeScreenshot(ref.current).then(downloadImage);

  const downloadImage = (image, { name, extension = 'jpg' } = {}) => {
    const filename = name || `GoToll Summary Dashboard ${moment(months[0]).format('MMMM YYYY')} - ${moment(months[months.length - 1]).format('MMMM YYYY')}`;
    const a = document.createElement('a');
    a.href = image;
    a.download = createFileName(extension, filename);
    a.click();
  };

  const getMonthDataSet = (dataKeys = []) => {
    return months.reduce((obj, month) => {
      obj[month] = {
        x: formatMonthName(month),
      };
      dataKeys.forEach((key) => {
        obj[month][key] = 0;
      });
      return obj;
    }, {});
  };

  const download = async () => {
    setIsLoading(true);

    const startMonth = months[0];
    const endMonth = months[months.length - 1];

    const res = await request({
      api: '/api/downloadReportZip',
      query: {
        startMonth,
        endMonth,
      },
      method: 'GET',
      mode: 'cors',
    });

    // console.log(res.headers); // returns a Headers{} object

    const blob = await res.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `GoToll__Reports__${startMonth}__${endMonth}.zip`;
    document.body.appendChild(a);
    a.click();
    a.remove();

    setIsLoading(false);
  };

  useEffect(() => {
    switch (period) {
    case 'launch':
      setStartMonth(APP_START_YEAR_MONTH);
      setEndMonth(LAST_MONTH);
      break;
    case 'last24':
      setStartMonth(moment().add(-24, 'months').format('YYYY-MM'));
      setEndMonth(LAST_MONTH);
      break;
    case 'last12':
      setStartMonth(moment().add(-12, 'months').format('YYYY-MM'));
      setEndMonth(LAST_MONTH);
      break;
    case 'last6':
      setStartMonth(moment().add(-6, 'months').format('YYYY-MM'));
      setEndMonth(LAST_MONTH);
      break;
    case 'last3':
      setStartMonth(moment().add(-3, 'months').format('YYYY-MM'));
      setEndMonth(LAST_MONTH);
      break;
    default:
      break;
    }

    setMonths(months);
  }, [period]);

  useEffect(() => {
    if (!startMonth || !endMonth) return;
    setMonths(getMonths(startMonth, endMonth));
  }, [startMonth, endMonth]);

  useEffect(() => {
    if (months.length === 0) return;

    (async () => {
      setIsLoading(true);

      setMonthlyTripDataKeys();

      toastr.info('Fetching Data...', `${months[0]} ~ ${months[months.length - 1]}`, { timeOut: 3000 });

      const data = await Promise.all(months.map(getMonthData));
      // const { data } = await request({
      //   api: '/api/monthlySummary',
      //   query: {
      //     startMonth: months[0],
      //     endMonth: months[months.length - 1],
      //   },
      //   method: 'GET',
      //   mode: 'cors',
      // });

      const summaryData = {
        accounts: 0,
        accountsDeleted: 0,
        trips: 0,
        paidTrips: 0,
        vehicles: 0,
        vehiclesDeleted: 0,
        vehiclesWithTrips: 0,
        vehiclesWithTripsEachMonth: 0,
        vehiclesTripsMedian: 0,
        vehiclesTripsAverage: 0,
        vehiclesTripsStandardDeviation: 0,
        vehiclesActiveRental: 0,
        vehiclesActiveNonRental: 0,
        passThruRevenue$: 0,
        serviceFeeRevenue$: 0,
        unpaidTollAmount$: 0,
        failedMatchedTollAmount$: 0,
        assetTransactions: 0,
        assetTransactionsFailed: 0,
        discountAmount$: 0,
        refundAmount$: 0,
      };

      const uniqueVehicleWithTrips = [];
      const uniqueVehicleWithTripsEachMonthMappings = {};
      const vehiclesTripsArray = [];

      data.forEach(({ accounts, vehicles, trips, assetTransactions }) => {
        summaryData.accounts += accounts.total;
        summaryData.accountsDeleted += accounts.deactivated;
        summaryData.trips += trips.total;
        summaryData.paidTrips += trips.paid;
        summaryData.vehicles += vehicles.added;
        summaryData.vehiclesDeleted += vehicles.deleted;
        summaryData.vehiclesActiveRental += vehicles.activeRental;
        summaryData.vehiclesActiveNonRental += vehicles.activeNonRental;
        summaryData.passThruRevenue$ += trips.passThruRevenue$;
        summaryData.serviceFeeRevenue$ += trips.serviceFeeRevenue$;
        summaryData.unpaidTollAmount$ += trips.unpaidTollAmount$;
        summaryData.discountAmount$ += trips.discountAmount$;
        summaryData.refundAmount$ += trips.refundAmount$;

        summaryData.failedMatchedTollAmount$ += assetTransactions.failedMatchedTollAmount$;

        summaryData.assetTransactions += assetTransactions.total;
        summaryData.assetTransactionsFailed += assetTransactions.failed;

        Object.keys(trips.lpnStateMappings || {}).map((lpnState) => {
          if (uniqueVehicleWithTrips.indexOf(lpnState) === -1) {
            uniqueVehicleWithTrips.push(lpnState);
          }
          uniqueVehicleWithTripsEachMonthMappings[lpnState] = uniqueVehicleWithTripsEachMonthMappings[lpnState] || 0;
          uniqueVehicleWithTripsEachMonthMappings[lpnState]++;

          vehiclesTripsArray.push(trips.lpnStateMappings[lpnState]);
        });
      });

      summaryData.vehiclesWithTrips = uniqueVehicleWithTrips.length;
      summaryData.vehiclesTripsAverage = stats.mean(vehiclesTripsArray).toFixed(1);
      // summaryData.vehiclesTripsAverage = stats.percentile(vehiclesTripsArray, 0.85);
      summaryData.vehiclesTripsMedian = stats.median(vehiclesTripsArray).toFixed(1);
      summaryData.vehiclesTripsStandardDeviation = stats.stdev(vehiclesTripsArray);

      Object.keys(uniqueVehicleWithTripsEachMonthMappings).map((key) => {
        if (uniqueVehicleWithTripsEachMonthMappings[key] === data.length) {
          summaryData.vehiclesWithTripsEachMonth++;
        }
      });

      setSummaryData(summaryData);

      setMonthlyAccountData(data.reduce((obj, monthData) => {
        const { month, accounts, trips } = monthData;
        obj[month] = {
          x: formatMonthName(month),
          v1: accounts.new,
          lv1: trips.uniqueTravellers,
        };
        return obj;
      }, getMonthDataSet(['v1', 'lv1'])));

      setMonthlyRevenueData(data.reduce((obj, monthData) => {
        const { month, trips } = monthData;
        obj[month] = {
          'x': formatMonthName(month),
          'Pass-thru toll revenue collected': trips.passThruRevenue$,
          'Service fee revenue': trips.serviceFeeRevenue$,
        };
        return obj;
      }, getMonthDataSet(monthlyRevenueDataKeys)));

      // trips
      const monthlyTripDataMappings = defaultMonthlyTripDataKeys.reduce((mappings, key) => {
        mappings[key] = 0;
        return mappings;
      }, {});

      setMonthlyTripData(data.reduce((obj, monthData) => {
        const { month, trips } = monthData;
        obj[month] = {
          'x': formatMonthName(month),
        };
        defaultMonthlyTripDataKeys.forEach((key) => {
          obj[month][key] = trips.states[key] ? trips.states[key].total : 0;

          if (key === 'Unknown' && trips.states[key]) {
            console.log(`${month} has unknown assets`);
          }

          monthlyTripDataMappings[key] += obj[month][key];
        });
        return obj;
      }, getMonthDataSet(defaultMonthlyTripDataKeys)));

      setMonthlyTripDataMappings(monthlyTripDataMappings);

      // sort the data keys
      const monthlyTripDataKeys = Object.keys(monthlyTripDataMappings)
        .sort((a, b) => {
          return monthlyTripDataMappings[a] < monthlyTripDataMappings[b] ? 1 : -1;
        })
        .filter((key) => {
          return monthlyTripDataMappings[key] > 0;
        });
      setMonthlyTripDataKeys(monthlyTripDataKeys);


      // unpaid tolls
      const monthlyUnpaidTollDataMappings = defaultMonthlyTripDataKeys.reduce((mappings, key) => {
        mappings[key] = 0;
        return mappings;
      }, {});

      setMonthlyUnpaidTollData(data.reduce((obj, monthData) => {
        const { month, trips } = monthData;
        obj[month] = {
          'x': formatMonthName(month),
        };
        defaultMonthlyTripDataKeys.forEach((key) => {
          obj[month][key] = trips.states[key] ? trips.states[key].unpaidTollAmount$ : 0;

          monthlyUnpaidTollDataMappings[key] += obj[month][key];
        });
        return obj;
      }, getMonthDataSet(defaultMonthlyTripDataKeys)));

      setMonthlyUnpaidTollDataMappings(monthlyUnpaidTollDataMappings);

      setIsLoading(false);
    })();
  }, [months]);

  // console.log('summaryData', summaryData);

  return (
    <React.Fragment>
      <Container maxWidth={false} style={{ padding: 16 }} ref={ref}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper style={{ padding: 32, boxShadow: 'none' }}>
              <Grid container spacing={2} justifyContent="center" alignItems="center">
                <div style={{ flex: 1, paddingLeft: 8 }}>
                  <Typography variant="h4" component="h4" color="textPrimary">
                    GoToll Summary Dashboard
                  </Typography>
                  {/* <Typography variant="h5" component="h5" color="textSecondary">
                    {moment(months[0]).format('MMMM YYYY')} - {moment(months[months.length - 1]).format('MMMM YYYY')}
                  </Typography> */}
                </div>
                <div>
                  <FormControl style={{ marginTop: 12, marginRight: 12 }}>
                    <ButtonGroup color="primary" aria-label="outlined primary button group">
                      {periodOptions.map((item, index)=>(
                        <Button
                          key={index}
                          disabled={isLoading}
                          // variant={period === item.value ? 'contained':'outlined'}
                          onClick={() => setPeriod(item.value)}
                        >
                          {item.label}
                        </Button>
                      ))}
                    </ButtonGroup>
                  </FormControl>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <FormControl>
                      <DatePicker
                        minDate={APP_START_YEAR_MONTH}
                        maxDate={endMonth}
                        monthsPerRow={4}
                        disableFuture={true}
                        inputVariant="outlined"
                        openTo="month"
                        views={['year', 'month']}
                        label="Start Month"
                        disabled={isLoading}
                        // helperText="Start from year selection"
                        value={moment(startMonth).toISOString()}
                        onChange={(s) => {
                          setStartMonth(moment(s).format('YYYY-MM'));
                        }}
                        style={{ marginRight: 8 }}
                      />
                    </FormControl>
                    <FormControl>
                      <DatePicker
                        minDate={startMonth || APP_START_YEAR_MONTH}
                        disableFuture={true}
                        monthsPerRow={4}
                        inputVariant="outlined"
                        openTo="month"
                        views={['year', 'month']}
                        label="End Month"
                        disabled={isLoading}
                        // helperText="Start from year selection"
                        value={moment(endMonth).toISOString()}
                        onChange={(s)=>{
                          setEndMonth(moment(s).format('YYYY-MM'));
                        }}
                      />
                    </FormControl>
                  </MuiPickersUtilsProvider>

                  <Tooltip title="Print this report page">
                    <Button
                      onClick={getImage}
                      disabled={isLoading}
                    >
                      <PrintIcon />
                    </Button>
                  </Tooltip>
                  {/* <ReactToPrint
                    trigger={() => {
                      return (
                        <Tooltip title="Print this report page">
                          <Button
                            disabled={isLoading}
                          >
                            <PrintIcon />
                          </Button>
                        </Tooltip>);
                    }}
                    content={() => componentRef}
                  /> */}
                  <Tooltip title="Download Daily Tolls by Vendor Accounts">
                    <Button
                      onClick={() => download()}
                      disabled={isLoading}
                    >
                      <GetAppIcon />
                    </Button>
                  </Tooltip>
                </div>
              </Grid>
            </Paper>

          </Grid>
          <Grid item xs={12} md={2}>
            <SummaryCard
              title="Accounts Registered"
              value={summaryData.accounts}
              hideDesc={true}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <SummaryCard
              title="Accounts Deleted"
              value={summaryData.accountsDeleted}
              hideDesc={true}
              color="red"
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <SummaryCard
              title="Trips"
              value={summaryData.trips}
              hideDesc={true}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <SummaryCard
              title="Trips CVR"
              value={(summaryData.paidTrips / summaryData.trips * 100).toFixed(2) + '%'}
              hideDesc={true}
              description="Paid Trips / Total Trips"
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <SummaryCard
              title="Asset Transactions"
              value={summaryData.assetTransactions}
              hideDesc={true}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <SummaryCard
              title="Asset Transactions CVR"
              value={((summaryData.assetTransactions - summaryData.assetTransactionsFailed) / summaryData.assetTransactions * 100).toFixed(2) + '%'}
              hideDesc={true}
              description="Converted Asset TXs / Total Asset TXs"
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <SummaryCard
              title="Vehicles Added"
              value={summaryData.vehicles}
              hideDesc={true}
            />
          </Grid>
          {/* <Grid item xs={12} md={2}>
            <SummaryCard
              title="Vehicles Deleted"
              value={summaryData.vehiclesDeleted}
              hideDesc={true}
              color="red"
            />
          </Grid> */}
          <Grid item xs={12} md={2}>
            <SummaryCard
              title="Active Vehicles"
              value={summaryData.vehiclesWithTrips}
              hideDesc={true}
              description="Vehicles with at least 1 trip"
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <SummaryCard
              title="Regular Vehicles"
              value={summaryData.vehiclesWithTripsEachMonth}
              hideDesc={true}
              description="Vehicles with at least 1 trip each month"
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <SummaryCard
              title="Vehicles-Trips Median"
              value={summaryData.vehiclesTripsMedian}
              hideDesc={true}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <SummaryCard
              title="Vehicles-Trips Average"
              value={summaryData.vehiclesTripsAverage}
              hideDesc={true}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <SummaryCard
              title="Vehicles-Trips SDV"
              value={summaryData.vehiclesTripsStandardDeviation}
              hideDesc={true}
              description={'Standard Deviation'}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <SummaryCard
              title="Pass-Thru Revenue"
              value={summaryData.passThruRevenue$}
              unit="$"
              hideDesc={true}
              color="green"
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <SummaryCard
              title="Service fee revenue"
              value={summaryData.serviceFeeRevenue$}
              unit="$"
              hideDesc={true}
              color="green"
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <SummaryCard
              title="Unpaid Tolls (Trips)"
              value={summaryData.unpaidTollAmount$}
              unit="$"
              hideDesc={true}
              color="red"
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <SummaryCard
              title="Failed matched tolls"
              value={summaryData.failedMatchedTollAmount$}
              unit="$"
              hideDesc={true}
              color="red"
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <SummaryCard
              title="Discount Amount"
              value={summaryData.discountAmount$}
              unit="$"
              hideDesc={true}
              color="#00689c"
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <SummaryCard
              title="Refund Amount"
              value={summaryData.refundAmount$}
              unit="$"
              hideDesc={true}
              color="#00689c"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <BarLineCard
              title="New accounts (bar) & unique travellers (line) each month"
              data={convertObjToArray(monthlyAccountData)}
              barName="Accounts Created"
              lineName="Unique Travellers"
              margin={margin}
            />
          </Grid>

          <div className="page-break" />

          <Grid item xs={12} md={6}>
            <BarCard
              title="Revenue Trend"
              data={convertObjToArray(monthlyRevenueData)}
              indexBy='x'
              dataKeys={monthlyRevenueDataKeys}
              mode='stacked'
              height={480}
              colors={hexColors.custom}
              margin={margin}
              legends={[
                {
                  dataFrom: 'keys',
                  anchor: 'top-left',
                  direction: 'column',
                  justify: false,
                  translateX: 30,
                  translateY: 10,
                  itemsSpacing: 2,
                  itemWidth: 100,
                  itemHeight: 20,
                  itemDirection: 'left-to-right',
                  itemOpacity: 1,
                  symbolSize: 20,
                  effects: [
                    {
                      on: 'hover',
                      style: {
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]}

            />
          </Grid>

          <div className="page-break" />

          <Grid item xs={12} md={6}>
            {monthlyTripDataKeys &&
            <BarCard
              title="Monthly trips by state of asset"
              data={convertObjToArray(monthlyTripData)}
              indexBy='x'
              dataKeys={monthlyTripDataKeys}
              mode='stacked'
              height={480}
              colors={hexColors.custom}
              margin={margin}
              legends={
                getGroupDataKeys(monthlyTripDataKeys).map((group, groupIndex)=>{
                  return {
                    dataFrom: 'x',
                    // not working for canvas
                    data: group
                      .map((id, index) => ({
                        color: hexColors.custom[groupIndex * legendGroupSize+ index],
                        id: groupIndex * legendGroupSize + index,
                        label: `${id} (${formatNumber(monthlyTripDataMappings[id])})`,
                      })),
                    anchor: 'center',
                    direction: 'row',
                    justify: false,
                    translateX: 0,
                    translateY: 0 + groupIndex * 20,
                    itemsSpacing: 0,
                    itemWidth: 150,
                    itemHeight: 20,
                    itemDirection: 'left-to-right',
                    itemOpacity: 1,
                    symbolSize: 12,
                    effects: [
                      {
                        on: 'hover',
                        style: {
                          itemOpacity: 1,
                        },
                      },
                    ],
                  };
                })}
            />}
          </Grid>

          <div className="page-break" />

          <Grid item xs={12} md={6}>
            {monthlyTripDataKeys &&
            <BarCard
              title="Unpaid Tolls by month & state of asset"
              data={convertObjToArray(monthlyUnpaidTollData)}
              indexBy='x'
              dataKeys={monthlyTripDataKeys}
              mode='stacked'
              height={480}
              colors={hexColors.custom}
              margin={margin}
              legends={
                getGroupDataKeys(monthlyTripDataKeys).map((group, groupIndex)=>{
                  return {
                    data: group
                      .map((id, index) => ({
                        color: hexColors.custom[groupIndex * legendGroupSize+ index],
                        id: groupIndex * legendGroupSize + index,
                        label: `${id} (${formatDollars(monthlyUnpaidTollDataMappings[id])})`,
                      })),
                    anchor: 'center',
                    direction: 'row',
                    justify: false,
                    translateX: 0,
                    translateY: 0 + groupIndex * 20,
                    itemsSpacing: 0,
                    itemWidth: 150,
                    itemHeight: 20,
                    itemDirection: 'left-to-right',
                    itemOpacity: 1,
                    symbolSize: 12,
                    effects: [
                      {
                        on: 'hover',
                        style: {
                          itemOpacity: 1,
                        },
                      },
                    ],
                  };
                })}
            />}
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2" color="textSecondary">
              1. Pass-thru revenue - cumulative toll revenue collected to pay toll portion oftransaction
            </Typography>
            <Typography variant="body2" color="textSecondary">
              2. Service fee revenue - cumulative administrative fee revenue of $0.85 / trip
            </Typography>
            <Typography variant="body2" color="textSecondary">
              3. Active accounts - an account that takes a trip in respective month
            </Typography>
            <Typography variant="body2" color="textSecondary">
              4. A state is unknown if it is not in the GoToll road model
            </Typography>
          </Grid>

        </Grid>
      </Container>
      <img src={image} style={{ display: 'hidden' }} />
    </React.Fragment>
  );
};

ReportDashboard.propTypes = {};

export default ReportDashboard;
