import React, { Component, Fragment } from 'react';
import { LazyLog, ScrollFollow } from 'react-lazylog';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';

import './Log.css';

const types = ['access', 'activity', 'system', 'ui'];

const TIME_FORMAT = 'YYYY-MM-DD';

const dates = [];
for (let i = 0; i > -7; i--) {
  dates.push(moment().add(i, 'days').utc().format(TIME_FORMAT));
}

export default class Log extends Component {
  state = {
    url: null,
    seletedTimeMenu: 0,
    type: types[0],
    // date: timeMenu[0].value,
    date: dates[0],
  }

  componentDidMount() {
    this.getLog();
  }

  getLog = () => {
    this.setState({ url: null }, () => {
      const queries = [
        `access_token=${window.localStorage.getItem('accessToken')}`,
        `type=${this.state.type}`,
        `date=${this.state.date}`,
      ];
      this.setState({
        url: `/api/logs?${queries.join('&')}`,
      });
    });
  }

  render() {
    return (
      <Fragment>
        <Paper>
          <Grid item xs={12} align="left" className="grid-container">
            <FormControl fullWidth>
              <InputLabel htmlFor="age-native-simple">UTC Date</InputLabel>
              <Select
                native
                value={this.state.date}
                onChange={(event)=>{
                  this.setState({
                    date: event.target.value,
                  }, this.getLog);
                }}
                disabled={this.state.loading}
                inputProps={{
                  name: 'UTC dates',
                  id: 'utc-dates',
                }}>
                {dates.map((listItem)=>{
                  return (<option key={listItem} value={listItem}>{listItem}</option>);
                })}
              </Select>
            </FormControl>
            {/* <TextField
                id="datetime-local-from"
                label={`UTC Date`}
                type="date"
                value={this.state.date}
                onChange={(event)=>{
                  this.setState({
                    date: event.target.value
                  });
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              /> */}
            {/* {
                timeMenu.map((item, index)=>(
                  <Button
                    key={index}
                    className="select-align-container"
                    variant={this.state.seletedTimeMenu === index ? 'outlined' : 'text'}
                    onClick={()=>{
                      if (this.state.seletedTimeMenu === index) return;
                      this.setState({
                        seletedTimeMenu: index,
                        date: item.value,
                      }, this.load)
                    }}>
                    {item.label}
                  </Button>
                ))
              } */}
          </Grid>
          <Grid item xs={12} align="left" className="grid-container">
            <FormControl fullWidth>
              <InputLabel htmlFor="age-native-simple">Log Type</InputLabel>
              <Select
                native
                value={this.state.type}
                onChange={(event)=>{
                  this.setState({
                    type: event.target.value,
                  }, this.getLog);
                }}
                inputProps={{
                  name: 'log type',
                  id: 'log-type',
                }}>
                {types.map((listItem)=>{
                  return (<option key={listItem} value={listItem}>{listItem}</option>);
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} align="left" className="grid-container">
            <Button variant="outlined" color="primary" onClick={this.getLog} disabled={this.state.loading}>
              {this.state.loading && <CircularProgress size={15}/>}
              {!this.state.loading && 'Reload'}
            </Button>
          </Grid>
        </Paper>
        <div className="log-container">
          {this.state.url &&
          <ScrollFollow
            startFollowing={true}
            render={({ follow, onScroll }) => (
              <LazyLog
                url={this.state.url}
                stream={true}
                enableSearch={true}
                extraLines={5}
                selectableLines={true}
                follow={follow}
                onScroll={onScroll} />
            )}
          />}
        </div>
      </Fragment>);
  }
}
