import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

import Table from './Table';
import {
  request,
  sortBy,
  tableCellTimeRenderer,
} from '../utils';

export default function AccountUpdateTable({ data: inData, accountId }) {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (inData) {
      setData(inData.sort(sortBy('eventTime', true)));
    }
  }, [inData]);

  useEffect(() => {
    if (!accountId) return;
    (async () => {
      const options = {
        api: `/api/accounts/${accountId}/updates`,
        method: 'GET',
        mode: 'cors',
      };
      const { data } = await request(options);
      setData(data.sort(sortBy('eventTime', true)));
    })();
  }, [accountId]);

  const headers = [{
    name: 'eventId',
    label: 'Event ID',
    options: {
      filter: false,
    },
  }, {
    name: 'accountId',
    label: 'Account ID',
    options: {
      filter: false,
    },
  }, {
    name: 'eventName',
    label: 'Event Name',
  }, {
    name: 'status',
    label: 'Status',
  }, {
    name: 'eventTime',
    label: 'Event Date',
    options: {
      filter: false,
      customBodyRender: tableCellTimeRenderer(true),
    },
  }, {
    name: 'firstBlockedNotificationSentAt',
    label: '1st Notification',
    options: {
      filter: false,
      customBodyRender: tableCellTimeRenderer(true),
    },
  }, {
    name: 'secondBlockedNotificationSentAt',
    label: '2nd Notification',
    options: {
      filter: false,
      customBodyRender: tableCellTimeRenderer(true),
    },
  }].map((item) => {
    if (accountId) {
      if (['accountId'].includes(item.name)) {
        item.options = {
          display: false,
          filter: false,
        };
      }
    }
    return item;
  });

  const onItemClick = (rowData, rowMeta) => {
    const item = data[rowMeta.dataIndex];
    console.log(item);
  };

  const options = {
    filterType: 'checkbox',
    onRowClick: onItemClick,
    rowsPerPageOptions: [10, 50, 100],
    rowsPerPage: 10,
  };

  return (
    <Grid item xs={12}>
      <Table
        title={'Account Update Records'}
        data={data}
        columns={headers}
        options={options}
        nested={true}
      />
    </Grid>);
}

AccountUpdateTable.propTypes = {
  data: PropTypes.array,
  accountId: PropTypes.string,
};
