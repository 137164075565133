import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';

import Table from './Table';
import {
  request,
  sortBy,
  tableCellTimeRenderer,
} from '../utils';

export default class VehicleExternalVdotTable extends Component {
  state = {
    data: [],
  }

  componentDidMount() {
    this.load();
  }

  load = () => {
    if (this.props.data) return;
    if (!this.props.vehicles) return;

    return Promise.resolve()
      .then(() => {
        const getDataPromises = this.props.vehicles.map((vehicle) => {
          const options = {
            api: `/api/vehicle/${vehicle.vehicleId}/externalVdot`,
            method: 'GET',
            mode: 'cors',
          };
          return request(options);
        });

        return Promise.all(getDataPromises);
      })
      .then((result) => {
        this.setState({
          data: result.reduce((data, res) => {
            return res.data ? [...data, res.data] : data;
          }, []),
        });
      })
      .catch(console.log);
  }

  render() {
    const headers = [{
      name: 'vendorAccount',
      label: 'Vendor Account',
      options: {
        filter: true,
      },
    }, {
      name: 'vdotId',
      label: 'VDOT ID',
      options: {
        filter: false,
      },
    }, {
      name: 'vehicleId',
      label: 'Vehicle ID',
      options: {
        filter: false,
      },
    }, {
      name: 'createdDate',
      label: 'Created Date',
      options: {
        filter: false,
        customBodyRender: tableCellTimeRenderer(true),
      },
    }, {
      name: 'endDate',
      label: 'End Date',
      options: {
        filter: false,
        customBodyRender: tableCellTimeRenderer(true),
      },
    }, {
      name: 'itemUpdatedAt',
      label: 'UpdatedAt',
      options: {
        filter: false,
        customBodyRender: tableCellTimeRenderer(true),
      },
    }];

    const onItemClick = (rowData, rowMeta) => {
      const item = (this.state.data || this.props.data)[rowMeta.dataIndex];
      console.log(item);
    };

    const options = {
      filterType: 'checkbox',
      onRowClick: onItemClick,
      rowsPerPageOptions: [10, 50, 100],
      rowsPerPage: 10,
    };

    const data = (this.state.data || this.props.data || [])
      .sort(sortBy('createdDate', true));

    return (
      <Grid item xs={12}>
        <Table
          title={this.props.title || 'VDOT Vehicle Records'}
          data={data}
          columns={headers}
          options={options}
          nested={this.props.nested}
        />
      </Grid>);
  }
}
