import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Table from './Table';
import {
  sortBy,
  request,
  tableCellAccountIdRenderer,
  tableCellTimeRenderer,
  tableCellStatusRenderer,
  tableCellCurrencyRenderer,
  tableCellTripIdRenderer,
} from '../utils';

export default class TransactionTable extends Component {
  state = {
    data: [],
  }

  componentDidMount() {
    this.load();
  }

  load = () => {
    if (this.props.data) return;

    if (this.props.tripId) {
      return Promise.resolve()
        .then(() => {
          const options = {
            api: `/api/trip/${this.props.tripId}/transactions`,
            method: 'GET',
            mode: 'cors',
          };
          return request(options);
        })
        .then((res) => {
          this.setState({
            data: res.data,
          });
        })
        .catch(console.log);
    } else
    if (this.props.accountId) {
      return Promise.resolve()
        .then(() => {
          const options = {
            api: `/api/account/${this.props.accountId}/transactions`,
            method: 'GET',
            mode: 'cors',
          };
          return request(options);
        })
        .then((res) => {
          this.setState({
            data: res.data,
          });
        })
        .catch(console.log);
    }
  }

  render() {
    const headers = [{
      name: 'transactionId',
      label: 'ID',
      options: {
        filter: false,
      },
    }, {
      name: 'tripIds',
      label: 'Trip IDs',
      options: {
        filter: false,
        customBodyRender: (value = []) => {
          return (
            <Typography variant="inherit">
              {
                value.map((item, index)=>(
                  <p key={index}>{tableCellTripIdRenderer(null, '_blank')(item)}</p>
                ))
              }
            </Typography>
          );
        },
      },
    }, {
      name: 'accountId',
      label: 'Account ID',
      options: {
        filter: false,
        customBodyRender: tableCellAccountIdRenderer(),
      },
    }, {
      name: 'type',
      label: 'Type',
      options: {
        customBodyRender: tableCellStatusRenderer({
          TRANSACTION: 1,
          REFUND: 2,
        }),
      },
    }, {
      name: 'subtotal',
      label: 'Subtotal',
      options: {
        filter: false,
        customBodyRender: tableCellCurrencyRenderer(),
      },
    }, {
      name: 'discount',
      label: 'Discount',
      options: {
        filter: false,
        customBodyRender: tableCellCurrencyRenderer(),
      },
    }, {
      name: 'amount',
      label: 'Amount',
      options: {
        filter: false,
        customBodyRender: tableCellCurrencyRenderer(),
      },
    }, {
      name: 'chaseCustomerRefNum',
      label: 'Chase Ref Num',
      options: {
        filter: false,
      },
    }, {
      name: 'chaseProcStatus',
      label: 'Status',
      options: {
        customBodyRender: tableCellStatusRenderer({
          Approved: 1,
          Success: 1,
        }),
      },
    }, {
      name: 'chaseResponse.procStatusMessage',
      label: 'Message',
      options: {
        filter: false,
      },
    }, {
      name: 'chaseResponse.respCode',
      label: 'Code',
      options: {
        filter: true,
      },
    }, {
      name: 'chaseTxRefNum',
      label: 'Chase Tx Ref Num',
      options: {
        display: false,
        filter: false,
      },
    }, {
      name: 'source',
      label: 'Source',
      options: {
        filter: false,
      },
    }, {
      name: 'description',
      label: 'Description',
      options: {
        display: false,
        filter: false,
      },
    }, {
      name: 'initiatedBy',
      label: 'By',
    }, {
      name: 'itemUpdatedAt',
      label: 'Updated At',
      options: {
        display: false,
        filter: false,
        customBodyRender: tableCellTimeRenderer(true),
      },
    }, {
      name: 'createdDate',
      label: 'Created At',
      options: {
        filter: false,
        customBodyRender: tableCellTimeRenderer(true),
      },
    }].map((item) => {
      if (this.props.tripId) {
        if (['tripId', 'accountId'].includes(item.name)) {
          item.options = {
            filter: false,
            display: false,
          };
        }
      }
      if (this.props.accountId) {
        if (['accountId'].includes(item.name)) {
          item.options = {
            filter: false,
            display: false,
          };
        }
      }
      return item;
    });

    const data = (this.props.data || this.state.data);

    const onItemClick = (rowData, rowMeta) => {
      const item = data[rowMeta.dataIndex];
      console.log(item);
    };

    const options = {
      filterType: 'checkbox',
      onRowClick: onItemClick,
      rowsPerPageOptions: [10, 50, 100],
      rowsPerPage: 10,
    };

    data
      .sort(sortBy('createdDate', true))
      .sort(sortBy('updatedDate', true));

    return (
      <Grid item xs={12}>
        <Table
          title={this.props.title || 'Transactions'}
          data={data}
          columns={headers}
          options={options}
          nested={this.props.nested}
        />
      </Grid>);
  }
}
