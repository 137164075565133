import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import Table from './Table';
import {
  tableCellAccountIdRenderer,
  tableCellTimeRenderer,
  tableCellStatusRenderer,
  tableRenderer,
  sortBy,
  request,
} from '../utils';
import ProgramTable from './ProgramTable';
import HistoryTable from './HistoryTable';

export default class CreditTable extends Component {
  state = {
    data: [],
  }

  componentDidMount() {
    this.load();
  }

  load = () => {
    if (this.props.data) return;
    if (this.props.code) {
      return Promise.resolve()
        .then(() => {
          const options = {
            api: `/api/promotion/code/${this.props.code.code}/credits`,
            method: 'GET',
            mode: 'cors',
          };
          return request(options);
        })
        .then((res) => {
          this.setState({
            data: res.data,
          });
        })
        .catch(console.log);
    } else
    if (this.props.accountId) {
      return Promise.resolve()
        .then(() => {
          const options = {
            api: `/api/account/${this.props.accountId}/credits`,
            method: 'GET',
            mode: 'cors',
          };
          return request(options);
        })
        .then((res) => {
          this.setState({
            data: res.data,
          });
        })
        .catch(console.log);
    }
  }
  render() {
    const headers = [{
      name: 'accountId',
      label: 'Account ID',
      options: {
        filter: false,
        display: this.props.code ? true : false,
        customBodyRender: tableCellAccountIdRenderer(null),
      },
    }, {
      name: 'programId',
      label: 'Program ID',
      options: {
        filter: false,
      },
    }, {
      name: 'code',
      label: 'code',
      options: {
        filter: false,
      },
    }, {
      name: 'title',
      label: 'Title',
      options: {
        filter: false,
      },
    // }, {
    //   name: 'description',
    //   label: 'Description',
    //   options: {
    //     filter: false,
    //     display: false,
    //   }
    }, {
      name: 'dateStart',
      label: 'Start',
      options: {
        filter: false,
        customBodyRender: tableCellTimeRenderer(true),
      },
    }, {
      name: 'dateEnd',
      label: 'End',
      options: {
        filter: false,
        customBodyRender: tableCellTimeRenderer(true),
      },
    }, {
      name: 'usage',
      label: 'Usage',
      options: {
        filter: false,
        customBodyRender: tableRenderer('center'),
      },
    }, {
      name: 'creditType',
      label: 'Type',
      options: {
        customBodyRender: tableRenderer('center'),
      },
    }, {
      name: 'creditValue',
      label: 'Value',
      options: {
        filter: false,
        customBodyRender: tableRenderer(),
      },
    }, {
      name: 'isFulfilled',
      label: 'Fulfilled',
      options: {
        customBodyRender: tableCellStatusRenderer(),
      },
    },
    {
      name: 'ignoreTripTime',
      label: 'Ignore Trip Time',
      options: {
        filter: true,
        customBodyRender: tableCellStatusRenderer({
          0: 2,
          1: 1,
        }),
      },
    },
    {
      name: 'assetIds',
      label: 'Roadways',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value = []) => {
          return `${value.sort((a, b) => a > b ? 1 : -1).join(', ')}`;
        },
      },
    },
    {
      name: 'referralId',
      label: 'Referral ID',
      options: {
        filter: false,
      },
    },
    {
      name: 'itemUpdatedAt',
      label: 'Updated At',
      options: {
        filter: false,
        customBodyRender: tableCellTimeRenderer(true),
      },
    }, {
      name: 'itemCreatedAt',
      label: 'Created At',
      options: {
        filter: false,
        customBodyRender: tableCellTimeRenderer(true),
      },
    }];

    const data = (this.props.data || this.state.data);

    const onItemClick = (rowData, rowMeta) => {
      const item = data[rowMeta.dataIndex];
      console.log(item);
    };

    const options = {
      filterType: 'checkbox',
      onRowClick: onItemClick,
      rowsPerPageOptions: [10, 50, 100],
      rowsPerPage: 10,
      expandableRows: this.props.code ? false : true,
      renderExpandableRow: (rowData, rowMeta) => {
        console.log('renderExpandableRow', rowData, rowMeta);
        const item = data[rowMeta.dataIndex];

        return (
          <TableRow>
            <TableCell colSpan={headers.length + 1} className="nested-table-container">
              <ProgramTable programId={item.programId} nested={true} />
              <HistoryTable credit={item} nested={true} />
            </TableCell>
          </TableRow>
        );
      },
    };

    data
      .sort(sortBy('itemCreatedAt', true))
      .sort(sortBy('itemUpdatedAt', true))
      .map((item) => {
        item.usage = `${item.countUses}/${item.usesMax}`;
        return item;
      });

    return (
      <Grid item xs={12}>
        <Table
          title={this.props.title || 'Credits'}
          data={data}
          columns={headers}
          options={options}
          nested={this.props.nested}
        />
      </Grid>);
  }
}
