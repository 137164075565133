import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DetailForm from 'react-material-final-form';

import { request } from '../utils';

const metadata = {
  fields: [{
    key: 'fromAccountId',
    type: 'String',
    label: 'Source account ID',
    isDisabled: true,
  }, {
    key: 'toAccountId',
    type: 'String',
    label: 'Target account ID',
  }],
};

const AccountMergeModal = ({ fromAccountId, onUpdate }) => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({});

  useEffect(() => {
    setData({
      fromAccountId,
      toAccountId: '',
    });
  }, [fromAccountId]);

  const onSubmit = async (value) => {
    try {
      const options = {
        api: `/api/account/merge`,
        method: 'POST',
        body: JSON.stringify({
          from: value.fromAccountId,
          to: value.toAccountId,
        }),
        mode: 'cors',
      };
      await request(options);
      setOpen(false);
      if (onUpdate) {
        onUpdate();
      }
    } catch (e) {
      console.log(e);
    } finally {
      //
    }
  };

  return (
    <Fragment>
      <Button className="space" variant="outlined" color="secondary" onClick={()=>setOpen(true)}>
        Merge
      </Button>
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={open}
        onClose={()=>setOpen(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          <p style={{ color: 'red' }}>
            This is a dangerous process. Please make sure that you have confirmed the target user is exactly the same with the current user.
          </p>
          <DetailForm
            title={'Merge Account Data'}
            metadata={metadata}
            data={data}
            onSubmit={onSubmit}
          />
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

AccountMergeModal.propTypes = {
  fromAccountId: PropTypes.string,
  onUpdate: PropTypes.func,
};

export default AccountMergeModal;
