import React, { Fragment } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DetailForm from 'react-material-final-form';

import { request } from '../utils';
import metadata from './CreateCodeMetadata';

export default class CreatecodeModal extends React.Component {
  state = {
    open: false,
    loading: false,
    metadata,
    data: {},
  };

  componentDidMount() {
    const { data, program } = this.props;

    const assetIdField = metadata.fields.find(({ key }) => key === 'assetIds');
    assetIdField.formOptions = ((data || program).assetIds || []).map((id) => {
      return { value: id, label: id };
    });

    if (data) {
      this.setState({
        data,
        metadata,
      });
    } else
    if (program) {
      this.setState({
        metadata,
        data: {
          programId: program.id,
          title: program.title,
          description: program.description,
          dateStart: program.dateStart,
          dateEnd: program.dateEnd,
          countUses: 0,
          usesMax: program.usesMax,
          creditType: program.creditType,
          creditValue: program.creditValue,
          assetIds: program.assetIds,
          ignoreTripTime: program.ignoreTripTime,
          creditUseDuration: 0,
          usesMaxPerAccount: 1,
        },
      });
    }
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  onSubmit = (values) => {
    return Promise.resolve()
      .then(() => {
        if (this.props.data) {
          const options = {
            api: `/api/promotions/code/${values.code}`,
            method: 'PUT',
            body: JSON.stringify(values),
            mode: 'cors',
          };
          return request(options);
        } else {
          const options = {
            api: `/api/promotions/codes`,
            method: 'POST',
            body: JSON.stringify(values),
            mode: 'cors',
          };

          return request(options);
        }
      })
      .then(() => {
        this.handleClose();

        if (this.props.onUpdate) {
          this.props.onUpdate();
        }
      })
      .catch(console.log);
  }

  render() {
    const mode = this.props.data ? 'Edit' : 'Create';

    return (
      <Fragment>
        <Button className="space" color="primary" onClick={this.handleClickOpen}>
          {mode} Code
        </Button>
        <Dialog
          fullWidth={true}
          maxWidth="md"
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            Code
          </DialogTitle>
          <DialogContent>
            <DetailForm
              title={mode}
              metadata={this.state.metadata}
              data={this.state.data}
              onSubmit={this.onSubmit}
            />
          </DialogContent>
        </Dialog>
      </Fragment>
    );
  }
}
