import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';

import Table from './Table';
import { tableCellTimeRenderer, sortBy, request } from '../utils';

export default class HistoryTable extends Component {
  state = {
    env: window.localStorage.getItem('env'),
    data: [],
  }

  componentDidMount() {
    this.load();
  }

  load = () => {
    if (this.props.data) return;

    let query = {};
    if (this.props.program) {
      query = {
        tableName: `got-${this.state.env}-promotion-programs`,
        partitionKey: this.props.program.id,
      };
    } else
    if (this.props.code) {
      query = {
        tableName: `got-${this.state.env}-promotion-codes`,
        partitionKey: this.props.code.code,
      };
    } else
    if (this.props.credit) {
      query = {
        tableName: `got-${this.state.env}-promotion-credits`,
        partitionKey: this.props.credit.accountId,
        sortKey: this.props.credit.code,
      };
    } else
    if (this.props.referral) {
      query = {
        tableName: `got-${this.state.env}-promotion-referrals`,
        partitionKey: this.props.referral.id,
      };
    } else
    if (this.props.trip) {
      query = {
        tableName: `got-${this.state.env}-trips-trips`,
        partitionKey: this.props.trip.tripId,
      };
    }

    return Promise.resolve()
      .then(() => {
        const options = {
          api: `/api/history`,
          query,
          method: 'GET',
          mode: 'cors',
        };
        return request(options);
      })
      .then((res) => {
        this.setState({
          data: res.data.data,
        });
      })
      .catch(console.log);
  }
  render() {
    const headers = [{
      name: 'eventId',
      label: 'ID',
      options: {
        filter: false,
        display: false,
      },
    }, {
      name: 'eventName',
      label: 'Type',
      options: {
        filter: false,
      },
    }, {
      name: 'eventTime',
      label: 'Time',
      options: {
        filter: false,
        customBodyRender: tableCellTimeRenderer(true),
      },
    }, {
      name: 'diff',
      label: 'Diff',
      options: {
        filter: false,
        customBodyRender: (value) => {
          const ignore = ['itemUpdatedAt', 'itemCreatedAt'];
          const items = value.filter((item) => !ignore.includes(item.key));
          return <div>
            {items.map((item, index)=>(
              <p key={index}>
                <b>{item.key}</b> {item.old || 'undefined'} =&gt; {item.new}
              </p>
            ))}
          </div>;
        },
      },
    }, {
      name: 'itemUpdatedAt',
      label: 'Updated At',
      options: {
        filter: false,
        customBodyRender: tableCellTimeRenderer(true),
      },
    }, {
      name: 'itemCreatedAt',
      label: 'Created At',
      options: {
        filter: false,
        customBodyRender: tableCellTimeRenderer(true),
      },
    }];

    const data = (this.props.data || this.state.data);

    const onItemClick = (rowData, rowMeta) => {
      const item = data[rowMeta.dataIndex];
      console.log(item);
    };

    const options = {
      filterType: 'checkbox',
      onRowClick: onItemClick,
      rowsPerPageOptions: [10, 50, 100],
      rowsPerPage: 10,
    };

    console.log(data);

    data
      .sort(sortBy('eventTime', true));

    return (
      <Grid item xs={12}>
        <Table
          title={this.props.title || 'History'}
          data={data}
          columns={headers}
          options={options}
          nested={this.props.nested}
        />
      </Grid>);
  }
}
