import React, { Fragment, useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DetailForm from 'react-material-final-form';

import { request } from '../utils';
import sourceMetadata from './CreateCreditMetadata';

export default ({ code, onUpdate }) => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({});
  const [metadata, setMetadata] = useState(sourceMetadata);

  useEffect(() => {
    if (!code) return;

    const assetIdField = sourceMetadata.fields.find(({ key }) => key === 'assetIds');
    assetIdField.formOptions = (code.assetIds || []).map((id) => {
      return { value: id, label: id };
    });

    setMetadata(sourceMetadata);
    setData({
      programId: code.programId,
      code: code.code,
      title: code.title,
      accountId: '',
      description: code.description,
      dateStart: code.dateStart,
      dateEnd: code.dateEnd,
      countUses: 0,
      usesMax: 1,
      creditType: code.creditType,
      creditValue: code.creditValue,
      assetIds: code.assetIds,
      ignoreTripTime: code.ignoreTripTime,
    });
  }, [code]);

  const onSubmit = async (values) => {
    try {
      const options = {
        api: `/api/promotions/credits`,
        method: 'POST',
        body: JSON.stringify(values),
        mode: 'cors',
      };

      await request(options);
      setOpen(false);
      if (onUpdate) {
        onUpdate();
      }
    } catch (e) {
      console.log(e);
    } finally {
      //
    }
  };

  return (
    <Fragment>
      <Button className="space" color="primary" onClick={()=>setOpen(true)}>
        Apply Code To Account
      </Button>
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={open}
        onClose={()=>setOpen(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Apply Code To Account
        </DialogTitle>
        <DialogContent>
          <p style={{ color: 'red' }}>
            Make sure you have the following things ready before you start
            <ul>
              <li>User Account ID </li>
              <li>Trip Date - The credit start and end date should cover the trip date.  </li>
              <li>Convert credit value to Cent from Dollar</li>
            </ul>
          </p>
          <DetailForm
            title={'Create Account Credit'}
            metadata={metadata}
            data={data}
            onSubmit={onSubmit}
          />
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};
