import React, { Fragment, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';
import Place from '@material-ui/icons/Place';
import CameraAlt from '@material-ui/icons/CameraAlt';

import { sortBy, request, diffTime } from '../utils';

const TIME_FORMAT = 'YYYY-MM-DDTHH:mm';

const formatOdOptionDisplay = (od) => {
  return `${od.direction} - ${od.id} (${od.source_target}) - ${od.nameSource} - ${od.nameTarget}`;
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: theme.spacing(1),
    width: '100%',
  },
  imgContainer: {
    paddingLeft: theme.spacing(2),
  },
  img: {
    width: '100%',
  },
  actionContainer: {
    padding: theme.spacing(2),
  },
}));

export default function CreateTripModal({ mode, accountId, onUpdate }) {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [assets, setAssets] = useState([]);
  const [assetId, setAssetId] = useState('');
  const [assetODs, setAssetODs] = useState([]);
  const [odId, setOdId] = useState('');
  const [od, setOd] = useState({});
  const [rangeFrom, setRangeFrom] = useState(moment(Date.now() - 60 * 60 * 1000).format(TIME_FORMAT));
  const [price, setPrice] = useState(215);
  const [mapImageSrc, setMapImageSrc] = useState('');
  const [errorMsg, setErrorMsg] = useState(null);

  const getAssets = useCallback(async () => {
    const options = {
      api: `/api/assets`,
      method: 'GET',
      mode: 'cors',
    };

    const { data } = await request(options);
    data.sort((a, b) => a.id > b.id ? 1 : -1);
    setAssets(data);
    setAssetId('va-i95-tu');
  }, []);

  const getAssetOds = useCallback(async (assetId) => {
    const options = {
      api: `/api/assets/${assetId}/ods`,
      method: 'GET',
      mode: 'cors',
    };

    const { data } = await request(options);

    const ods = data
      .sort((a, b) => a.id < b.id ? 1 : -1)
      .sort(sortBy('direction'));

    setAssetODs(ods);
    setOdId(ods[0].id);
  }, []);

  const createTrips = useCallback(async () => {
    if (window.localStorage.getItem('env') === 'prd') {
      return console.log('Can not run this in prd.');
    }

    try {
      setLoading(true);
      const [origin, destination] = od.source_target.split('_');

      if (mode === 'Asset') {
        const options = {
          api: `/api/account/${accountId}/newAssetTrips`,
          query: {
            assetId,
            origin,
            destination,
            originTimestamp: new Date(rangeFrom).getTime(),
            price,
          },
          method: 'GET',
          mode: 'cors',
        };

        await request(options);
      } else {
        const options = {
          api: `/api/account/${accountId}/newGpsTrip`,
          query: {
            assetId,
            origin,
            destination,
            originTimestamp: new Date(rangeFrom).getTime(),
          },
          method: 'GET',
          mode: 'cors',
        };

        await request(options);
      }
      if (onUpdate) {
        onUpdate();
      }
      setOpen(false);
    } catch (err) {
      setErrorMsg(err.message);
    } finally {
      setLoading(false);
    }
  }, [accountId, assetId, mode, od, onUpdate, price, rangeFrom]);

  useEffect(() => {
    if (open) {
      getAssets();
    }
  }, [open, getAssets]);

  useEffect(() => {
    if (assetId) {
      getAssetOds(assetId);
    }
  }, [assetId, getAssetOds]);

  useEffect(() => {
    const od = assetODs.find((item) => item.id === odId);
    if (od) {
      const [source, target] = od.source_target.split('_');
      setOd(od);
      setMapImageSrc(`https://s3.amazonaws.com/got-dev-static/images/routes_ods/${assetId}__${source}__${target}.png`);
    }
  }, [assetId, odId, assetODs]);

  return (
    <Fragment>
      <Button className="space" color="primary" onClick={()=>setOpen(true)}>
        {
          mode ==='Asset'?
            <Fragment><CameraAlt /> Create Asset trips</Fragment>:
            <Fragment><Place /> Create GPS trips </Fragment>
        }
      </Button>
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={open}
        onClose={()=>setOpen(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Create new {mode || 'GPS'} trips
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12} md={6}>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="age-native-simple">Asset</InputLabel>
                <Select
                  native
                  value={assetId}
                  onChange={(event) => setAssetId(event.target.value)}
                  inputProps={{
                    name: 'assetId',
                    id: 'asset-id',
                  }}>
                  {assets.map((asset)=>{
                    return (<option key={asset.id} value={asset.id}>{asset.id} - {asset.name}</option>);
                  })}
                </Select>
              </FormControl>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="age-native-simple">OD</InputLabel>
                <Select
                  native
                  value={odId}
                  onChange={(event)=> setOdId(event.target.value)}
                  inputProps={{
                    name: 'odId',
                    id: 'odId-id',
                  }}>
                  {assetODs.map((od)=>{
                    return (
                      <option key={od.id} value={od.id}>
                        {formatOdOptionDisplay(od)}
                      </option>);
                  })}
                </Select>
              </FormControl>
              <FormControl className={classes.formControl}>
                <TextField
                  id="datetime-local-from"
                  label={`From (${diffTime(rangeFrom)})`}
                  type="datetime-local"
                  defaultValue={rangeFrom}
                  onChange={(event)=> setRangeFrom(event.target.value)}
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <TextField
                  id="pricing-name"
                  label="Pricing"
                  value={price}
                  disabled={mode === 'GPS'}
                  margin="normal"
                  onChange={(event) => setPrice(event.target.value)}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">Cent</InputAdornment>,
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className={classes.imgContainer}>
                <img src={mapImageSrc} alt="" className={classes.img} />
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={classes.actionContainer}>
          <Typography variant="body1" color="error">
            {errorMsg}
          </Typography>
          <Button onClick={()=>setOpen(false)} color="default">
            Cancel
          </Button>
          <Button variant="contained" onClick={createTrips} color="primary" disabled={loading || !price}>
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

CreateTripModal.propTypes = {
  mode: PropTypes.string,
  accountId: PropTypes.string,
  onUpdate: PropTypes.func,
};
