import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { green, red } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    boxShadow: 'none',
  },
  value: {
    margin: theme.spacing(1),
  },
}));
export default function SummaryCard({
  title,
  value,
  prevValue,
  unit = '',
  appendix = '',
  period,
  description,
  hideDesc = false,
  color = '#000',
}) {
  const classes = useStyles();

  let p;
  switch (period) {
  case 'year':
    p = 'last year';
    break;
  case 'month':
    p = 'last month';
    break;
  case 'week':
    p = 'last week';
    break;
  case 'day':
  default:
    p = 'last period';
    break;
  }

  const diff = +(((prevValue ? (value - prevValue) / prevValue : value) * 100).toFixed(0));
  const isSame = diff === 0;
  const isIncreased = diff > 0;
  const desc = (isSame ? `Same as ${p}` : `Compare to ${p}(${unit}${prevValue}${appendix}) ${isIncreased?'Increase':'Decrease'} ${Math.abs(diff)}%`);

  return (
    <Paper className={classes.container}>
      <Typography variant="h5" component="h5">
        {title}
      </Typography>
      <Typography variant="h4" component="h1" align="center" className={classes.value} style={{ color }}>
        {(isSame || hideDesc) ? '' :
          isIncreased ?
            <ArrowDropUpIcon fontSize="small" style={{ color: green[500] }} /> :
            <ArrowDropDownIcon fontSize="small" style={{ color: red[500] }} />}
        {unit}{(value || 0).toLocaleString('en-US')}{appendix}
      </Typography>
      {!hideDesc &&
      <Typography variant="body2" component="p" align="center" color="textSecondary">
        {desc}
      </Typography>}
      {description &&
      <Typography variant="body2" component="p" align="center" color="textSecondary">
        {description}
      </Typography>}
    </Paper>);
}


SummaryCard.propTypes = {
  title: PropTypes.string,
  value: PropTypes.number,
  prevValue: PropTypes.number,
  unit: PropTypes.string,
  appendix: PropTypes.string,
  period: PropTypes.string,
};
