import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import to from 'await-to-js';
import Grid from '@material-ui/core/Grid';

import Table from '../components/Table';

import {
  request,
  sortBy,
  tableCellStatusRenderer,
} from '../utils';

const CronJob = ({ lastUpdatedAt }) => {
  const env = window.localStorage.getItem('env');
  const [jobs, setJobs] = useState([]);

  const renderJobTable = (inTitle, inData) => {
    const headers = [{
      name: 'arn',
      label: 'Arn',
      options: {
        filter: false,
        display: false,
      },
    }, {
      name: 'rule',
      label: 'Rule',
      options: {
        filter: false,
        display: false,
      },
    }, {
      name: 'module',
      label: 'Module',
    }, {
      name: 'function',
      label: 'Function',
      options: {
        filter: false,
      },
    }, {
      name: 'rate',
      label: 'Rate',
      options: {
        filter: false,
      },
    }, {
      name: 'state',
      label: 'State',
      options: {
        customBodyRender: tableCellStatusRenderer({
          'ENABLED': 1,
          'DISABLED': 2,
        }),
      },
    }];

    const data = inData.sort(sortBy('createdAt', true));

    const onItemClick = (rowData, rowMeta) => {
      const item = data[rowMeta.dataIndex];
      console.log(item);
    };

    const options = {
      filterType: 'checkbox',
      onRowClick: onItemClick,
      rowsPerPageOptions: [50, 100, 200],
      rowsPerPage: 50,
    };

    return (
      <Grid item xs={12}>
        <Table
          title={inTitle}
          data={data}
          columns={headers}
          options={options}
        />
      </Grid>);
  };

  useEffect(() => {
    (async () => {
      const options = {
        api: `/api/cronJob/${env}`,
        method: 'GET',
        mode: 'cors',
      };
      const [err, { data }] = await to(request(options));
      if (err) {
        return console.error(err);
      }
      setJobs(data);
    })();
  }, [env, lastUpdatedAt]);

  return (
    <Fragment>
      {renderJobTable('Cron Jobs', jobs)}
    </Fragment>);
};

CronJob.propTypes = {
  lastUpdatedAt: PropTypes.number,
};

export default CronJob;
