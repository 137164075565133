import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { ResponsiveBarCanvas, ResponsiveBar } from '@nivo/bar';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    boxShadow: 'none',
  },
  value: {
    margin: theme.spacing(1),
  },
}));

export default function BarCard({
  title,
  data = [],
  dataKeys = [],
  mode = 'grouped',
  indexBy = 'name',
  height = 300,
  colors = { scheme: 'nivo' },
  margin = { top: 50, right: 130, bottom: 50, left: 60 },
  legends = [
    {
      dataFrom: 'keys',
      anchor: 'bottom-right',
      direction: 'column',
      justify: false,
      translateX: 120,
      translateY: 0,
      itemsSpacing: 2,
      itemWidth: 100,
      itemHeight: 20,
      itemDirection: 'left-to-right',
      itemOpacity: 0.85,
      symbolSize: 20,
      effects: [
        {
          on: 'hover',
          style: {
            itemOpacity: 1,
          },
        },
      ],
    },
  ],
}) {
  const classes = useStyles();

  return (
    <Paper className={classes.container}>
      <Typography variant="h5" component="h5">
        {title}
      </Typography>
      <Grid container align="center" justifyContent="center">
        <div style={{ width: '100%', height }}>
          <ResponsiveBarCanvas
            data={data}
            keys={dataKeys}
            indexBy={indexBy}
            groupMode={mode}
            margin={margin}
            padding={0.3}
            colors={colors}
            borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: -90,
            }}
            enableLabel={false}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
            legends={legends.length > 1 ? [] : legends}
            animate={false}
            motionStiffness={90}
            motionDamping={15}
          />
        </div>
        {legends.length > 1 &&
        <div style={{ width: '100%', height: 150 }}>
          <ResponsiveBar
            data={[]}
            keys={['a']}
            indexBy={''}
            margin={margin}
            colors={colors}
            axisTop={null}
            axisRight={null}
            axisBottom={null}
            legends={legends}
          />
        </div>}
      </Grid>
    </Paper>);
}

BarCard.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
  dataKeys: PropTypes.array,
  mode: PropTypes.string,
  indexBy: PropTypes.string,
  margin: PropTypes.any,
  legends: PropTypes.array,
};
