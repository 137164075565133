import React, { Fragment } from 'react';

import AssetTable from '../components/AssetTable';

import './Asset.css';

const Asset = () => {
  return (
    <Fragment>
      <AssetTable />
    </Fragment>);
};

export default Asset;
