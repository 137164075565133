import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import Table from './Table';
import Code from './Code';
import {
  tableCellTimeRenderer,
  tableCellStatusRenderer,
  sortBy,
  request,
} from '../utils';

export default class VendorTransactionTable extends Component {
  state = {
    data: [],
  }

  componentDidMount() {
    this.load();
  }

  load = () => {
    if (this.props.data) return;
    if (!this.props.uniqueId) return;

    return Promise.resolve()
      .then(() => {
        const options = {
          api: `/api/vendorTransaction`,
          query: {
            uniqueId: this.props.uniqueId,
          },
          method: 'GET',
          mode: 'cors',
        };
        return request(options);
      })
      .then((res) => {
        this.setState({
          data: Array.isArray(res.data) ? res.data : [res.data],
        });
      })
      .catch(console.log);
  }

  render() {
    const headers = [{
      name: 'vendor',
      label: 'Vendor',
    },
    {
      name: 'uniqueId',
      label: 'ID',
      options: {
        filter: false,
      },
    }, {
      name: 'category',
      label: 'Category',
    }, {
      name: 'txStatus',
      label: 'Status',
      options: {
        customBodyRender: tableCellStatusRenderer(),
      },
    }, {
      name: 'txStatusMsg',
      label: 'Status Message',
      options: {
        filter: false,
      },
      // }, {
      //   name: 'data',
      //   label: 'Data',
      //   options: {
      //     display: false,
      //     filter: false,
      //   }
    }, {
      name: 'createdDate',
      label: 'Created At',
      options: {
        filter: false,
        customBodyRender: tableCellTimeRenderer(true),
      },
    },
    ].map((item) => {
      return item;
    });

    const data = (this.props.data || this.state.data);

    const onItemClick = (rowData, rowMeta) => {
      const item = data[rowMeta.dataIndex];
      console.log(item);
    };

    const options = {
      filterType: 'checkbox',
      onRowClick: onItemClick,
      rowsPerPageOptions: [10, 50, 100],
      rowsPerPage: 10,
      expandableRows: true,
      renderExpandableRow: (rowData, rowMeta) => {
        const item = data[rowMeta.dataIndex];
        return (
          <TableRow>
            <TableCell colSpan={headers.length + 1} className="nested-table-container">
              <Paper>
                {/* <pre>{JSON.stringify(item, null, 4)}</pre> */}
                <Code code={item} />
              </Paper>
            </TableCell>
          </TableRow>
        );
      },
    };

    data
      .sort(sortBy('createdDate', true))
      .filter((item) => item)
      .map((item) => {
        // try {
        //   item.data = JSON.stringify(item.data);
        // } catch (e) {
        //   //
        // }
        headers.forEach((header) => {
          item[header.name] = (item[header.name] !== undefined) ? item[header.name] : '';
        });
        return item;
      });

    return (
      <Grid item xs={12}>
        <Table
          title={this.props.title || 'Vendor Source Transactions'}
          data={data}
          columns={headers}
          options={options}
          nested={true}
        />
      </Grid>);
  }
}
