import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import IconButton from '@material-ui/core/IconButton';
import SyncIcon from '@material-ui/icons/Sync';
import Select from '@material-ui/core/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { DebounceInput } from 'react-debounce-input';
import GetAppIcon from '@material-ui/icons/GetApp';

import RichTextEditor from 'react-rte';
import { animateScroll, Element } from 'react-scroll';

import {
  request,
  formatTime,
  diffTime,
} from '../utils';
import { caseStatus } from './constants';
import EmailComposeDialog from './EmailComposeDialog';
import toolbarConfig from './rteToolbarConfig';
import CaseAccount from './CaseAccount';
import CaseTrips from './CaseTrips';

import './Case.css';

export default function Case({ id: inId, onUpdate, messageContainerHeight = '250px' }) {
  const [caseId, setCaseId] = useState();
  const [accountId, setAccountId] = useState();
  const [caseItem, setCaseItem] = useState();
  const [messages, setMessages] = useState([]);
  const [worknotes, setWorknotes] = useState([]);
  const [account, setAccount] = useState({ device: {}, app: {} });
  const [csrs, setCsrs] = useState([]);

  const [worknoteContent, setWorknoteContent] = useState(RichTextEditor.createEmptyValue());
  const [messageContent, setMessageContent] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  const downloadAttachment = async (s3Key) => {
    setIsDownloading(true);
    const options = {
      api: `/api/emailAttachment`,
      query: {
        s3Key,
      },
      method: 'GET',
      mode: 'cors',
    };

    const res = await request(options);
    console.log(res.headers); // returns a Headers{} object

    const blob = await res.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${s3Key.split('/').pop()}`;
    document.body.appendChild(a);
    a.click();
    a.remove();
    setIsDownloading(false);
  };

  const handleCaseUpdate = async (toUpdateProps) => {
    setIsSaving(true);
    try {
      const options = {
        api: `/api/customerCases/${caseItem.caseID}`,
        method: 'PUT',
        body: JSON.stringify(toUpdateProps),
        mode: 'cors',
      };

      const res = await request(options);
      console.log(res);

      const data = await loadCase(caseItem.caseID);
      setCaseItem(data);
      await Promise.all([
        loadMessages(caseId),
        loadWorknotes(caseId),
      ]);
    } catch (e) {
      console.log(e);
    } finally {
      setIsSaving(false);
    }
  };

  const handleCaseAccountUpdate = async (accountID) => {
    setIsSaving(true);
    try {
      const data = await loadCase(caseItem.caseID);
      setCaseItem(data);
      await Promise.all([
        loadAccount(accountID),
        loadMessages(caseId),
        loadWorknotes(caseId),
      ]);
    } catch (e) {
      console.log(e);
    } finally {
      setIsSaving(false);
    }
  };

  const saveMessageContent = async (inMessageHtml, inEmailAttachments) => {
    try {
      setIsSaving(true);
      const options = {
        api: `/api/case/${caseItem.caseID}/message`,
        method: 'POST',
        body: JSON.stringify({
          message: inMessageHtml ? 'N/A' : messageContent.toString('html'),
          messageHtml: inMessageHtml ? inMessageHtml : undefined,
          emailAttachments: inEmailAttachments || [],
        }),
        mode: 'cors',
      };

      await request(options);

      const [caseData] = await Promise.all([
        loadCase(caseId),
        loadMessages(caseId),
      ]);
      setCaseItem(caseData);
      setMessageContent('');
    } catch (e) {
      console.log(e);
    } finally {
      setIsSaving(false);
    }
  };

  const saveWorknote = async () => {
    setIsSaving(true);
    try {
      const options = {
        api: `/api/case/${caseItem.caseID}/notes`,
        method: 'POST',
        body: JSON.stringify({ content: worknoteContent.toString('html') }),
        mode: 'cors',
      };

      await request(options);

      await loadWorknotes(caseId);

      setWorknoteContent(RichTextEditor.createEmptyValue());
    } catch (e) {
      console.log(e);
    } finally {
      setIsSaving(false);
    }
  };

  const loadMessages = useCallback(async (inCaseId) => {
    const options = {
      api: `/api/case/${inCaseId}/messages`,
      method: 'GET',
      mode: 'cors',
    };
    const { data } = await request(options);
    console.log('messages', data);
    if (data.some(({ emailS3Key, messageHtml }) => emailS3Key)) {
      // load email from the S3
      console.log('load email from the S3');
    }
    setMessages(data.sort((a, b) => a.createdAt > b.createdAt ? 1 : -1));

    setTimeout(() => {
      animateScroll.scrollToBottom({
        containerId: 'messages-container',
      });
    }, 300);
  }, []);

  const loadWorknotes = useCallback(async (inCaseId) => {
    const { data: worknoteData } = await request({
      api: `/api/case/${inCaseId}/notes`,
      method: 'GET',
      mode: 'cors',
    });
    console.log('notes', worknoteData);
    setWorknotes(worknoteData.sort((a, b) => a.createdAt > b.createdAt ? 1 : -1));
    setTimeout(() => {
      animateScroll.scrollToBottom({
        containerId: 'worknotes-container',
      });
    }, 300);
  }, []);

  const loadCase = useCallback(async (id) => {
    const options = {
      api: `/api/case/${id}`,
      method: 'GET',
      mode: 'cors',
    };
    const { data } = await request(options);
    console.log('case', data);
    if (onUpdate) {
      onUpdate(data);
    }
    return data;
  }, [onUpdate]);

  const loadAccount = useCallback(async (accountId) => {
    const { data: accountData } = await request({
      api: `/api/account/${accountId}`,
      method: 'GET',
      mode: 'cors',
    });
    setAccount(accountData);
    setAccountId(accountId);
    return accountData;
  }, [setAccount, setAccountId]);

  useEffect(() => {
    if (!accountId) return;
    (async () => {
      await loadAccount(accountId);
    })();
  }, [accountId, loadAccount]);

  useEffect(() => {
    if (!caseId) return;

    (async () => {
      await Promise.all([
        loadMessages(caseId),
        loadWorknotes(caseId),
      ]);
    })();
  }, [caseId, loadWorknotes, loadMessages]);

  useEffect(() => {
    (async () => {
      const params = new URLSearchParams(window.location.search);
      const id = inId || params.get('id');
      setCaseId(id);

      if (id) {
        const caseData = await loadCase(id);
        setCaseItem(caseData);
        setAccountId(caseData.accountID);
        if (caseData.email) {
          const [firstName, lastName] = caseData.name.split(' ');
          setAccount({
            email: caseData.email,
            firstName,
            lastName,
            device: {},
            app: {},
          });
        }
      } else {
        setCaseItem(null);
        setAccountId(null);
      }
    })();
  }, [inId, loadCase]);

  useEffect(() => {
    (async () => {
      const { data } = await request({
        api: `/api/users`,
        method: 'GET',
        mode: 'cors',
      });
      const csrs = data.filter(({ isActive, team }) => isActive && team === 'ops');
      setCsrs(csrs);
    })();
  }, [caseId]);

  if (!caseItem) return null;

  return (
    <Grid container spacing={2} className="main-container">
      <Grid item xs={12} md={4} lg={3} style={{ height: '100vh', paddingBottom: 100, overflowY: 'auto' }}>
        <Card className="case-details-card">
          <IconButton
            className="open-in-new-button"
            color="inherit"
            onClick={async () => {
              const [caseData] = await Promise.all([
                loadCase(caseId),
                loadMessages(caseId),
                loadWorknotes(caseId),
              ]);
              setCaseItem(caseData);
            }}
            aria-label="open"
          >
            <SyncIcon />
          </IconButton>
          <CardHeader title="Case Details" />
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={isSaving}
                      checked={caseItem.isSpam === 1}
                      onChange={()=>{
                        handleCaseUpdate({ isSpam: caseItem.isSpam ? 0 : 1 });
                      }}
                      color="secondary"
                    />
                  }
                  label={'Mark as SPAM'}
                />
              </Grid>
              <Grid item xs={12}><TextField readOnly={true} label="Case ID" value={caseItem.caseID} fullWidth /></Grid>
              <Grid item xs={6}><TextField readOnly={true} label="Category" value={caseItem.category} fullWidth /></Grid>
              <Grid item xs={6}><TextField readOnly={true} label="Source" value={caseItem.source} fullWidth /></Grid>
              <Grid item xs={6}>
                {/* <TextField readOnly={true} label="Status" value={caseItem.status} fullWidth /> */}
                <FormControl fullWidth>
                  <InputLabel>
                    Status
                  </InputLabel>
                  <Select
                    native
                    value={caseItem.status}
                    disabled={isSaving}
                    onChange={(event)=> handleCaseUpdate({ status: event.target.value })}
                  >
                    {caseStatus.map((name)=>{
                      return (<option key={name} value={name}>{name}</option>);
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel>
                    Assignee
                  </InputLabel>
                  <Select
                    native
                    value={caseItem.csrID}
                    disabled={isSaving}
                    onChange={(event)=> {
                      if (event.target.value === '') return;
                      handleCaseUpdate({ csrID: event.target.value });
                    }}
                  >
                    <option value={''}> </option>
                    {csrs.map(({ name, email })=>{
                      return (<option key={email} value={email}>{name}</option>);
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}><TextField readOnly={true} label="Subject" value={caseItem.title} fullWidth multiline/></Grid>
              <Grid item xs={12}><TextField readOnly={true} label="Description" value={caseItem.description} fullWidth multiline/></Grid>
            </Grid>
          </CardContent>
        </Card>
        <CaseAccount account={account} caseItem={caseItem} onUpdate={handleCaseAccountUpdate} />
        <CaseTrips accountId={accountId} />
      </Grid>
      <Grid item xs={12} md={5} lg={5}>
        <Element
          id="messages-container"
          className="messages-container"
          style={{ height: `calc(100vh - ${messageContainerHeight} + ${caseItem.source === 'app' ? '0px' : '130px' }` }}
        >
          {
            messages.map(({ message, messageHtml, name, createdAt, isUser, emailAttachments = [] }, index)=>(
              <Card
                key={index}
                className={isUser? 'message-from':'message-to'}
              >
                <CardContent>
                  {messageHtml ?
                    <div dangerouslySetInnerHTML={{ __html: messageHtml }} /> :
                    <div dangerouslySetInnerHTML={{ __html: message.replace(/\n/g, '<br/>').replace(/\r/g, '<br/>') }} />}
                  {emailAttachments.length > 0 && <div>
                    <Divider className="message-divider"/>
                    {emailAttachments.map(({ filename, s3Key }, index)=>(
                      <Button
                        key={index}
                        onClick={() => downloadAttachment(s3Key)}
                        disabled={isDownloading}
                      >
                        {filename} <GetAppIcon />
                      </Button>
                    ))}
                  </div>}
                  <Divider className="message-divider"/>
                  <span className="message-subcontent">
                    <span style={{ flex: 1 }}>{name}</span>
                    {formatTime(createdAt)} ({diffTime(createdAt)})
                  </span>

                </CardContent>
              </Card>
            ))
          }
        </Element>
        {caseItem.source === 'app' ?
          <Card className="text-editor-card message-text-editor">
            <div style={{ width: '100%' }}>
              <span>Response</span>
              <div style={{ float: 'right' }}>
                <Button
                  color="secondary"
                  size="small"
                  onClick={() => setMessageContent('')}
                  disabled={isSaving || messageContent.length === 0}>
                  Clear
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  onClick={() => saveMessageContent()}
                  disabled={isSaving || messageContent.length < 1}>
                  Send
                </Button>
              </div>
            </div>
            <DebounceInput
              debounceTimeout={300}
              onChange={(e) => {
                setMessageContent(e.target.value);
              }}
              element={TextField}
              fullWidth
              id={`response-message`}
              label=""
              value={messageContent}
              margin="normal"
              type={'text'}
              placeholder="type here..."
              disabled={isSaving}
              multiline
            />
          </Card> :
          <EmailComposeDialog
            caseID={caseItem.caseID}
            onUpdate={({
              messageContentHtml,
              emailAttachments,
            }) => {
              saveMessageContent(messageContentHtml, emailAttachments);
            }}
          />
        }
      </Grid>
      <Grid item xs={12} md={3} lg={4}>
        <Element
          id="worknotes-container"
          className="worknotes-container"
          style={{ height: messageContainerHeight }}
        >
          {
            worknotes.map(({ content, csrID, createdAt }, index)=>(
              <Card
                key={index}
                className={`worknote-message ${csrID === window.localStorage.getItem('name') ? 'message-to':'message-from'}`}
              >
                <CardContent>
                  {/* {content} */}
                  <div dangerouslySetInnerHTML={{ __html: content }} >
                  </div>
                  <Divider className="message-divider"/>
                  <span className="message-subcontent">
                    <span style={{ flex: 1 }}>{csrID}</span>
                    {formatTime(createdAt)} ({diffTime(createdAt)})
                  </span>
                </CardContent>
              </Card>
            ))
          }
        </Element>
        <div className="text-editor-card">
          <RichTextEditor
            className="worknote-text-editor"
            toolbarConfig={toolbarConfig}
            value={worknoteContent}
            onChange={(value)=>{
              setWorknoteContent(value);
            }}
          />
          <div className="text-editor-action">
            <Button
              color="secondary"
              onClick={()=>setWorknoteContent(RichTextEditor.createEmptyValue())}
              disabled={isSaving || worknoteContent.length === 0}>
              Clear
            </Button>
            <Button
              color="primary"
              onClick={saveWorknote}
              disabled={isSaving || worknoteContent.length < 1}>
              Save
            </Button>
          </div>
        </div>
      </Grid>
    </Grid>);
}

Case.propTypes = {
  id: PropTypes.string,
  onUpdate: PropTypes.func,
  messageContainerHeight: PropTypes.string,
};
