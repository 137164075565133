import React, { useState, useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { DebounceInput } from 'react-debounce-input';
import queryString from 'query-string';
import Clear from '@material-ui/icons/Clear';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';

import './Database.css';
import { request } from '../utils';
import ResultTable from './ResultTable';
import { comparators, joins } from './constants';
import { isValidValue } from './helpers';

const useStyles = makeStyles((theme) => ({
  queryContainer: {
    marginBottom: theme.spacing(2),
  },
  container: {
    padding: theme.spacing(2),
  },
}));

export default function Database() {
  const classes = useStyles();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [tableName, setTableName] = useState('');
  // const [tableDetails, setTableDetails] = useState({});
  const [data, setData] = useState(null);
  const [indexes, setIndexes] = useState([]);
  const [queryIndex, setQueryIndex] = useState('scan');
  const [queryFields, setQueryFields] = useState([]);
  const [queryUseExpressionAttributeNames, setQueryUseExpressionAttributeNames] = useState(false);
  const [queryLimit, setQueryLimit] = useState(5);
  const [queryScanIndexForward, setQueryScanIndexForward] = useState(true);
  const [queryParams, setQueryParams] = useState({});
  const [showQueryParams, setShowQueryParams] = useState(true);
  const [info, setInfo] = useState({});
  const [showInfo, setShowInfo] = useState(true);
  const [schema, setSchema] = useState(null);
  const [showSchema, setShowSchema] = useState(false);
  const [stats, setStats] = useState({});
  const [showStats, setShowStats] = useState(false);
  const [attributes, setAttributes] = useState([]);

  useEffect(() => {
    (async () => {
      const { table } = queryString.parse(history.location.search);
      const options = {
        api: `/api/databaseTables`,
        method: 'GET',
        mode: 'cors',
      };
      const { data } = await request(options);
      setList(data);
      setTableName(table || data.find((item) => item.endsWith('accounts-accounts')));
    })();
  }, [history]);

  useEffect(() => {
    if (!tableName) return;

    (async () => {
      const queryParams = queryString.parse(history.location.search);
      queryParams['table'] = tableName;

      history.push({
        search: queryString.stringify(queryParams),
      });

      const options = {
        api: `/api/database/${tableName}/details`,
        method: 'GET',
        mode: 'cors',
      };
      const { data: { schema, info, indexes, attributes } } = await request(options);
      setQueryIndex(indexes[0].name);
      setIndexes(indexes);
      setInfo(info);
      setSchema(schema);
      setAttributes(attributes);

      // // preload some data
      // await sendQueryRequest();
    })();
  }, [tableName, history]);

  useEffect(() => {
    queryFields.forEach((item) => item.remove());

    if (queryIndex !== 'scan') {
      const indexItem = indexes.find((item) => item.name === queryIndex);
      if (indexItem) {
        addQueryField(indexItem['hash'], 'hash');
        if (indexItem['range']) {
          addQueryField(indexItem['range'], 'range');
        }
      }
    }
  }, [queryIndex, indexes]);

  useEffect(() => {
    if (!tableName) return;

    const keyExpressions = [];
    const filterExpressions = [];
    const filterExpressionsJoins = [];

    const queryParams = {
      TableName: tableName,
      IndexName: '',
      KeyConditionExpression: '',
      FilterExpression: '',
      ExpressionAttributeNames: {},
      ExpressionAttributeValues: {},
      ReturnConsumedCapacity: 'TOTAL', // INDEXES
      ScanIndexForward: queryScanIndexForward,
      Limit: queryLimit,
    };

    if (queryIndex && queryIndex !== 'scan' && queryIndex !== 'query (partitionKey)') {
      queryParams.IndexName = queryIndex;
    } else
    if (queryIndex && queryIndex === 'query (partitionKey)') {
      delete queryParams.IndexName;
    } else {
      delete queryParams.IndexName;
      delete queryParams.KeyConditionExpression;
    }

    // const hasFilters = queryFields.find((item) => item.category === 'filter' && isValidValue(item.value));

    queryFields.forEach((field) => {
      const { attribute, comparator, value, category, type, queryJoin } = field;

      if (!isValidValue(value)) {
        return;
      }

      let formatValue;

      switch (type) {
      case 'number':
        formatValue = parseInt(value);
        break;
      case 'boolean':
        formatValue = (value === 'true') ? true : false;
        break;
      case 'date':
      case 'datetime':
      case 'datetime-local':
        formatValue = moment(value).utc();
        break;
      case 'string':
      default:
        formatValue = value;
        break;
      }

      let expression;
      let keys;

      const sanitizedAttribute = attribute
        .replace(/\[/g, '_')
        .replace(/\]\./g, '_')
        .replace(/\./g, '_');

      const ATTRIBUTE_NAMES = [];
      const ATTRIBUTE_KEY = `:${sanitizedAttribute}`;

      let ATTRIBUTE_NAME;

      // ValidationException: ExpressionAttributeValues can only be specified when using expressions: FilterExpression is null
      if (queryUseExpressionAttributeNames) { // hasFilters
        attribute.split('.').forEach((attributeSubkey) => {
          const sanitizedAttributeSubkey = attributeSubkey.split('[')[0];
          queryParams.ExpressionAttributeNames[`#${sanitizedAttributeSubkey}`] = sanitizedAttributeSubkey;
          ATTRIBUTE_NAMES.push(`#${attributeSubkey}`);
        });

        ATTRIBUTE_NAME = ATTRIBUTE_NAMES.join('.');
      } else {
        ATTRIBUTE_NAME = attribute;
        delete queryParams.ExpressionAttributeNames;
      }

      switch (comparator) {
      case 'attribute_exists':
      case 'attribute_not_exists':
        expression = `${comparator}(${ATTRIBUTE_NAME})`;
        break;
      case 'begins_with':
      case 'contains':
        expression = `${comparator}(${ATTRIBUTE_NAME}, ${ATTRIBUTE_KEY})`;
        queryParams.ExpressionAttributeValues[ATTRIBUTE_KEY] = formatValue;
        break;
      case 'IN':
        keys = [];
        formatValue.split(',').forEach((item, index) => {
          const key = `${ATTRIBUTE_KEY}${index}`;
          keys.push(key);
          queryParams.ExpressionAttributeValues[key] = item;
        });
        expression = `${ATTRIBUTE_NAME} IN (${keys.join(',')})`;
        break;
      case 'size =':
      case 'size <':
      case 'size <=':
      case 'size >':
      case 'size >=':
        expression = `${comparator.split(' ')[0]}(${ATTRIBUTE_NAME}) ${comparator.split(' ')[1]} ${ATTRIBUTE_KEY}`;
        queryParams.ExpressionAttributeValues[ATTRIBUTE_KEY] = parseInt(formatValue);
        break;
      default:
        expression = `${ATTRIBUTE_NAME} ${comparator} ${ATTRIBUTE_KEY}`;
        queryParams.ExpressionAttributeValues[ATTRIBUTE_KEY] = formatValue;
      }

      if (category === 'hash' || category === 'range') {
        keyExpressions.push(expression);
      } else {
        filterExpressions.push(expression);
        filterExpressionsJoins.push(queryJoin);
      }
    });

    if (keyExpressions.length > 0) {
      queryParams.KeyConditionExpression = keyExpressions.join(' AND ');
    } else {
      delete queryParams.KeyConditionExpression;
    }

    if (filterExpressions.length > 0) {
      queryParams.FilterExpression = filterExpressions.reduce((array, filter, index) => {
        if (index !== 0) {
          array.push(filterExpressionsJoins[index]);
        }
        array.push(filter);
        return array;
      }, []).join(' ');
    } else {
      delete queryParams.FilterExpression;
      // delete queryParams.ExpressionAttributeValues;
    }

    if (Object.keys(queryParams.ExpressionAttributeNames || {}).length === 0) {
      delete queryParams.ExpressionAttributeNames;
    }

    if (Object.keys(queryParams.ExpressionAttributeValues || {}).length === 0) {
      delete queryParams.ExpressionAttributeValues;
    }

    if (queryParams.Limit === 0) {
      delete queryParams.Limit;
    }

    setQueryParams(queryParams);
  }, [queryIndex, tableName, queryFields, queryLimit, queryScanIndexForward, queryUseExpressionAttributeNames]);

  const addQueryField = (inTargetAttribute, inCategory, inPosition, inDefaultValue) => {
    const attribute = inTargetAttribute ||
      (attributes.find((item) => !isAttributeSelected(item.key)) || {}).key;

    if (!attribute) {
      return;
    }

    const type = (attributes.find((item) => item.key === attribute) || {}).type || 'string';
    let defaultValue;
    switch (type) {
    case 'number':
      defaultValue = 0;
      break;
    case 'datetime-local':
      defaultValue = moment().format('YYYY-MM-DDTHH:MM:SS');
      break;
    case 'boolean':
      defaultValue = 'true';
      break;
    case 'string':
    default:
      defaultValue = '';
    }

    defaultValue = inDefaultValue || defaultValue;

    const fieldItem = {
      attribute,
      queryJoin: 'AND',
      comparator: comparators[0],
      value: defaultValue,
      category: inCategory || 'filter',
      type,
      required: inCategory && inCategory === 'hash' ? true : false,
      isValid: isValidValue(defaultValue),
      remove: () => {
        const matchedFieldIndex = queryFields.findIndex((item) => item.attribute === attribute);
        queryFields.splice(matchedFieldIndex, 1);
        setQueryFields([...queryFields]);
      },
      update: (key) => {
        return (event) => {
          if (key === 'attribute') {
            const matchedFieldIndex = queryFields.findIndex((item) => item.attribute === attribute);
            addQueryField(event.target.value, false, matchedFieldIndex);
          } else {
            const matchedField = queryFields.find((item) => item.attribute === attribute);
            matchedField[key] = event.target.value;

            if (key === 'comparator' && ['attribute_exists', 'attribute_not_exists'].includes(event.target.value)) {
              matchedField['value'] = 'N/A';
            }

            matchedField.isValid = isValidValue(matchedField['value']);
            setQueryFields([...queryFields]);
          }
        };
      },
    };

    if (inPosition !== void(0)) {
      queryFields.splice(inPosition, 1, fieldItem);
    } else {
      queryFields.push(fieldItem);
    }

    setQueryFields([...queryFields]);
  };

  const isAttributeSelected = (inAttributeName) => {
    return queryFields.find((item) => item.attribute === inAttributeName);
  };

  const renderQueryFields = () => {
    return (
      <Fragment>
        {
          queryFields.map((field, index) => {
            const { category, attribute, comparator, queryJoin, value, required, update, type } = field;
            return (
              <Grid container key={index}>
                <Grid item xs={1}>
                  <TextField
                    fullWidth
                    id={`${attribute}-category`}
                    label={`Category`}
                    value={category}
                    margin="normal"
                    disabled
                  />
                </Grid>
                <Grid item xs={1}>
                  <FormControl fullWidth className="select-align-container">
                    <InputLabel htmlFor={queryJoin}>Join</InputLabel>
                    <Select
                      native
                      value={queryJoin}
                      onChange={update('queryJoin')}
                      disabled={category === 'hash' || category === 'range'}
                      inputProps={{
                        name: queryJoin,
                        id: queryJoin,
                      }}>
                      {joins
                        .map((key)=>{
                          return (
                            <option
                              key={key}
                              value={key}>
                              {key}
                            </option>
                          );
                        })
                      }
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  {
                    schema &&
                    <FormControl fullWidth className="select-align-container">
                      <InputLabel htmlFor={attribute}>Attribute</InputLabel>
                      <Select
                        native
                        value={attribute}
                        disabled={required}
                        onChange={update('attribute')}
                        inputProps={{
                          name: attribute,
                          id: attribute,
                        }}>
                        {
                          attributes.map((item)=>{
                            return (
                              <option
                                key={item.key}
                                disabled={isAttributeSelected(item.key)}
                                value={item.key}>
                                {item.key}
                              </option>);
                          })
                        }
                      </Select>
                    </FormControl>
                  }
                </Grid>
                <Grid item xs={2}>
                  <FormControl fullWidth className="select-align-container">
                    <InputLabel htmlFor={comparator}>Comparator</InputLabel>
                    <Select
                      native
                      value={comparator}
                      onChange={update('comparator')}
                      disabled={category === 'hash'}
                      inputProps={{
                        name: comparator,
                        id: comparator,
                      }}>
                      {comparators
                        .map((key)=>{
                          return (
                            <option
                              key={key}
                              value={key}>
                              {key}
                            </option>
                          );
                        })
                      }
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <DebounceInput
                    debounceTimeout={300}
                    onChange={update('value')}
                    element={TextField}
                    fullWidth
                    id={`${attribute}-value`}
                    label={`Value (${type})`}
                    value={value}
                    margin="normal"
                    type={type}
                  />
                </Grid>
                <Grid item xs={1}>
                  {
                    category === 'filter' &&
                    <IconButton
                      style={{ marginTop: '20px' }}
                      size={'small'}
                      aria-label="Delete"
                      onClick={field.remove}>
                      <Clear />
                    </IconButton>
                  }
                </Grid>
              </Grid>
            );
          })
        }
      </Fragment>);
  };

  const sendQueryRequest = async (inQueryParams, getAll = false) => {
    setLoading(true);
    try {
      const options = {
        api: `/api/database/query`,
        query: {
          getAll,
        },
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify(inQueryParams || queryParams),
      };
      const { data } = await request(options);
      setData(data.result);
      setStats(data.stats);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const isValid = () => {
    return !queryFields.find((item) => {
      return (item.required) ? !item.isValid : false;
    });
  };

  return (
    <Fragment>
      <Grid container justifyContent="center" spacing={2} className={classes.queryContainer}>
        <Grid item xs={12} md={6}>
          <Paper className={classes.container}>
            <Grid container>
              <Grid item xs={12} md={12}>
                <FormControl fullWidth className="select-align-container">
                  <InputLabel htmlFor="table-name">Tables</InputLabel>
                  <Select
                    native
                    value={tableName}
                    onChange={(event)=> setTableName(event.target.value)}
                    inputProps={{
                      name: 'tableName',
                      id: 'table-name',
                    }}>
                    {list.map((listItem)=>{
                      return (<option key={listItem} value={listItem}>{listItem}</option>);
                    })}
                  </Select>
                </FormControl>
                {indexes &&
                <FormControl fullWidth className="select-align-container">
                  <InputLabel htmlFor="table-index">Index</InputLabel>
                  <Select
                    native
                    value={queryIndex}
                    onChange={(event) => setQueryIndex(event.target.value)}
                    inputProps={{
                      name: 'queryIndex',
                      id: 'table-index',
                    }}>
                    {indexes.map((listItem)=>{
                      return (<option key={listItem.name} value={listItem.name}>{listItem.name}</option>);
                    })}
                  </Select>
                </FormControl>}
              </Grid>
            </Grid>

            {schema && renderQueryFields()}

            <Grid item xs={12} align="center">
              <Button size="small" fullWidth disabled={loading} onClick={addQueryField}>
                + Add New Query Filter +
              </Button>
            </Grid>

            <Grid item xs={12}>
              <DebounceInput
                debounceTimeout={300}
                onChange={(event)=>{
                  setQueryLimit(event.target.value);
                }}
                element={TextField}
                fullWidth
                id={`query-limit-value`}
                label={`Limit (Use 0 for no Limit)`}
                value={queryLimit}
                margin="normal"
                type={'number'}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                className="select-align-container"
                control={
                  <Switch
                    checked={queryScanIndexForward}
                    onChange={(event)=>{
                      setQueryScanIndexForward(event.target.checked);
                    }}
                    value="queryScanIndexForward"
                  />
                }
                label={`ScanIndexForward (${queryScanIndexForward?'ascending':'desending'})`}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                className="select-align-container"
                control={
                  <Switch
                    checked={queryUseExpressionAttributeNames}
                    onChange={(event)=> setQueryUseExpressionAttributeNames(event.target.checked)}
                    value="queryUseExpressionAttributeNames"
                  />
                }
                label={`Use ExpressionAttributeNames (avoid reserved words for filters)`}
              />
            </Grid>

            <Grid item xs={12}>
              <Button variant="contained" color="primary" size="small" disabled={loading || !isValid()} onClick={()=>sendQueryRequest()}>
                {queryIndex === 'scan'? 'Scan' : 'Query'}
              </Button>
              {' '}
              <Button variant="outlined" color="primary" size="small" disabled={loading || !isValid()} onClick={()=>sendQueryRequest(undefined, true)}>
                {queryIndex === 'scan'? 'Scan All' : 'Query All'}
              </Button>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper className={classes.container}>
            <Button size="small" fullWidth onClick={() => setShowInfo(!showInfo)}>
              DB Info
              <div className="expand"></div>
              { showInfo ? <ExpandLess/> : <ExpandMore/> }
            </Button>
            {showInfo && <pre>
              {JSON.stringify(info, null, 2)}
            </pre>}

            <hr/>

            <Button size="small" fullWidth onClick={()=>setShowSchema(!showSchema)}>
              Schema
              <div className="expand"></div>
              { showSchema ? <ExpandLess/> : <ExpandMore/> }
            </Button>
            {showSchema && <pre>
              {JSON.stringify(schema, null, 2)}
            </pre>}

            <hr/>

            <Button size="small" fullWidth onClick={()=>setShowQueryParams(!showQueryParams)}>
              Query Params
              <div className="expand"></div>
              { showQueryParams ? <ExpandLess/> : <ExpandMore/> }
            </Button>
            {showQueryParams && <pre>
              {JSON.stringify(queryParams, null, 2)}
            </pre>}

            <hr/>

            <Button size="small" fullWidth onClick={()=>setShowStats(!showStats)}>
              Query Stats
              <div className="expand"></div>
              { showStats ? <ExpandLess/> : <ExpandMore/> }
            </Button>
            {showStats && <pre>
              {JSON.stringify(stats, null, 2)}
            </pre>}
          </Paper>
        </Grid>
      </Grid>

      { data &&
        <ResultTable
          title={`${tableName} (Count: ${stats.Count} / Scanned: ${stats.ScannedCount} / Total: ${info.count})`}
          data={data}
          nested={true}
        /> }
    </Fragment>);
}

Database.propTypes = {};
