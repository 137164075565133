import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormGroup from '@material-ui/core/FormGroup';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import RichTextEditor from 'react-rte';
import Button from '@material-ui/core/Button';
import { Element } from 'react-scroll';
import { utils, writeFile } from 'xlsx';
import { DebounceInput } from 'react-debounce-input';

import moment from 'moment';
import {
  request,
  formatTime,
  diffTime,
  formatCurrency,
  sortBy,
} from '../utils';
import FailedAssetTransactionHandler from './FailedAssetTransactionHandler';
import toolbarConfig from './rteToolbarConfig';

import StatusBadge from './StatusBadge';
import './FailedAssetTransactionCase.css';

const OFFSET_HEIGHT = '150px';
const useStyles = makeStyles((theme) => ({
  listContainer: {
    overflow: 'auto',
    height: `calc(100vh - ${OFFSET_HEIGHT})`,
  },
  selected: {
    backgroundColor: '#f5f5f5',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 80,
  },
  formControlWide: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  inline: {
    display: 'inline',
    paddingRight: 10,
    flex: 1,
  },
}));

export default function FailedAssetTransactionCase({
  id: inId,
  onUpdate,
}) {
  const classes = useStyles();

  const [caseItem, setCaseItem] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [selectedTransaction, setSelectedTransaction] = useState(undefined);
  const [relatedTransactions, setRelatedTransactions] = useState([]);
  const [caseStatusOptions, setCaseStatusOptions] = useState([]);
  const [lastUpdatedAt, setLastUpdatedAt] = useState(Date.now());
  const [worknoteContent, setWorknoteContent] = useState(RichTextEditor.createEmptyValue());

  const handleCaseUpdate = async (props = {}) => {
    const options = {
      api: `/api/failedTransactionCase/${caseItem.id}`,
      method: 'PUT',
      mode: 'cors',
      body: JSON.stringify(props),
    };
    await request(options);

    Object.assign(caseItem, props);

    if (onUpdate) {
      onUpdate(caseItem);
    }
  };

  const triggerDownload = async () => {
    setIsLoading(true);

    const data = (await Promise.all(caseItem.assetTransactions.map(async (tx) => {
      const options = {
        api: `/api/failedTransactionVehicles/${tx.tx_license_no}`,
        method: 'GET',
        mode: 'cors',
      };
      const { data: vehicles } = await request(options);
      const mapping = {};
      vehicles
        .sort(sortBy('registerDate'))
        .forEach((item) => {
          const { accountId, registerDate, vehicleLpnState, vehicleLpn, vehicleState } = item;
          mapping[accountId] = mapping[accountId] || {
            accountId,
            vehicleLpnState,
            vehicleLpn,
            vehicleState,
            registerDate,
            records: [],
          };

          mapping[accountId].registerDate = registerDate;
          mapping[accountId].records.push(item);
        });

      Object.keys(mapping).map((key, index) => {
        const { accountId, registerDate, records } = mapping[key];

        tx[`Candidate ${index+1}`] = `Account ID: ${accountId}\nLatest Registration Date: ${registerDate}\n` +
          records.map(({ activeStatus, blockedStatus, registerDate }) => {
            if (activeStatus === 1 && blockedStatus === 0) {
              return ` - ADD @ ${registerDate}`;
            }
            if (activeStatus === 0 && blockedStatus === 0) {
              return ` - DELETE @ ${registerDate}`;
            }
            if (blockedStatus === 1) {
              return ` - BLOCKED @ ${registerDate}`;
            }
            return `${activeStatus} @ ${registerDate}`;
          }).join('\n');
      });

      return tx;
    })));

    const rows = data
      .sort(sortBy('tx_exit_datetime'))
      .sort(sortBy('tx_license_no'))
      .sort(sortBy('vendor'));

    const truncate = (string, length, end = '...') => {
      return string.length < length ? string : string.substring(0, length) + end;
    };

    const worksheet = utils.json_to_sheet(rows);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, truncate(`${caseItem.name || ''} (${caseItem.agency})`, 27));
    writeFile(workbook, `Failed Asset Transaction Case ${caseItem.name || ''} (${caseItem.agency}).xlsx`);

    setIsLoading(false);
  };

  useEffect(() => {
    (async () => {
      const { data: statusOptions } = await request({
        api: '/api/failedTransactionCasesStatusOptions',
        method: 'GET',
        mode: 'cors',
      });

      setCaseStatusOptions(statusOptions);
    })();
  }, []);

  const loadCase = useCallback(async (id) => {
    const options = {
      api: `/api/failedTransactionCase/${id}`,
      method: 'GET',
      mode: 'cors',
    };
    const { data } = await request(options);

    return data;
  }, []);

  useEffect(() => {
    (async () => {
      const params = new URLSearchParams(window.location.search);
      const id = inId || params.get('id');
      setIsLoading(true);

      if (id) {
        const caseData = await loadCase(id);
        setCaseItem(caseData);

        setSelectedTransaction(caseData.assetTransactions[0]);
      } else {
        setCaseItem(null);
      }

      setIsLoading(false);
    })();
  }, [inId, loadCase, lastUpdatedAt]);

  useEffect(() => {
    if (selectedTransaction && selectedTransaction.tx_serial_no) {
      const {
        asset_id: assetId,
        tx_serial_no: txSerialNo,
        tx_entry_datetime: txEntryDatetime,
        tx_license_no: txLicenseNo,
      } = selectedTransaction;
      const from = moment(txEntryDatetime).add(-2, 'hours').toISOString();
      const to = moment(txEntryDatetime).add(2, 'hours').toISOString();

      const relatedTransactions = caseItem.assetTransactions
        .filter((x) => {
          return x.tx_serial_no !== txSerialNo &&
            x.asset_id === assetId &&
            x.tx_license_no === txLicenseNo &&
            (x.tx_entry_datetime >= from && x.tx_entry_datetime <= to);
        })
        .sort(sortBy('tx_entry_datetime'));

      setRelatedTransactions(relatedTransactions);
    }
  }, [selectedTransaction, caseItem]);

  if (!caseItem) return null;

  return (
    <Grid container spacing={2} className="main-container">
      <Grid item md={12}>
        <Paper style={{ padding: 8 }}>
          <FormGroup row>
            <FormControl className={classes.formControl}>
              <InputLabel>Case Status</InputLabel>
              <Select
                native
                value={caseItem.status}
                onChange={(event) => handleCaseUpdate({
                  status: event.target.value,
                })}
              >
                {caseStatusOptions.map((item)=>{
                  return (<option key={item} value={item}>{item}</option>);
                })}
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <DebounceInput
                debounceTimeout={1000}
                onChange={(event) => handleCaseUpdate({
                  name: event.target.value,
                })}
                element={TextField}
                id={`name`}
                label="Name"
                value={caseItem.name}
                type={'text'}
                placeholder="name"
                // variant="outlined"
                // margin="dense"
                disabled={isLoading}
              />
            </FormControl>
            <FormControl className={classes.formControlWide}>
              <DebounceInput
                debounceTimeout={1000}
                onChange={(event) => handleCaseUpdate({
                  description: event.target.value,
                })}
                element={TextField}
                id={`description`}
                label="Description"
                value={caseItem.description}
                type={'text'}
                placeholder="description"
                disabled={isLoading}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <DebounceInput
                debounceTimeout={1000}
                onChange={(event) => handleCaseUpdate({
                  agency: event.target.value,
                })}
                element={TextField}
                id={`agency`}
                label="Agency"
                value={caseItem.agency}
                type={'text'}
                placeholder="agency"
                // variant="outlined"
                // margin="dense"
                disabled={isLoading}
              />
            </FormControl>
            <FormControl className={classes.formControlWide}>
              <TextField
                readOnly={true}
                label="Created At"
                value={`${formatTime(caseItem.createdAt)} (${diffTime(caseItem.createdAt)})`}
              />
            </FormControl>
            <FormControl className={classes.formControlWide}>
              <TextField
                readOnly={true}
                label="Updated At"
                value={`${formatTime(caseItem.updatedAt)} (${diffTime(caseItem.updatedAt)})`}
              />
            </FormControl>
            <FormControl className={classes.formControl} style={{ paddingTop: 12 }}>
              <Button
                variant="outlined"
                onClick={triggerDownload}
                disabled={isLoading}
              >
                Download Report
              </Button>
            </FormControl>
          </FormGroup>
        </Paper>
      </Grid>
      <Grid item md={12} container spacing={2}>
        <Grid item md={2}>
          <Paper className={classes.listContainer}>
            <List className={classes.root}>
              {isLoading && Array.from(new Array(10)).map((item, index)=>(
                <ListItem key={index} divider button>
                  <ListItemText
                    primary={<Skeleton animation="wave" />}
                    secondary={<Skeleton animation="wave" width="70%" />}
                  />
                </ListItem>
              ))}
              {caseItem.assetTransactions.map((item, index)=>(
                <ListItem
                  key={index}
                  button
                  divider
                  alignItems="flex-start"
                  onClick={() => {
                    console.log(item);
                    setSelectedTransaction(item);
                  }}
                  disabled={isLoading}
                  selected={(selectedTransaction || {}).tx_serial_no === item.tx_serial_no}
                >
                  <ListItemText
                    primary={<span style={{ display: 'flex' }}>
                      <Typography
                        component="span"
                        variant="body1"
                        className={classes.inline}
                        color="textPrimary"
                      >
                        {caseItem.assetTransactions.length - index}. {item.tx_license_no}
                      </Typography>
                      <StatusBadge status={item.tx_status} />
                    </span>}
                    secondary={
                      <span style={{ display: 'flex' }}>
                        <Typography
                          component="span"
                          variant="body2"
                          className={classes.inline}
                        >
                          [{item.vendor}] {item.asset_id} {formatCurrency(item.tx_toll_amount)}
                        </Typography>
                        {diffTime(item.tx_entry_datetime)}
                      </span>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </Paper>
        </Grid>
        <Grid item md={7}>
          {selectedTransaction &&
            <FailedAssetTransactionHandler
              containerStyle={{
                overflow: 'auto',
                height: `calc(100vh - ${OFFSET_HEIGHT})`,
              }}
              transaction={selectedTransaction}
              relatedTransactions={relatedTransactions}
              onUpdate={() => {
                setLastUpdatedAt(Date.now());
              }}
            />}
        </Grid>
        <Grid item md={3}>
          <Element
            id="worknotes-container"
            className="worknotes-container"
            style={{ height: '250px' }}
          >
            {
              caseItem.workNotes.map(({ content, createdBy, createdAt }, index)=>(
                <Card
                  key={index}
                  className={`worknote-message ${createdBy === window.localStorage.getItem('name') ? 'message-to':'message-from'}`}
                >
                  <CardContent>
                    {/* {content} */}
                    <div dangerouslySetInnerHTML={{ __html: content }} >
                    </div>
                    <Divider className="message-divider"/>
                    <span className="message-subcontent">
                      <span style={{ flex: 1 }}>{createdBy}</span>
                      {formatTime(createdAt)} ({diffTime(createdAt)})
                    </span>
                  </CardContent>
                </Card>
              ))
            }
          </Element>
          <div className="text-editor-card">
            <RichTextEditor
              className="worknote-text-editor"
              toolbarConfig={toolbarConfig}
              value={worknoteContent}
              onChange={(value)=>{
                setWorknoteContent(value);
              }}
            />
            <div className="text-editor-action">
              <Button
                color="secondary"
                onClick={() => setWorknoteContent(RichTextEditor.createEmptyValue())}
                disabled={isLoading || worknoteContent.length === 0}>
                Clear
              </Button>
              <Button
                color="primary"
                onClick={async () => {
                  const updateWorkNotes = [...caseItem.workNotes, {
                    createdBy: window.localStorage.getItem('name'),
                    createdAt: new Date().toISOString(),
                    content: worknoteContent.toString('html'),
                  }];
                  await handleCaseUpdate({
                    workNotes: updateWorkNotes,
                  });

                  setWorknoteContent(RichTextEditor.createEmptyValue());
                }}
                disabled={isLoading || worknoteContent.length < 1}>
                Save
              </Button>
            </div>
          </div>
        </Grid>
      </Grid>
    </Grid>);
}

FailedAssetTransactionCase.propTypes = {
  id: PropTypes.string,
  onUpdate: PropTypes.func,
  messageContainerHeight: PropTypes.string,
};
