import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';

import Table from './Table';
import {
  tableCellTimeRenderer,
  tableCellStatusRenderer,
  tableCellAccountIdRenderer,
  sortBy,
  request,
} from '../utils';

import VehicleChangesTable from './VehicleChangesTable';
import VehicleExterrnalStatusTable from './VehicleExterrnalStatusTable';
import VehicleExternalVdotTable from './VehicleExternalVdotTable';

const SetVehicleBlockStatusButton = ({ accountId, vehicleId, blockedStatus, onUpdate }) => {
  const [isLoading, setIsLoading] = useState(false);

  const isSetToBlock = blockedStatus === 0 ? true : false;

  const setBlockStatus = async () => {
    setIsLoading(true);
    try {
      const options = {
        api: `/api/setVehicleBlockStatus`,
        method: 'PUT',
        body: JSON.stringify({
          accountId,
          vehicleId,
          blockedStatus: isSetToBlock ? 1 : 0,
        }),
        mode: 'cors',
      };

      const result = await request(options);
      console.log(result);
      onUpdate();
    } catch (e) {
      console.log(e);
    }
    setIsLoading(false);
  };

  return (<Button
    variant="contained"
    color={isSetToBlock ? 'secondary' : 'primary'}
    size="small"
    onClick={setBlockStatus}
    disabled={isLoading}
  >
    {isSetToBlock ? 'Block' : 'Unblock'}
  </Button>);
};

SetVehicleBlockStatusButton.propTypes = {
  accountId: PropTypes.string,
  vehicleId: PropTypes.string,
  blockedStatus: PropTypes.number,
  onUpdate: PropTypes.func,
};

const VehicleTable = ({
  title = 'Vehicles',
  data: inData,
  accountId,
  onUpdate,
  onRefresh,
  nested,
}) => {
  const [vehicles, setVehicles] = useState([]);

  const load = async () => {
    const options = {
      api: `/api/account/${accountId}/vehicles`,
      method: 'GET',
      mode: 'cors',
    };
    const { data } = await request(options);
    setVehicles(data);
  };

  useEffect(() => {
    if (inData) {
      setVehicles(inData);
    } else {
      load();
    }
  }, [inData]);

  const mapping = {};
  vehicles
    .forEach((item) => {
      const key = `${item.accountId}__${item.vehicleLpnState}`;
      mapping[key] = mapping[key] || [];
      mapping[key].push(item);
    });

  const data = Object.keys(mapping)
    .map((key) => {
      // only return the latest one
      const latestVehicle = mapping[key].sort(sortBy('registerDate', true))[0];
      latestVehicle.records = mapping[key];
      return latestVehicle;
    })
    .sort(sortBy('activeStatus', true))
    .sort(sortBy('registerDate', true));

  return (
    <Grid item xs={12}>
      {renderTable(
        title,
        data,
        onRefresh,
        ()=>{
          load();
          onUpdate && onUpdate();
        },
        true,
        nested,
      )}
    </Grid>);
};

VehicleTable.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
  accountId: PropTypes.string,
  onUpdate: PropTypes.func,
  onRefresh: PropTypes.func,
  nested: PropTypes.bool,
};

export default VehicleTable;

const renderTable = (title, data, onRefresh, onUpdate, expandable = true, nested) => {
  const headers = [{
    name: 'accountId',
    label: 'Account ID',
    options: {
      filter: false,
      customBodyRender: tableCellAccountIdRenderer(),
    },
  }, {
    name: 'vehicleId',
    label: 'ID',
    options: {
      filter: false,
    },
  }, {
    name: 'vehicleLpnState',
    label: 'LPN State',
    options: {
      filter: false,
    },
  }, {
    name: 'vehicleLpnStateNormalized',
    label: 'LPN State (Normalized)',
    options: {
      filter: false,
    },
  }, {
    name: 'vehicleLpnDisplay',
    label: 'LPN State (Display)',
    options: {
      filter: false,
    },
  }, {
    name: 'nickname',
    label: 'Nickname',
    options: {
      filter: false,
    },
  }, {
    name: 'registerDate',
    label: 'Register Date',
    options: {
      filter: false,
      customBodyRender: tableCellTimeRenderer(true),
    },
  }, {
    name: 'activeStatus',
    label: 'Status',
    options: {
      customBodyRender: tableCellStatusRenderer(),
    },
  }, {
    name: 'blockedStatus',
    label: 'Blocked Status',
    options: {
      customBodyRender: tableCellStatusRenderer(),
    },
  }, {
    name: 'isRental',
    label: 'Rental',
    options: {
      customBodyRender: tableCellStatusRenderer(),
    },
  }, {
    name: 'autoRemovedAt',
    label: 'Auto Removed At',
    options: {
      filter: false,
      customBodyRender: tableCellTimeRenderer(true),
    },
  }, {
    name: 'vehicleId',
    label: ' ',
    options: {
      filter: false,
      customBodyRenderLite(dataIndex) {
        const { accountId, vehicleId, blockedStatus } = data[dataIndex];

        return <SetVehicleBlockStatusButton
          accountId={accountId}
          vehicleId={vehicleId}
          blockedStatus={blockedStatus}
          onUpdate={onUpdate}
        />;
      },
    },
  }];

  const onItemClick = (rowData, rowMeta) => {
    const item = data[rowMeta.dataIndex];
    console.log(item);
  };

  const options = {
    filterType: 'checkbox',
    onRowClick: onItemClick,
    rowsPerPageOptions: [10, 50, 100],
    rowsPerPage: 10,
    expandableRows: expandable,
    renderExpandableRow(rowData, rowMeta) {
      const item = data[rowMeta.dataIndex];
      return (
        <TableRow>
          <TableCell colSpan={headers.length + 1} className="nested-table-container">
            {renderTable('Vehicle History', item.records, undefined, false, true)}
            <br/>
            <VehicleChangesTable vehicles={item.records} nested={true}/>
            <br/>
            <VehicleExternalVdotTable vehicles={item.records} nested={true} />
            <br/>
            <VehicleExterrnalStatusTable lpnState={item.vehicleLpnState} nested={true}/>
          </TableCell>
        </TableRow>
      );
    },
  };

  return (
    <Table
      title={title}
      data={data}
      columns={headers}
      options={options}
      onRefresh={onRefresh}
      nested={nested}
    />);
};
