import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import Table from './Table';
import {
  tableCellTimeRenderer,
  tableCellStatusRenderer,
  tableCellProgramIdRenderer,
  tableRenderer,
  sortBy,
  request,
} from '../utils';
import Program from './Program';

export default class ProgramTable extends Component {
  state = {
    env: window.localStorage.getItem('env'),
    canEdit: true, // window.localStorage.getItem('env') !== 'prd',
    data: [],
  }

  componentDidMount() {
    this.load();
  }

  load() {
    if (this.props.programId) {
      return Promise.resolve()
        .then(() => {
          const options = {
            api: `/api/promotion/program/${this.props.programId}`,
            method: 'GET',
            mode: 'cors',
          };
          return request(options);
        })
        .then((res) => {
          this.setState({
            data: [res.data],
          });
        })
        .catch(console.log);
    }
  }

  handleOnUpdate = () => {
    if (this.props.onUpdate) {
      this.props.onUpdate();
    }
  }

  render() {
    const headers = [{
      name: 'id',
      label: 'ID',
      options: {
        filter: false,
        customBodyRender: tableCellProgramIdRenderer(),
      },
    }, {
      name: 'type',
      label: 'Type',
      options: {
        filter: true,
      },
    }, {
      name: 'title',
      label: 'Title',
      options: {
        filter: false,
      },
    }, {
      name: 'titleCustomer',
      label: 'Title Customer',
      options: {
        filter: false,
      },
    }, {
      name: 'description',
      label: 'Description',
      options: {
        filter: false,
        display: false,
      },
    }, {
      name: 'activeStatus',
      label: 'Status',
      options: {
        filter: true,
        customBodyRender: tableCellStatusRenderer({
          0: 2,
          1: 1,
        }),
      },
    }, {
      name: 'dateStart',
      label: 'Start',
      options: {
        filter: false,
        customBodyRender: tableCellTimeRenderer(true),
      },
    }, {
      name: 'dateEnd',
      label: 'End',
      options: {
        filter: false,
        customBodyRender: tableCellTimeRenderer(true),
      },
    }, {
      name: 'usage',
      label: 'Usage',
      options: {
        filter: false,
        customBodyRender: tableRenderer('center'),
      },
    }, {
      name: 'creditType',
      label: 'Type',
      options: {
        customBodyRender: tableRenderer('center'),
      },
    }, {
      name: 'creditValue',
      label: 'Value',
      options: {
        filter: false,
        customBodyRender: tableRenderer(),
      },
    }, {
      name: 'url',
      label: 'URL',
      options: {
        filter: false,
      },
    }, {
      name: 'firstTimeUserOnly',
      label: '1st Time User Only',
      options: {
        filter: true,
        customBodyRender: tableCellStatusRenderer({
          0: 2,
          1: 1,
        }),
      },
    }, {
      name: 'firstTimeRedeemOnly',
      label: '1st Time Redeem Only',
      options: {
        filter: true,
        customBodyRender: tableCellStatusRenderer({
          0: 2,
          1: 1,
        }),
      },
    }, {
      name: 'ignoreTripTime',
      label: 'Ignore Trip Time',
      options: {
        filter: true,
        customBodyRender: tableCellStatusRenderer({
          0: 2,
          1: 1,
        }),
      },
    }, {
      name: 'assetIds',
      label: 'Roadways',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value = []) => {
          return `${value.sort((a, b) => a > b ? 1 : -1).join(', ')}`;
        },
      },
    }, {
      name: 'itemUpdatedAt',
      label: 'Updated At',
      options: {
        filter: false,
        customBodyRender: tableCellTimeRenderer(true),
      },
    }, {
      name: 'itemCreatedAt',
      label: 'Created At',
      options: {
        filter: false,
        customBodyRender: tableCellTimeRenderer(true),
      },
    }, {
      name: 'id',
      label: 'View',
      options: {
        filter: false,
        customBodyRender: tableCellProgramIdRenderer('View'),
      },
    }];

    const data = (this.props.data || this.state.data);

    const onItemClick = (rowData, rowMeta) => {
      const item = data[rowMeta.dataIndex];
      console.log(item);
    };

    const options = {
      filterType: 'checkbox',
      onRowClick: onItemClick,
      rowsPerPageOptions: [10, 50, 100],
      rowsPerPage: 10,
      expandableRows: true,
      renderExpandableRow: (rowData, rowMeta) => {
        console.log('renderExpandableRow', rowData, rowMeta);
        const item = data[rowMeta.dataIndex];

        return (
          <TableRow>
            <TableCell colSpan={headers.length + 1} className="nested-table-container">
              <Program data={item} onUpdate={this.handleOnUpdate} />
            </TableCell>
          </TableRow>
        );
      },
    };

    data
      .sort(sortBy('itemCreatedAt', true))
      .sort(sortBy('itemUpdatedAt', true))
      .map((item) => {
        item.usage = `${item.countUses}/${item.usesMax}`;
        return item;
      });

    return (
      <Grid item xs={12}>
        <Table
          title={this.props.title || 'Programs'}
          data={data}
          columns={headers}
          options={options}
          nested={this.props.nested}
          maxHeight={this.props.maxHeight}
        />
      </Grid>);
  }
}
