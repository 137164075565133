// https://learnui.design/tools/data-color-picker.html#divergent

const bar = [
  '#102063',
  '#0f4142',
  '#216a49',
  '#00876c',
  '#3d9c73',
  '#63b179',
  '#88c580',
  '#aed987',
  // '#d6ec91',
  // '#ffff9d',
  // '#fee17e',
  '#fcc267',
  '#f7a258',
  '#ef8250',
  '#e4604e',
  '#d43d51',
  '#b22457',
  '#91129c',
  '#650f90',
  '#422ce2',
];

// https://www.schemecolor.com/flat-orange-blue-green-pie-chart.php
const pie = [
  '#2D87BB',
  '#F66D44',
  '#10adac',
  '#FEAE65',
  '#AADEA7',
  '#cc5960',
  '#bcd42c',
  '#7a4abe',
  '#216a49',
];

const custom = [
  '#2D87BB',
  '#F66D44',
  '#216a49',
  '#10adac',
  '#bcd42c',
  '#f7e204',
  '#cc5960',
  '#9270be',
  '#51ecc5',
  '#422ce2',
  '#54a506',
  '#87b0a8',
  '#7a4abe',
  '#79b0a4',
  '#c1fe94',
  '#df06ac',
  '#bcd42c',
  '#9cdae7',
  '#10adac',
  '#216a49',
  '#8cd0f2',
  '#cce0ff',
  '#b7bcb5',
  '#927b3f',
  '#650f10',
  '#7f711c',
  '#57bf23',
  '#3f804f',
  '#3cbfa0',
  '#91129c',
  '#df06ac',
  '#b22457',
  '#96fce9',
  '#f7e204',
];

export default { bar, pie, custom };
