import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

const Error = ({ description = '' }) => {
  useEffect(() => {
    window.localStorage.removeItem('accessToken');
    window.localStorage.removeItem('accessTokenType');
    window.localStorage.removeItem('accessTokenExpiredIn');
    window.localStorage.removeItem('idToken');
  }, []);

  return (
    <Grid container className="main" justify="center">
      <Grid item>
        <h1>{description || 'User is not assigned to this application.'}</h1>
      </Grid>
    </Grid>);
};

Error.propTypes = {
  description: PropTypes.string,
};

export default Error;
