import React, { Component, Fragment } from 'react';
import Grid from '@material-ui/core/Grid';

import Table from '../components/Table';

import {
  request,
  sortBy,
  tableCellStatusRenderer,
} from '../utils';

export default class DatadogMonitors extends Component {
  state = {
    env: window.localStorage.getItem('env'),
    loading: false,
    monitors: [],
  }

  componentDidMount = async () => {
    await this.load();
  }

  load = async () => {
    try {
      const options = {
        api: `/api/datadog/monitors`,
        query: {
          env: this.state.env,
        },
        method: 'GET',
        mode: 'cors',
      };
      const res = await request(options);
      this.setState({ monitors: res.data });
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    return (
      <Fragment>
        {this.renderTable('Datadog Monitors', this.state.monitors)}
      </Fragment>);
  }

  renderTable(inTitle, inData) {
    const headers = [{
      name: 'name',
      label: 'Name',
      options: {
        filter: false,
      },
    }, {
      name: 'env',
      label: 'Env',
    }, {
      name: 'level',
      label: 'Level',
    }, {
      name: 'state',
      label: 'State',
      options: {
        customBodyRender: tableCellStatusRenderer({
          'No Data': 0,
          'OK': 1,
          'Warn': 2,
          'Alert': 2,
        }),
      },
    }, {
      name: 'status',
      label: 'Status',
      options: {
        customBodyRender: tableCellStatusRenderer({
          Up: 1,
          Down: 2,
        }),
      },
    }];

    const data = inData
      .sort(sortBy('name'))
      .sort(sortBy('level'));

    const onItemClick = (rowData, rowMeta) => {
      const item = data[rowMeta.dataIndex];
      console.log(item);
    };

    const options = {
      filterType: 'checkbox',
      onRowClick: onItemClick,
      rowsPerPageOptions: [100, 200, 500],
      rowsPerPage: 100,
    };

    return (
      <Grid item xs={12}>
        <Table
          title={inTitle}
          data={data}
          columns={headers}
          options={options}
        />
      </Grid>);
  }
}
