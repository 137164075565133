import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { RedocStandalone } from 'redoc';
import queryString from 'query-string';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';

const menu = [
  'account',
  'asset',
  'communication',
  'config',
  'external-vdot',
  'history',
  'payment',
  'promotion',
  'static-module',
  'trip',
  'vehicle',
];

const useStyles = makeStyles((theme) => ({
  container: {
    margin: '-16px -16px 0px -16px',
  },
  moduleSelector: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: 228,
    padding: 16,
    backgroundColor: '#fafafa',
    zIndex: 10,
  },
}));

const ApiDocumentation = () => {
  const history = useHistory();
  const classes = useStyles();

  const [url, setUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [moduleName, setModuleName] = useState(undefined);

  useEffect(() => {
    if (!moduleName) return;

    setLoading(true);

    history.push({
      search: `?moduleName=${moduleName}`,
    });

    const env = window.localStorage.getItem('env');
    const token = window.localStorage.getItem('accessToken');
    setUrl(`/api/apiDocumentations/${env}.json?access_token=${token}&moduleName=${moduleName}`);

    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, [history, moduleName]);

  useEffect(() => {
    const params = queryString.parse(history.location.search);
    if (params.moduleName) {
      setModuleName(params.moduleName);
    } else {
      setModuleName('account');
    }
  }, [history]);

  if (!url) return null;

  return (
    <div className={classes.container}>
      <div className={classes.moduleSelector}>
        <FormControl fullWidth>
          <Select
            native
            value={moduleName}
            onChange={(event)=>{
              setModuleName(event.target.value);
            }}
            inputProps={{
              name: 'moduleName',
              id: 'module-name',
            }}
            disabled={loading}
          >
            {menu.map((listItem)=>{
              return (
                <option
                  key={listItem}
                  value={listItem}
                  disabled={loading}
                >
                  {listItem}
                </option>
              );
            })}
          </Select>
        </FormControl>
      </div>
      <RedocStandalone
        options={{
          scrollYOffset: 40,
          // hideHostname: true,
          requiredPropsFirst: true,
          showExtensions: true,
          expandResponses: 'all',
          jsonSampleExpandLevel: 'all',
          sortPropsAlphabetically: true,
          pathInMiddlePanel: true,
        }}
        specUrl={url}/>
    </div>
  );
};

export default ApiDocumentation;
