import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';

import Table from './Table';
import {
  request,
  sortBy,
  tableCellTimeRenderer,
  tableCellAccountIdRenderer,
} from '../utils';

export default class VehicleChangesTable extends Component {
  state = {
    data: null,
  }

  componentDidMount() {
    this.load();
  }

  load = () => {
    if (this.props.data) return;
    if (!this.props.vehicles) return;

    return Promise.resolve()
      .then(() => {
        const getDataPromises = this.props.vehicles.map((vehicle) => {
          const options = {
            api: `/api/vehicle/${vehicle.vehicleId}/records`,
            method: 'GET',
            mode: 'cors',
          };
          return request(options);
        });

        return Promise.all(getDataPromises);
      })
      .then((result) => {
        this.setState({
          data: result.reduce((data, res) => {
            return [...data, ...res.data];
          }, []),
        });
        console.log(this.state);
      })
      .catch(console.log);
  }

  render() {
    const headers = [{
      name: 'vendor',
      label: 'Vendor',
    }, {
      name: 'vendorAccount',
      label: 'Account',
    }, {
      name: 'vehicleId',
      label: 'Vehicle ID',
      options: {
        filter: false,
      },
    }, {
      name: 'changeId',
      label: 'Change ID',
      options: {
        filter: false,
      },
    }, {
      name: 'accountId',
      label: 'Account ID',
      options: {
        filter: false,
        display: false,
        customBodyRender: tableCellAccountIdRenderer(),
      },
    }, {
      name: 'vehicleLpn',
      label: 'LPN',
    }, {
      name: 'vehicleState',
      label: 'State',
    }, {
      name: 'status',
      label: 'Status',
      options: {},
    }, {
      name: 'statusMsg',
      label: 'Msg',
      options: {
        filter: false,
      },
    }, {
      name: 'source',
      label: 'Source',
      options: {},
    }, {
      name: 'action',
      label: 'Action',
    }, {
      name: 'itemUpdatedAt',
      label: 'UpdatedAt',
      options: {
        filter: false,
        customBodyRender: tableCellTimeRenderer(true),
      },
    }, {
      name: 'createdAt',
      label: 'Created At',
      options: {
        filter: false,
        customBodyRender: tableCellTimeRenderer(true),
      },
    }];

    const onItemClick = (rowData, rowMeta) => {
      const item = (this.state.data || this.props.data)[rowMeta.dataIndex];
      console.log(item);
    };

    const options = {
      filterType: 'checkbox',
      onRowClick: onItemClick,
      rowsPerPageOptions: [10, 50, 100],
      rowsPerPage: 10,
    };

    const data = (this.state.data || this.props.data || [])
      .sort(sortBy('createdAt', true));

    return (
      <Grid item xs={12}>
        <Table
          title={this.props.title || 'Vehicle Change Records'}
          data={data}
          columns={headers}
          options={options}
          nested={this.props.nested}
        />
      </Grid>);
  }
}
