import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Refresh from '@material-ui/icons/Refresh';
import to from 'await-to-js';

import AccountTable from '../components/AccountTable';
import TripTable from '../components/TripTable';
import VehicleTable from '../components/VehicleTable';
import CardTable from '../components/CardTable';
import NotificationTable from '../components/NotificationTable';
import TransactionTable from '../components/TransactionTable';
import CaseTable from '../components/CaseTable';
import CreditTable from '../components/CreditTable';
import ReferralTable from '../components/ReferralTable';

import CreateTripModal from '../components/CreateTripModal';
import InternalAPIMenuButton from '../components/InternalAPIMenuButton';
import PurgeAccountModal from '../components/PurgeAccountModal';
import AddVehicleModal from '../components/AddVehicleModal';
import AccountMergeModal from '../components/AccountMergeModal';
import AccountNotificationToggle from '../components/AccountNotificationToggle';
import AccountStatus from '../components/AccountStatus';

import { request } from '../utils';

const menu = [{
  label: 'Account',
  key: 'account',
  // isDisabled: true,
}, {
  label: 'Vehicles',
  key: 'vehicle',
}, {
  label: 'Cards',
  key: 'card',
}, {
  label: 'Transactions',
  key: 'transaction',
}, {
  label: 'Trips',
  key: 'trip',
}, {
  label: 'Asset Trips',
  key: 'assetTrip',
}, {
  label: 'Notifications',
  key: 'notifications',
}, {
  label: 'Cases',
  key: 'cases',
}, {
  label: 'Credits',
  key: 'credits',
}, {
  label: 'Referrals',
  key: 'referrals',
}];

const defaultChecked = {
  account: true,
  vehicle: false,
  trip: true,
  assetTrip: true,
  card: false,
  transaction: false,
  notifications: false,
  cases: false,
  credits: false,
  referrals: false,
};

export default function AccountDetail({ match }) {
  const env = window.localStorage.getItem('env');
  const cacheChecked = window.localStorage.getItem('accountDetails-checkedMenu');
  const canEdit = env !== 'prd';

  const [checked, setChecked] = useState(cacheChecked ? JSON.parse(cacheChecked) : defaultChecked);
  const [loading, setLoading] = useState(false);
  const [accountId, setAccountId] = useState(null);
  const [account, setAccount] = useState({});

  const load = useCallback(async () => {
    setLoading(true);

    const options = {
      api: `/api/account/${accountId}`,
      method: 'GET',
      mode: 'cors',
    };

    const [error, res] = await to(request(options));

    setLoading(false);

    if (error) return global.logger.error(error);

    console.log(res);

    const { data } = res;
    setAccount(data);
    document.title = `Account: ${data.firstName} ${data.lastName} | GoToll Admin`;
  }, [accountId]);

  useEffect(() => {
    if (checked) {
      window.localStorage.setItem('accountDetails-checkedMenu', JSON.stringify(checked));
    }
  }, [checked]);

  useEffect(() => {
    if (accountId) {
      load();
    }
  }, [accountId, load]);

  useEffect(() => {
    if (match && match.params && match.params.id) {
      setAccountId(match.params.id);
    } else {
      const params = new URLSearchParams(window.location.search);
      const id = params.get('accountId');
      if (id) {
        setAccountId(id);
      }
    }
  }, [match]);

  if (!accountId) return null;

  return (
    <div style={{ padding: 16 }}>
      {canEdit &&
        <Grid container className="main" justify="center">
          <Grid item xs={12} sm={8}>
            <AddVehicleModal mode="GPS" accountId={accountId} onUpdate={load} />
            {/* <CreateTripModal mode="GPS" accountId={accountId} onUpdate={load} /> */}
            <CreateTripModal mode="Asset" accountId={accountId} onUpdate={load} />
            <InternalAPIMenuButton onUpdate={load} />
          </Grid>
          <Grid item xs={12} sm={4} align="right">
            <PurgeAccountModal account={account} accountId={accountId} onUpdate={load}/>
          </Grid>
        </Grid>
      }

      <Grid container className="main" justify="space-between" spacing={2}>
        <Grid item xs={12} sm={8}>
          <FormGroup row className="space">
            {
              menu.map((item, index)=>{
                const { key, isDisabled, label } = item;
                return (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        disabled={isDisabled}
                        checked={checked[key]}
                        onChange={()=>{
                          setChecked({
                            ...checked,
                            [key]: !checked[key],
                          });
                        }}
                        color="primary"
                      />
                    }
                    label={label}
                  />);
              })
            }
          </FormGroup>
        </Grid>
        <Grid item xs={12} sm={4} align="right">
          <AccountStatus accountId={accountId} accountStatus={(account || {}).status} onUpdate={load} />
          <AccountMergeModal fromAccountId={accountId} onUpdate={load}/>
          <AccountNotificationToggle accountId={accountId} silenceNotifications={(account || {}).silenceNotifications} onUpdate={load} />
          <Button className="space" variant="contained" color="primary" disabled={loading} onClick={load}>
            <Refresh /> Reload
          </Button>
        </Grid>
      </Grid>

      {!loading &&
      <Grid container direction="column" alignItems="space-between" justify="space-between" spacing={2}>
        {checked.account &&
          <AccountTable
            data={[account]}
            disabledNav={true}
            nested={true}
          />}
        {checked.vehicle && accountId &&
          <VehicleTable
            accountId={accountId}
            nested={true}
            onUpdate={load}
          />}
        {checked.card && accountId &&
          <CardTable
            accountId={accountId}
            nested={true}
          />}
        {checked.transaction && accountId &&
          <TransactionTable
            accountId={accountId}
            nested={true}
          />}
        {checked.trip && accountId &&
          <TripTable
            accountId={accountId}
            title="Trips"
            onUpdate={load}
            nested={true}
          />}
        {checked.assetTrip && accountId &&
          <TripTable
            target="assetTrips"
            accountId={accountId}
            title="New Asset Trips (map by vechicle.accounts)"
            onUpdate={load}
            nested={true}
          />}
        {checked.notifications && accountId &&
          <NotificationTable
            accountId={accountId}
            title="Notifications"
            nested={true}
          />}
        {checked.cases && accountId &&
          <CaseTable
            accountId={accountId}
            title="Cases"
            nested={true}
          />}
        {checked.credits && accountId &&
          <CreditTable
            accountId={accountId}
            title="Credits"
            nested={true}
          />}
        {checked.referrals && accountId &&
          <ReferralTable
            accountId={accountId}
            title="Referrals"
            nested={true}
          />}
      </Grid>}
    </div>);
}

AccountDetail.propTypes = {
  id: PropTypes.string,
  match: PropTypes.object,
};
