import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { ResponsiveBar } from '@nivo/bar';
import { computeXYScalesForSeries } from '@nivo/scales';
import { useTooltip, TableTooltip } from '@nivo/tooltip';
import { line } from 'd3-shape';
import { Axes } from '@nivo/axes';
import hexColors from '../../hexColors';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    boxShadow: 'none',
  },
  value: {
    margin: theme.spacing(1),
  },
}));

const barColor = hexColors.custom[0];
const lineColor = hexColors.custom[1];

// https://codesandbox.io/s/nivo-composed-bar-with-line-j6qce5?file=/src/index.js
export default function BarLineCard({
  title,
  data = [],
  height = 480,
  barName,
  lineName,
  margin = { top: 50, right: 130, bottom: 50, left: 60 },
}) {
  const classes = useStyles();

  const Line = (options) => {
    const { bars, xScale, innerWidth, innerHeight } = options;
    const scale = computeXYScalesForSeries(
      [
        {
          id: 'only',
          data: data.map((it) => ({ x: it.d, y: it.lv1 })),
        },
      ], { type: 'linear' }, { type: 'linear' },
      innerWidth,
      innerHeight,
    );

    const lineGenerator = line()
      .x((bar) => bar.x + bar.width / 2)
      .y((bar) => scale.yScale(bar.data.data.lv1));

    const tip = useTooltip();

    function renderTip(e, idx) {
      return tip.showTooltipFromEvent(
        <CustomTooltip barValue={data[idx].v1} lineValue={data[idx].lv1} />,
        e,
      );
    }

    return (
      <Fragment>
        <Axes
          yScale={scale.yScale}
          xScale={xScale}
          width={innerWidth}
          height={innerHeight}
          right={{
            ticksPosition: 'after',
            legend: lineName,
            legendPosition: 'middle',
            legendOffset: 50,
          }}
        />
        <path
          d={lineGenerator(bars)}
          fill='none'
          stroke={lineColor}
          style={{ pointerEvents: 'none' }}
        />
        {/* {bars.map((bar) => (
          <circle
            key={bar.key}
            cx={xScale(bar.data.index) + bar.width / 2}
            cy={scale.yScale(bar.data.data.lv1)}
            r={4}
            fill='white'
            stroke={lineColor}
            style={{ pointerEvents: 'none' }}
          />
        ))} */}
        {bars.map((bar, idx) => (
          <rect
            key={bar.key}
            x={bar.x}
            y={0}
            height={innerHeight}
            width={bar.width}
            fill='transparent'
            onMouseEnter={(e) => renderTip(e, idx)}
            onMouseMove={(e) => renderTip(e, idx)}
            onMouseLeave={tip.hideTooltip}
          />
        ))}
      </Fragment>
    );
  };

  Line.propTypes = {
    options: PropTypes.object.isRequired,
  };

  function CustomTooltip(options) {
    const { barValue, lineValue } = options;
    return (
      <TableTooltip
        rows={[
          [barName, barValue],
          [lineName, lineValue],
        ]}
      />
    );
  }
  CustomTooltip.propTypes = {
    options: PropTypes.object,
  };

  return (
    <Paper className={classes.container}>
      <Typography variant="h5" component="h5">
        {title}
      </Typography>
      <Grid container align='center' justifyContent='center'>
        <div style={{ width: '100%', height }}>
          <ResponsiveBar
            data={data}
            keys={['v1']}
            padding={0.3}
            groupMode="stacked"
            margin={margin}
            indexBy="x"
            enableLabel={false}
            colors={[barColor]}
            borderRadius={2}
            axisLeft={{
              legend: barName,
              legendPosition: 'middle',
              legendOffset: -45,
            }}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: -90,
            }}
            enableGridY={false}
            layers={['grid', 'axes', 'bars', Line, 'markers', 'legends']}
          />
        </div>
      </Grid>
    </Paper>);
}

BarLineCard.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
  dataKeys: PropTypes.array,
  mode: PropTypes.string,
};
