import Dashboard from '@material-ui/icons/Dashboard';
import AccountBox from '@material-ui/icons/AccountBox';
import FindInPage from '@material-ui/icons/FindInPage';
import OfflineBolt from '@material-ui/icons/OfflineBolt';
import Flag from '@material-ui/icons/Flag';
import PinDrop from '@material-ui/icons/PinDrop';
import Notes from '@material-ui/icons/Notes';
import Link from '@material-ui/icons/Link';
import Alarm from '@material-ui/icons/Alarm';
import Storage from '@material-ui/icons/Storage';
import PersonalVideo from '@material-ui/icons/PersonalVideo';
import People from '@material-ui/icons/People';
import GridOn from '@material-ui/icons/GridOn';
import EmojiPeople from '@material-ui/icons/EmojiPeople';
// import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import DriveEta from '@material-ui/icons/DriveEta';
import SmsFailedIcon from '@material-ui/icons/SmsFailed';

import {
  FaDonate,
  FaUserLock,
  FaSolarPanel,
  FaAdn,
} from 'react-icons/fa';

import dashboard from './dashboard';
// import account from './account';
import accountDetail from './accountDetail';
import asset from './asset';
import postgres from './postgres';
import postman from './postman';
import roadModel from './roadModel';
import configuration from './configuration';
import ApiDocumentation from './apiDocumentation';
import Login from './auth/Login';
import CronJob from './cronJob';
import Database from './database';
import TripTableDetails from './components/TripTableDetails';
import Log from './log';
import Users from './users';
import Promotion from './promotion';
import Environment from './environment';
import Permission from './permission';
import csr from './csr';
import casePage from './csr/Case';
import AssetDetails from './asset/AssetDetails';
// import Vehicle from './vehicle';
import vendor from './vendor';
import FailedAssetTransactions from './FailedAssetTransactions';
import agency from './agency';
import Program from './components/Program';
import FailedAssetTransactionCase from './FailedAssetTransactions/FailedAssetTransactionCase';
import VehicleSearch from './vehicleSearch';
import ReportDashboard from './reportDashboard';
import accountSearch from './accountSearch';
// import Webform from './components/Webform';

export default [
  {
    label: 'Dashboard',
    icon: Dashboard,
    category: 'basic',
    component: dashboard,
    paths: [
      '/', '/dashboard', '/implicit/callback',
    ],
  },
  {
    label: 'Report',
    icon: FindInPage,
    category: 'basic',
    component: ReportDashboard,
    paths: [
      '/report',
    ],
  },
  // Deprecated too much records to load
  // {
  //   label: 'Accounts',
  //   icon: AccountBox,
  //   category: 'basic',
  //   component: account,
  //   paths: [
  //     '/account',
  //   ],
  // },
  {
    label: 'Accounts',
    icon: AccountBox,
    category: 'basic',
    component: accountSearch,
    paths: [
      '/accounts',
    ],
  },

  // Deprecated too much records to load
  // {
  //   label: 'Vehicles',
  //   icon: DriveEta,
  //   category: 'basic',
  //   component: Vehicle,
  //   paths: [
  //     '/vehicle',
  //   ],
  // },
  {
    label: 'Vehicles',
    icon: DriveEta,
    category: 'basic',
    component: VehicleSearch,
    paths: [
      '/vehicles',
    ],
  },
  {
    label: 'Account Details',
    icon: AccountBox,
    category: 'basic',
    component: accountDetail,
    paths: [
      '/accountDetail',
      '/account/:id',
    ],
    hideFromNavbar: true,
  },
  {
    label: 'Cases',
    icon: EmojiPeople,
    category: 'basic',
    component: csr,
    paths: [
      '/csr',
    ],
  },
  {
    label: 'Case Page',
    category: 'basic',
    component: casePage,
    paths: [
      '/case',
    ],
    hideFromNavbar: true,
  },
  {
    label: 'Assets',
    icon: Flag,
    category: 'basic',
    component: asset,
    paths: [
      '/asset',
    ],
  },
  {
    label: 'Failed Assets Transactions',
    icon: SmsFailedIcon,
    category: 'basic',
    component: FailedAssetTransactions,
    paths: [
      '/failedAssetTransactions',
    ],
  },
  {
    label: 'Failed Assets Transaction Case',
    category: 'basic',
    component: FailedAssetTransactionCase,
    paths: [
      '/failedAssetTransactionCase',
    ],
    hideFromNavbar: true,
  },
  {
    label: 'Vendors',
    icon: FaSolarPanel,
    category: 'basic',
    component: vendor,
    paths: [
      '/vendor',
    ],
  },
  {
    label: 'Agencies',
    icon: FaAdn,
    category: 'basic',
    component: agency,
    paths: [
      '/agency',
    ],
  },
  {
    label: 'Asset Details',
    component: AssetDetails,
    paths: [
      '/asset/:assetId',
    ],
    hideFromNavbar: true,
  },
  {
    label: 'Road Models',
    icon: PinDrop,
    category: 'basic',
    component: roadModel,
    paths: [
      '/roadModel',
    ],
  },
  {
    label: 'Promotion',
    icon: FaDonate,
    category: 'basic',
    component: Promotion,
    paths: [
      '/promotion',
    ],
  },
  {
    label: 'Program',
    icon: FaDonate,
    category: 'basic',
    component: Program,
    hideFromNavbar: true,
    paths: [
      '/program/:id',
    ],
  },
  {
    label: 'Environment',
    icon: GridOn,
    category: 'ops',
    component: Environment,
    // hideFromProduction: true,
    paths: [
      '/environment',
    ],
  },
  {
    label: 'Database',
    icon: Storage,
    category: 'ops',
    component: Database,
    paths: [
      '/database',
    ],
  },
  {
    label: 'API',
    icon: Link,
    category: 'dev',
    component: ApiDocumentation,
    paths: [
      '/apiDocumentation',
    ],
  },
  // }, {
  //   label: 'JS Doc',
  //   icon: Code,
  //   category: 'dev',
  //   component: jsDoc,
  //   hideFromProduction: true,
  //   paths: [
  //     '/jsDoc',
  //   ],
  // }, {
  //   label: 'CloudFormation',
  //   icon: CloudQueue,
  //   category: 'ops',
  //   component: cloudformation,
  //   paths: [
  //     '/cloudformation',
  //   ],
  {
    label: 'Config',
    icon: Notes,
    category: 'ops',
    component: configuration,
    paths: [
      '/configuration',
    ],
  },
  {
    label: 'Postgres',
    icon: FindInPage,
    category: 'dev',
    component: postgres,
    hideFromNavbar: true,
    paths: [
      '/postgres',
    ],
  },
  {
    label: 'Postman',
    icon: OfflineBolt,
    category: 'dev',
    component: postman,
    hideFromProduction: true,
    paths: [
      '/postman',
    ],
  },
  // }, {
  //   label: 'Webform',
  //   icon: ContactSupportIcon,
  //   category: 'dev',
  //   component: Webform,
  //   hideFromProduction: true,
  //   paths: [
  //     '/webform',
  //   ],
  {
    label: 'Login',
    category: 'dev',
    component: Login,
    paths: [
      '/login',
    ],
    hideFromNavbar: true,
  },
  {
    label: 'Crons',
    icon: Alarm,
    category: 'ops',
    component: CronJob,
    paths: [
      '/cronJob',
    ],
  },
  {
    label: 'Trip Details',
    component: TripTableDetails,
    paths: [
      '/trip/:tripId',
    ],
    hideFromNavbar: true,
  },
  {
    label: 'Log',
    icon: PersonalVideo,
    category: 'settings',
    component: Log,
    hideFromProduction: false,
    paths: [
      '/log',
    ],
  }, {
    label: 'Users',
    icon: People,
    category: 'settings',
    component: Users,
    paths: [
      '/users',
    ],
  },
  {
    label: 'Permission',
    icon: FaUserLock,
    category: 'settings',
    component: Permission,
    paths: [
      '/permission',
    ],
  },
];
