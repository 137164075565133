import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import RichTextEditor from 'react-rte';
import { useDropzone } from 'react-dropzone';
import prettyBytes from 'pretty-bytes';

import toolbarConfig from './rteToolbarConfig';

import './Case.css';

const DropZone = ({ setFiles }) => {
  const {
    acceptedFiles,
    // fileRejections,
    getRootProps,
    getInputProps,
  } = useDropzone({
    onDrop(files) {
      setFiles(files);
    },
  });

  const acceptedFileItems = acceptedFiles.map((file) => {
    return (
      <li key={file.path}>
        {file.path} ({prettyBytes(file.size)})
      </li>
    );
  });

  return (
    <section style={{ border: '1px dotted black', paddingLeft: 16, marginTop: 16 }}>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <p>Email Attachments: Drag and drop files here, or click to select files (The total file sizes should be less than 10MB)</p>
      </div>
      {(acceptedFiles.length > 0) && <aside>
        {/* <h4>Accepted files</h4> */}
        <ul>{acceptedFileItems}</ul>
        {/* <h4>Rejected files</h4>
        <ul>{fileRejectionItems}</ul> */}
      </aside>}
    </section>);
};

const EmailComposeDialog = ({ caseID, onUpdate }) => {
  const [open, setOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [messageContentHtml, setMessageContentHtml] = useState(RichTextEditor.createEmptyValue());
  const [files, setFiles] = useState([]);

  const uploadAttachments = async () => {
    if (files.length === 0) {
      return [];
    }
    console.log('acceptedFiles', files);

    const formData = new FormData();
    files.forEach((item) => {
      formData.append('files', item, item.name);
    });

    const res = await fetch(`/api/case/${caseID}/uploadEmailAttachments?env=${window.localStorage.getItem('env')}`, {
      method: 'POST',
      body: formData,
    });

    const { success, data } = await res.json();
    if (!success) {
      throw new Error('');
    }
    return data;
  };

  const onSubmit = async () => {
    setIsSaving(true);

    try {
      const emailAttachments = await uploadAttachments();

      onUpdate({
        messageContentHtml: messageContentHtml.toString('html'),
        emailAttachments,
      });
      onCancel();
    } catch (e) {
      console.log(e);
    } finally {
      setIsSaving(false);
    }
  };

  const onCancel = async () => {
    setMessageContentHtml(RichTextEditor.createEmptyValue());
    setOpen(false);
    setFiles([]);
  };

  useEffect(() => {}, []);

  const totalSize = files.reduce((sum, file) => {
    return sum += file.size;
  }, 0);

  const canSubmit = totalSize < 10 * 1024 * 1024;

  return (
    <Fragment>
      <Button fullWidth variant="contained" color="primary" onClick={() => setOpen(true)}>
        Reply
      </Button>
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={open}
        onClose={() => {}}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          <div className="text-editor-card">
            <RichTextEditor
              className="message-text-editor"
              toolbarConfig={toolbarConfig}
              value={messageContentHtml}
              onChange={(value) => {
                setMessageContentHtml(value);
              }}
            />
            {open && <DropZone setFiles={setFiles} />}
          </div>
        </DialogContent>
        <DialogActions style={{ padding: 16 }}>
          <Button
            onClick={onCancel}
            disabled={isSaving}
          >
            Cancel
          </Button>
          <div style={{ flex: 1 }} />
          <Button
            onClick={onSubmit}
            disabled={!canSubmit || isSaving || messageContentHtml.toString('html') === '<p><br></p>'}
            variant="contained"
            color="primary"
          >
            Send
          </Button>
        </DialogActions>

      </Dialog>
    </Fragment>
  );
};

EmailComposeDialog.propTypes = {
  caseID: PropTypes.string,
  onUpdate: PropTypes.func,
};

export default EmailComposeDialog;
