import React, { Fragment } from 'react';

import PrdDashboard from './prd/Dashboard-PRD';

const Dashboard = () => {
  // const env = window.localStorage.getItem('env');

  return (
    <Fragment>
      <PrdDashboard />
    </Fragment>
  );
};

export default Dashboard;
