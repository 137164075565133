import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import CopyIcon from '@material-ui/icons/FileCopy';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';

import {
  sortBy,
  request,
  formatCurrency,
} from '../utils';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
  },
}));

export default function CaseTrips({ accountId }) {
  const classes = useStyles();
  const [vehicleTrips, setVehicleTrips] = useState({});
  const [checked, setChecked] = useState([]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const onCopyAll = () => {
    setChecked([]);
  };

  useEffect(() => {
    if (!accountId) return;

    (async () => {
      const [
        { data: paidTrips },
        { data: assetTrips },
      ] = await Promise.all([
        request({
          api: `/api/account/${accountId}/trips`,
          method: 'GET',
          mode: 'cors',
        }),
        request({
          api: `/api/account/${accountId}/assetTrips`,
          method: 'GET',
          mode: 'cors',
        }),
      ]);

      const vehicleTrips = {};

      const trips = [...paidTrips, ...assetTrips]
        .filter(({ origin }) => origin === 'VIDEO')
        .map(({ tripId, lpn, cost, entry, tripStatus, tolls }) => {
          const status = tripStatus === 'PAID' ? 'PAID' : 'UNPAID';
          const costTotal = formatCurrency(cost.total);
          const entryTime = entry.time;
          const entryDate = moment(entryTime).format('YYYY-MM-DD');
          const tollWay = tolls[0].location.tollWay;
          const textString = `${entryDate}  ${lpn}  ${tollWay}  ${costTotal}  ${status} \r`;

          return {
            tripId,
            lpn,
            costTotal,
            entryDate,
            entryTime,
            status,
            tollWay,
            textString,
          };
        })
        .sort(sortBy('entryTime', true));

      trips.forEach((trip) => {
        vehicleTrips[trip.lpn] = vehicleTrips[trip.lpn] || [];
        vehicleTrips[trip.lpn].push(trip);
      });

      setVehicleTrips(vehicleTrips);
    })();
  }, [accountId]);

  if (!accountId) return null;

  return (
    <Card>
      <div style={{ width: '100%', textAlign: 'center', paddingTop: 8 }}>
        <CopyToClipboard
          text={checked.join('')}
          onCopy={onCopyAll}
        >
          <Button disabled={checked.length === 0} variant="contained" color="primary">
            Copy selected
          </Button>
        </CopyToClipboard>
      </div>
      <List className={classes.root} subheader={<li />}>
        {Object.keys(vehicleTrips).map((lpn) => (
          <li key={`section-${lpn}`} className={classes.listSection}>
            <ul className={classes.ul}>
              <ListSubheader>{lpn}</ListSubheader>
              {vehicleTrips[lpn].map(({ tripId, textString, entryDate, lpn, costTotal, tollWay, status }) => {
                const labelId = `checkbox-list-label-${tripId}`;

                return (
                  <ListItem key={tripId} role={undefined} dense button onClick={handleToggle(textString)}>
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={checked.indexOf(textString) !== -1}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      id={labelId}
                      primary={`${entryDate}  ${status} ${costTotal}`}
                      secondary={`${tollWay}`}
                    />
                    <ListItemSecondaryAction>
                      <Tooltip title="Copy to Clipboard">
                        <CopyToClipboard text={textString}>
                          <IconButton component="span">
                            <CopyIcon />
                          </IconButton>
                        </CopyToClipboard>
                      </Tooltip>
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
            </ul>
          </li>
        ))}
      </List>
    </Card>);
}


CaseTrips.propTypes = {
  accountId: PropTypes.string,
};
