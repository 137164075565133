import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Table from './Table';
import { tableCellTimeRenderer, sortBy, request } from '../utils';

const CardTable = ({ title = 'Cards', data: inData, accountId, nested }) => {
  const [cards, setCards] = useState([]);

  const load = useCallback(async () => {
    if (!accountId) return;

    const options = {
      api: `/api/account/${accountId}/cards`,
      method: 'GET',
      mode: 'cors',
    };
    const { data } = await request(options);
    setCards(data);
  }, [accountId]);

  useEffect(() => {
    if (inData) {
      setCards(inData);
    } else {
      load();
    }
  }, [inData, load]);

  const data = cards
    .sort(sortBy('createdDate', true))
    .sort(sortBy('updatedDate', true))
    .map((item) => {
      item.mitTransactionID = item.mitTransactionID || '';
      item.lifecycleCode = item.lifecycleCode || '';
      return item;
    });

  const onItemClick = (rowData, rowMeta) => {
    const item = data[rowMeta.dataIndex];
    console.log(item);
  };

  const options = {
    filterType: 'checkbox',
    onRowClick: onItemClick,
    rowsPerPageOptions: [10, 50, 100],
    rowsPerPage: 10,
  };

  const headers = [{
    name: 'chaseCustomerRefNum',
    label: 'Chase Ref Num',
    options: {
      filter: false,
    },
  }, {
    name: 'mitTransactionID',
    label: 'Chase MID Tx ID',
    options: {
      filter: false,
    },
  }, {
    name: 'lifecycleCode',
    label: 'Lifecycle Code',
    options: {
      filter: true,
    },
  }, {
    name: 'cardType',
    label: 'Brand',
  }, {
    name: 'cardLast4',
    label: 'Last 4',
    options: {
      filter: false,
    },
  }, {
    name: 'cardZipcode',
    label: 'Zip Code',
    options: {
      filter: false,
    },
  }, {
    name: 'cardExpirationDate',
    label: 'Exp',
    options: {
      filter: false,
    },
  }, {
    name: 'nickname',
    label: 'Nickname',
    options: {
      filter: false,
    },
  }, {
    name: 'isDefault',
    label: 'Default',
    options: {
      customBodyRender(value) {
        let display = '';
        switch (value) {
        case 1:
          display = `Default`;
          break;
        default:
          display = '';
        }
        return (
          <Typography variant="body1">
            {display}
          </Typography>);
      },
    },
  }, {
    name: 'itemUpdatedAt',
    label: 'Updated At',
    options: {
      filter: false,
      customBodyRender: tableCellTimeRenderer(true),
    },
  }, {
    name: 'createdDate',
    label: 'Created At',
    options: {
      filter: false,
      customBodyRender: tableCellTimeRenderer(true),
    },
  }];

  return (
    <Grid item xs={12}>
      <Table
        title={title}
        data={data}
        columns={headers}
        options={options}
        nested={nested}
      />
    </Grid>
  );
};

CardTable.propTypes = {
  title: PropTypes.string,
  data: PropTypes.object,
  accountId: PropTypes.string,
  nested: PropTypes.bool,
};

export default CardTable;
