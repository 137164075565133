import React, { useEffect, Fragment } from 'react';
import { request } from '../utils';

const Login = () => {
  useEffect(() => {
    window.localStorage.removeItem('accessToken');
    window.localStorage.removeItem('accessTokenType');
    window.localStorage.removeItem('accessTokenExpiredIn');
    window.localStorage.removeItem('idToken');

    (async () => {
      const options = {
        api: `/api/test`,
        method: 'GET',
        mode: 'cors',
      };
      const res = await request(options);
      console.log(res);
    })();
  }, []);

  return (
    <Fragment>
    </Fragment>);
};

export default Login;
