import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

import Table from './Table';

export default function VendorMappingTable({ title, data: vendorsMapping }) {
  const onItemClick = (rowData, rowMeta) => {
    const item = vendorsMapping[rowMeta.dataIndex];
    console.log(item);
  };

  const headers = [{
    name: 'id',
    label: 'ID',
    options: {
      filter: false,
      display: false,
    },
  }, {
    name: 'vendorId',
    label: 'Vendor ID',
    options: {
      filter: true,
    },
  }, {
    name: 'agencyId',
    label: 'Agency ID',
    options: {
      filter: false,
    },
  }, {
    name: 'assetId',
    label: 'Asset ID',
    options: {
      filter: true,
    },
  }, {
    name: 'vehicleRegistrationType',
    label: 'Vehicle Reg Type',
    options: {
      filter: true,
    },
  }, {
    name: 'vehicleRegistrationValue',
    label: 'Vehicle Reg Value (seconds)',
    options: {
      filter: false,
    },
  }];

  const options = {
    filterType: 'checkbox',
    onRowClick: onItemClick,
    rowsPerPageOptions: [20, 50, 100],
    rowsPerPage: 20,
  };

  return (
    <Grid item xs={12}>
      <Table
        title={title || 'Vendors Mapping'}
        data={vendorsMapping}
        columns={headers}
        options={options}
        nested={true}
      />
    </Grid>);
}

VendorMappingTable.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
};
