import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import SyncIcon from '@material-ui/icons/Sync';

const getMuiTheme = (maxHeight) => {
  const scrollMaxHeightCss = `${maxHeight ? maxHeight : 'calc(100vh - 115px)'} !important`;

  return createMuiTheme({
    overrides: {
      MUIDataTable: {
        paper: {
          padding: 0,
        },
        responsiveScrollMaxHeight: {
          width: '100%',
          height: scrollMaxHeightCss,
          maxHeight: scrollMaxHeightCss,
        },
      },
      MUIDataTableHeadCell: {
        root: {
          maxWidth: '300px',
          minWidth: '32px',
          wordBreak: 'break-word',
          overflowWrao: 'break-word',
          padding: '4px 4px 4px 12px',
          cursor: 'pointer',
        },
      },
      MUIDataTableBodyCell: {
        root: {
          maxWidth: '300px',
          minWidth: '32px',
          wordBreak: 'break-word',
          overflowWrao: 'break-word',
          padding: '4px 4px 4px 12px',
          cursor: 'pointer',
        },
      },
      MUIDataTableSelectCell: {
        expandDisabled: {
          // Soft hide the button.
          visibility: 'hidden',
        },
      },
    },
  });
};

export default function Table({ title, data, columns, maxHeight, options = {}, nested = false, useCacheColumns, onRefresh }) {
  // set cache
  const cacheName = `table-column-display-${title}`;
  const isCached = window.localStorage.getItem(cacheName) ? true : false;
  const cacheColumns = (window.localStorage.getItem(cacheName) || '').split(',');
  const updateCacheColumns = [];

  if (useCacheColumns) {
    columns.forEach((col) => {
      col.options = col.options || {};

      if (isCached) {
        col.options.display = cacheColumns.includes(col.name) ? true : false;
      } else {
        col.options.display = (col.options.display === undefined || (col.options.display === true || col.options.display === 'true')) ? true : false;
      }

      if (col.options.display) {
        updateCacheColumns.push(col.name);
      }
    });

    window.localStorage.setItem(cacheName, updateCacheColumns.join(','));
  }

  const onItemClick = (rowData, rowMeta) => {
    const item = data[rowMeta.dataIndex];
    console.log(item);
  };

  // overwrite options
  const updatedOptions = Object.assign({
    pagination: true,
    rowsPerPageOptions: [10, 50, 100],
    rowsPerPage: 10,
    filterType: 'checkbox',
    responsive: nested ? 'standard' : 'scrollMaxHeight',
    fixedHeader: true,
    resizableColumns: false,
    selectableRows: 'none',
    isRowSelectable: () => false,
    onRowClick: onItemClick,
    enableNestedDataAccess: '.',
    // https://github.com/gregnb/mui-datatables/issues/750
    // disable print for error: Function components cannot be given refs
    print: false,
    customToolbar: () =>
      <Fragment>
        {onRefresh &&
        <Tooltip title={'Refresh'}>
          <IconButton
            data-testid={'refresh-iconButton'}
            aria-label={'refresh'}
            onClick={onRefresh}>
            <SyncIcon />
          </IconButton>
        </Tooltip>}
      </Fragment>,
  }, options, {
    onViewColumnsChange: (changedColumn, action) => {
      if (!useCacheColumns) {
        return;
      }
      let cacheColumns = (window.localStorage.getItem(cacheName) || '').split(',');
      switch (action) {
      case 'add':
        if (!cacheColumns.includes(changedColumn)) {
          cacheColumns.push(changedColumn);
        }
        break;
      case 'remove':
        cacheColumns = cacheColumns.filter((item) => item !== changedColumn);
        break;
      default:
      }
      window.localStorage.setItem(cacheName, cacheColumns.join(','));
    },
  });

  return (
    <MuiThemeProvider theme={getMuiTheme(maxHeight)}>
      <MUIDataTable
        className='data-table'
        title={title}
        data={data}
        columns={columns}
        options={updatedOptions}
      />
    </MuiThemeProvider>
  );
}

Table.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
  columns: PropTypes.array,
  options: PropTypes.object,
  useCacheColumns: PropTypes.bool,
  onRefresh: PropTypes.func,
  nested: PropTypes.bool,
  maxHeight: PropTypes.string,
};
