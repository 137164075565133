import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
// import TableRow from '@material-ui/core/TableRow';
// import TableCell from '@material-ui/core/TableCell';

import Table from './Table';
import {
  sortBy,
  sortByList,
  request,
  tableRenderer,
  tableCellTimeRenderer,
  tableCellBooleanRenderer,
  tableCellStatusRenderer,
} from '../utils';
// import VendorMappingTable from './VendorMappingTable';

export default function AssetTable({ title, data: inData }) {
  const [data, setData] = useState([]);
  const [headers, setHeaders] = useState([]);

  const load = async () => {
    const options = {
      api: `/api/assets`,
      method: 'GET',
      mode: 'cors',
    };
    const { data } = await request(options);
    return data;
  };

  useEffect(() => {
    (async () => {
      const data = inData || await load();
      setData(data
        .sort(sortBy('id'))
        .sort(sortBy('state'))
        .map((x) => {
          x.count_ods = (x.resourceCount || {}).ods || 0;
          x.count_tollingZones = (x.resourceCount || {}).tollingZones || 0;
          return x;
        }));

      const headers = Object.keys(data[0] || {})
        .sort(sortByList([
          'state', 'id', 'name', 'shortName', 'shorterName', 'active',
          'gpsDisabled', 'gpsPayable', 'gpsRateLimitingEnabled', 'gpsRateLimitingAgeMinutes',
        ]))
        .map((key) => {
          const config = {
            name: key,
            label: key,
            options: {
              filter: false,
              customBodyRender(value) {
                value = `${value}`;
                return (
                  <Typography variant="body1">
                    {value}
                  </Typography>);
              },
            },
          };

          if (['shortName', 'shorterName', 'tagName'].includes(key)) {
            config.options.display = false;
          }

          if (['active'].includes(key)) {
            config.options.customBodyRender = tableCellStatusRenderer();
          }

          if (['gpsDisabled', 'gpsPayable', 'gpsRateLimitingEnabled'].includes(key)) {
            config.options.customBodyRender = tableCellBooleanRenderer();
          }

          if (['createdDate', 'itemUpdatedAt', 'vendor', 'mappings'].includes(key)) {
            config.options.display = false;
            config.options.customBodyRender = tableCellTimeRenderer(true);
          }

          if (key.startsWith('count_')) {
            config.options.customBodyRender = tableRenderer();
          }

          if (['resourceCount'].includes(key)) {
            config.options.display = false;
            config.options.customBodyRender = function render(value = {}) {
              return (
                <ul>
                  {Object.keys(value).map((x, index)=>(
                    <li key={index}>{x}: {value[x]}</li>
                  ))}
                </ul>
              );
            };
          }

          return config;
        });

      setHeaders(headers);
    })();
  }, [inData]);

  const onItemClick = (rowData, rowMeta) => {
    const item = data[rowMeta.dataIndex];
    console.log(item);
  };

  const options = {
    filterType: 'checkbox',
    onRowClick: onItemClick,
    rowsPerPageOptions: [10, 50, 100],
    rowsPerPage: 10,
    // expandableRows: true,
    // renderExpandableRow(rowData, rowMeta) {
    //   console.log('renderExpandableRow', rowData, rowMeta);
    //   const item = data[rowMeta.dataIndex];

    //   const copiedData = JSON.parse(JSON.stringify(item));
    //   delete copiedData.vendor;
    //   delete copiedData.mappings;

    //   return (
    //     <TableRow>
    //       <TableCell colSpan={headers.length + 1} className="nested-table-container">
    //         <VendorMappingTable data={item.mappings}/>
    //       </TableCell>
    //     </TableRow>
    //   );
    // },
  };

  return (
    <Grid item xs={12}>
      <Table
        title={title || 'Assets'}
        data={data}
        columns={headers}
        options={options}
      />
    </Grid>);
}

AssetTable.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
};
