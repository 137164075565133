import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import NotificationsOutlinedIcon from '@material-ui/icons/NotificationsOutlined';
import NotificationsOffOutlinedIcon from '@material-ui/icons/NotificationsOffOutlined';

import { request } from '../utils';

const AccountNotificationToggle = ({ accountId, silenceNotifications, onUpdate }) => {
  const silenced = parseInt(silenceNotifications, 10) === 1 ? true : false;

  if (!accountId) {
    return null;
  }

  const handleToggle = async () => {
    try {
      const next = silenced ? 0 : 1;
      const options = {
        api: `/api/account/${accountId}/notifications`,
        method: 'POST',
        body: JSON.stringify({
          silenced: next,
        }),
        mode: 'cors',
      };
      await request(options);

      if (onUpdate) {
        onUpdate();
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Fragment>
      <Button className="space" variant="outlined" color="secondary" onClick={handleToggle} title={silenced ? 'Notifications muted' : 'Notifications enabled'}>
        { silenced ? (<NotificationsOffOutlinedIcon />) : (<NotificationsOutlinedIcon />) }
      </Button>
    </Fragment>
  );
};

AccountNotificationToggle.propTypes = {
  accountId: PropTypes.string,
  silenceNotifications: PropTypes.number,
  onUpdate: PropTypes.func,
};

export default AccountNotificationToggle;
