import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

import { request } from '../utils';
import Code from '../components/Code';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
  },
  selectContainer: {
    paddingTop: theme.spacing(2),
  },
}));

const timeZones = [
  'America/New_York',
  'America/Los_Angeles',
  'UTC',
];

export default function Postgres() {
  const classes = useStyles();
  const env = window.localStorage.getItem('env');

  const [loading, setLoading] = useState(false);
  const [timeZone, setTimeZone] = useState(timeZones[0]);
  const [tableName, setTableName] = useState('');
  const [tableNames, setTableNames] = useState([]);
  const [query, setQuery] = useState({
    queryString: '',
    sample: {},
  });

  useEffect(() => {
    (async () => {
      setLoading(true);
      const options = {
        api: `/api/postgres/${env}/list`,
        method: 'GET',
        mode: 'cors',
      };
      const { data } = await request(options);
      setTableName(data[0]);
      setTableNames(data);
      setLoading(false);
    })();
  }, [env]);

  useEffect(() => {
    if (!tableName) return;
    (async () => {
      setLoading(true);
      const options = {
        api: `/api/postgres/${tableName}/queryStrings`,
        query: {
          timeZone,
        },
        method: 'GET',
        mode: 'cors',
      };
      const { data } = await request(options);
      setQuery(data);
      setLoading(false);
    })();
  }, [tableName, timeZone]);

  return (
    <Paper className={classes.container}>
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          <Typography variant="h5">
            Postgres Query String Helper
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.selectContainer}>
          <FormControl fullWidth>
            <InputLabel htmlFor="age-native-simple">
              Time Zone
            </InputLabel>
            <Select
              native
              value={timeZone}
              disabled={loading}
              onChange={(event)=> setTimeZone(event.target.value)}
              inputProps={{
                name: 'timeZone',
                id: 'time-zone',
              }}>
              {timeZones.map((name)=>{
                return (<option key={name} value={name}>{name}</option>);
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} className={classes.selectContainer}>
          <FormControl fullWidth>
            <InputLabel htmlFor="age-native-simple">
              Select the database table
            </InputLabel>
            <Select
              native
              value={tableName}
              disabled={loading}
              onChange={(event)=> setTableName(event.target.value)}
              inputProps={{
                name: 'tableName',
                id: 'table-name',
              }}>
              {tableNames.map((name)=>{
                return (<option key={name} value={name}>{name}</option>);
              })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container justify="space-between">
        <Grid item xs={12} sm={6} className={classes.container}>
          <Typography variant="h6">
            Postgres Query String
          </Typography>
          <Code code={query.queryString} defaultLanguage="text"/>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.container}>
          <Typography variant="h6">
            Sample data
          </Typography>
          <Code code={query.sample} defaultLanguage="json"/>
        </Grid>
      </Grid>
    </Paper>
  );
}
