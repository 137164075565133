import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Skeleton from '@material-ui/lab/Skeleton';
import { green, pink } from '@material-ui/core/colors';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import queryString from 'query-string';
import moment from 'moment';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormGroup from '@material-ui/core/FormGroup';
import SyncIcon from '@material-ui/icons/Sync';
import { DebounceInput } from 'react-debounce-input';
import TextField from '@material-ui/core/TextField';

import { request, diffTime, sortBy, formatCurrency } from '../utils';
import FailedAssetTransactionHandlerDialog from './FailedAssetTransactionHandlerDialog';
import FailedAssetTransactionCaseKanbanBoard from './FailedAssetTransactionCaseKanbanBoard';
import StatusBadge from './StatusBadge';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
    paddingRight: 10,
    flex: 1,
  },
  pink: {
    color: theme.palette.getContrastText(pink[500]),
    backgroundColor: pink[500],
  },
  green: {
    color: '#fff',
    backgroundColor: green[500],
  },
  main: {
    overflow: 'hidden',
    height: 'calc(100vh)',
    padding: theme.spacing(2),
  },
  filterContainer: {
    marginBottom: 16,
    padding: 16,
  },
  listContainer: {
    overflow: 'auto',
    height: 'calc(100vh - 80px)',
  },
  selected: {
    backgroundColor: '#f5f5f5',
  },
  formControl: {
    margin: theme.spacing(0),
    width: '100%',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
    height: 24,
    minWidth: 10,
  },
  selectFormControl: {
    margin: 0,
    marginRight: 8,
    marginTop: 10,
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, items, theme) {
  return {
    fontWeight: items.indexOf(name) === -1 ?
      theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  };
}

const FailedAssetTransactions = ({ history }) => {
  const classes = useStyles();
  const theme = useTheme();

  const [transactions, setTransactions] = useState([]);
  const [selectedTransaction, setSelectedTransaction] = useState(undefined);
  const [relatedTransactions, setRelatedTransactions] = useState([]);
  const [isLoadingTransactions, setIsLoadingTransactions] = useState(true);
  const [isLoadingCases, setIsLoadingCases] = useState(true);
  const [caseStatusOptions, setCaseStatusOptions] = useState([]);
  const [cases, setCases] = useState([]);
  const [checkedTransactionIds, setCheckedTransactionIds] = useState([]);
  const [lastUpdatedAt, setLastUpdatedAt] = useState();
  const [targetStatus, setTargetStatus] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [filteredCases, setFilteredCases] = useState([]);

  const handleCreateCase = async () => {
    setIsLoadingTransactions(true);
    setIsLoadingCases(true);

    const assetTransactions = transactions.filter((x) => checkedTransactionIds.includes(x.tx_serial_no));

    const options = {
      api: `/api/failedTransactionCases`,
      method: 'POST',
      body: JSON.stringify({
        assetTransactions,
      }),
      mode: 'cors',
    };
    await request(options);
    setIsLoadingTransactions(false);
    setIsLoadingCases(false);
    setLastUpdatedAt(Date.now());
  };

  const loadCases = useCallback(async (inTargetStatus = []) => {
    let cases = [];
    await Promise.all(inTargetStatus.map(async (status) => {
      const { data } = await request({
        api: `/api/failedTransactionCases`,
        method: 'GET',
        query: {
          status,
        },
        mode: 'cors',
      });

      cases = [...cases, ...data];
    }));

    return cases;
  }, []);

  useEffect(() => {
    if (selectedTransaction && selectedTransaction.tx_serial_no) {
      const queryParams = queryString.parse(history.location.search);
      history.push({
        search: queryString.stringify(Object.assign(queryParams, {
          txSerialNo: selectedTransaction.tx_serial_no,
        })),
      });

      const {
        asset_id: assetId,
        tx_serial_no: txSerialNo,
        tx_entry_datetime: txEntryDatetime,
        tx_license_no: txLicenseNo,
      } = selectedTransaction;
      const from = moment(txEntryDatetime).add(-2, 'hours').toISOString();
      const to = moment(txEntryDatetime).add(2, 'hours').toISOString();

      const relatedTransactions = transactions
        .filter((x) => {
          return x.tx_serial_no !== txSerialNo &&
            x.asset_id === assetId &&
            x.tx_license_no === txLicenseNo &&
            (x.tx_entry_datetime >= from && x.tx_entry_datetime <= to);
        })
        .sort(sortBy('tx_entry_datetime'));

      setRelatedTransactions(relatedTransactions);
    }
  }, [selectedTransaction, transactions, history]);

  useEffect(() => {
    (async () => {
      setIsLoadingCases(true);
      const { data: statusOptions } = await request({
        api: '/api/failedTransactionCasesStatusOptions',
        method: 'GET',
        mode: 'cors',
      });
      console.log('statusOptions', statusOptions);
      setCaseStatusOptions(statusOptions);
      setTargetStatus(statusOptions.filter((x) => x !== 'CLOSED' && x !== 'ABSORBED_COST'));

      setIsLoadingCases(false);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setIsLoadingTransactions(true);
      setTransactions([]);

      const [
        { data: transactions },
      ] = await Promise.all([
        request({
          api: '/api/failedAssetTransactions',
          method: 'GET',
          mode: 'cors',
        }),
      ]);

      setTransactions(transactions.sort(sortBy('tx_entry_datetime', true)));

      setIsLoadingTransactions(false);
    })();
  }, [lastUpdatedAt]);

  useEffect(() => {
    if (targetStatus.length === 0) return;

    (async () => {
      setIsLoadingCases(true);

      const [
        cases,
      ] = await Promise.all([
        loadCases(targetStatus),
      ]);

      setCases(cases);

      setIsLoadingCases(false);
    })();
  }, [lastUpdatedAt, targetStatus, loadCases]);

  useEffect(() => {
    if (!searchText || searchText === '') {
      setFilteredCases([...cases]);
    } else {
      setFilteredCases(cases
        .filter((item) => {
          return Object.keys(item).some((key) => {
            if (typeof item[key] === 'string') {
              return item[key].toLowerCase().includes(searchText.toLowerCase());
            } else {
              return false;
            }
          });
        }));
    }
  }, [searchText, cases]);

  return (
    <Grid container className={classes.main} spacing={2}>
      <Grid item md={2}>
        <div style={{ padding: 8 }}>
          <Button
            fullWidth={true}
            onClick={handleCreateCase}
            disabled={checkedTransactionIds.length === 0 || isLoadingTransactions}
            variant="contained"
            color="primary"
          >
            Create Case ({checkedTransactionIds.length})
          </Button>
        </div>
        <Paper className={classes.listContainer}>
          <List className={classes.root}>
            {isLoadingTransactions && Array.from(new Array(10)).map((item, index)=>(
              <ListItem key={index} divider button>
                <ListItemText
                  primary={<Skeleton animation="wave" />}
                  secondary={<Skeleton animation="wave" width="70%" />}
                />
              </ListItem>
            ))}
            {transactions.map((item, index)=>(
              <ListItem
                key={index}
                button
                divider
                alignItems="flex-start"
                onClick={() => {
                  console.log(item);
                  const { tx_serial_no: id } = item;
                  const currentIndex = checkedTransactionIds.indexOf(id);
                  if (currentIndex === -1) {
                    checkedTransactionIds.push(id);
                  } else {
                    checkedTransactionIds.splice(currentIndex, 1);
                  }

                  setCheckedTransactionIds([...checkedTransactionIds]);
                }}
                disabled={isLoadingTransactions}
                selected={(selectedTransaction || {}).tx_serial_no === item.tx_serial_no}
              >
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={checkedTransactionIds.indexOf(item.tx_serial_no) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': item.tx_serial_no }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={<span style={{ display: 'flex' }}>
                    <Typography
                      component="span"
                      variant="body1"
                      className={classes.inline}
                      color="textPrimary"
                    >
                      {transactions.length - index}. {item.tx_license_no}
                    </Typography>
                    <StatusBadge status={item.tx_status} />
                  </span>}
                  secondary={
                    <span style={{ display: 'flex' }}>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                      >
                        [{item.vendor}] {item.asset_id} {formatCurrency(item.tx_toll_amount)}
                      </Typography>
                      {diffTime(item.tx_entry_datetime)}
                    </span>
                  }
                />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="comments"
                    disabled={isLoadingTransactions}
                    size="small"
                    onClick={()=>{
                      console.log(item);
                      setSelectedTransaction(item);
                    }}>
                    <VisibilityIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Paper>
      </Grid>
      <Grid item xs={10} container spacing={2}>
        <Grid item xs={12}>
          <Paper style={{ padding: 8 }}>
            <FormGroup row>
              <FormControl style={{ paddingTop: 16 }}>
                <IconButton
                  color="inherit"
                  onClick={async () => {
                    setLastUpdatedAt(Date.now());
                  }}
                  aria-label="open"
                  style={{ marginRight: 16 }}
                  disabled={isLoadingCases}
                >
                  {isLoadingCases ? <CircularProgress size={20} /> : <SyncIcon /> }
                </IconButton>
              </FormControl>
              <FormControl
                className={classes.selectFormControl}
                margin="dense"
                variant="outlined"
              >
                <InputLabel id="status-multiple-label">Status</InputLabel>
                <Select
                  labelId="status-multiple"
                  id="status-multiple"
                  multiple
                  value={targetStatus}
                  onChange={(e)=> setTargetStatus(e.target.value)}
                  input={<Input id="select-multiple-status" />}
                  renderValue={(selected) => (
                    <div className={classes.chips}>
                      {selected.map((value) => (
                        <Chip key={value} label={value} className={classes.chip} />
                      ))}
                    </div>
                  )}
                  disabled={isLoadingCases}
                  MenuProps={MenuProps}
                >
                  {caseStatusOptions.map((name) => (
                    <MenuItem key={name} value={name} style={getStyles(name, targetStatus, theme)}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl style={{ paddingTop: 16 }}>
                <DebounceInput
                  debounceTimeout={300}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                  element={TextField}
                  id={`search-text`}
                  label=""
                  value={searchText}
                  type={'text'}
                  placeholder="Search ..."
                  variant="outlined"
                  margin="dense"
                  disabled={isLoadingCases}
                />
              </FormControl>
            </FormGroup>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <FailedAssetTransactionCaseKanbanBoard
            cases={filteredCases}
            caseStatusOptions={targetStatus}
          />
        </Grid>
        {selectedTransaction &&
          <FailedAssetTransactionHandlerDialog
            transaction={selectedTransaction}
            relatedTransactions={relatedTransactions}
            onUpdate={(updatedTransactions) => {
              selectedTransaction.isProcessed = true;
              setSelectedTransaction({ ...selectedTransaction });

              updatedTransactions.forEach((item)=>{
                item.isProcessed = true;
              });
              setTransactions([...transactions]);
            }}
            onClose={() => setSelectedTransaction()}
          />}
      </Grid>
    </Grid>
  );
};

FailedAssetTransactions.propTypes = {
  history: PropTypes.object.isRequired,
};

export default FailedAssetTransactions;
