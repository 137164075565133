import React, { Fragment, useState, useEffect } from 'react';
import moment from 'moment';
import Fab from '@material-ui/core/Fab';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DetailForm from 'react-material-final-form';
import AddIcon from '@material-ui/icons/Add';

import { request } from '../utils';

import sourceMetadata from './CreateProgramMetadata';

export default function CreateProgramModal({ data: inData, onUpdate }) {
  const [open, setOpen] = useState(false);
  const [metadata, setMetadata] = useState(undefined);
  const [data, setData] = useState({});

  const mode = inData ? 'Edit' : 'Create';

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async (values) => {
    if (inData && values.id) {
      const options = {
        api: `/api/promotions/program/${values.id}`,
        method: 'PUT',
        body: JSON.stringify(values),
        mode: 'cors',
      };

      await request(options);
    } else {
      const options = {
        api: `/api/promotions/programs`,
        method: 'POST',
        body: JSON.stringify(values),
        mode: 'cors',
      };

      await request(options);
    }

    handleClose();

    if (onUpdate) {
      onUpdate();
    }
  };

  useEffect(() => {
    if (inData) {
      setData(inData);
    } else {
      setData({
        dateStart: moment().toISOString(),
        dateEnd: moment().add(60, 'days').toISOString(),
        type: 'discount',
        countUses: 0,
        usesMax: 1000,
        activeStatus: 1,
        firstTimeUserOnly: 0,
        firstTimeRedeemOnly: 0,
        creditType: 'cent',
        creditValue: 100,
        ignoreTripTime: 0,
      });
    }
  }, [inData]);

  useEffect(() => {
    (async () => {
      const options = {
        api: `/api/assets`,
        method: 'GET',
        mode: 'cors',
      };

      const { data: assets } = await request(options);

      const assetIdField = sourceMetadata.fields.find(({ key }) => key === 'assetIds');
      assetIdField.formOptions = assets
        .map((asset) => {
          return {
            label: `[${asset.id}] ${asset.state} - ${asset.shortName}`,
            value: asset.id,
          };
        })
        .sort((a, b) => a.label > b.label ? 1 : -1);
      setMetadata(sourceMetadata);
    })();
  }, []);

  if (!metadata) {
    return null;
  }

  return (
    <Fragment>
      {mode === 'Edit' ?
        <Button className="space" color="primary" onClick={handleClickOpen}>
          {mode} Program
        </Button>:
        <Fab
          color="secondary"
          aria-label="add"
          size="medium"
          onClick={handleClickOpen}
          style={{ position: 'absolute', zIndex: 10, bottom: 64, right: 32 }}
        >
          <AddIcon />
        </Fab>}
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Program
        </DialogTitle>
        <DialogContent>
          <DetailForm
            title={mode}
            metadata={metadata}
            data={data}
            onSubmit={onSubmit}
          />
        </DialogContent>
      </Dialog>
    </Fragment>
  );
}
