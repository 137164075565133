import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import Table from './Table';
import {
  tableCellTimeRenderer,
  tableCellStatusRenderer,
  sortBy,
  request,
} from '../utils';
import VendorTransactionTable from './VendorTransactionTable';

export default class AssetTransactionTable extends Component {
  state = {
    data: [],
  }

  componentDidMount() {
    this.load();
  }

  load = () => {
    if (this.props.data) return;
    if (!this.props.tripId) return;

    return Promise.resolve()
      .then(() => {
        const options = {
          api: `/api/trip/${this.props.tripId}/assets`,
          method: 'GET',
          mode: 'cors',
        };
        return request(options);
      })
      .then((res) => {
        this.setState({
          data: res.data,
        });
      })
      .catch(console.log);
  }

  render() {
    const headers = [{
      name: 'vendor',
      label: 'Vendor',
    }, {
      name: 'tx_serial_no',
      label: 'ID',
      options: {
        filter: false,
        display: false,
      },
    }, {
      name: 'ref_tx_serial_no',
      label: 'Ref ID',
      options: {
        filter: false,
        display: false,
      },
    }, {
      name: 'asset_id',
      label: 'Asset ID',
    }, {
      name: 'tripId',
      label: 'Trip ID',
      options: {
        filter: false,
      },
    }, {
      name: 'category',
      label: 'Category',
    }, {
      name: 'tx_status',
      label: 'Status',
      options: {
        customBodyRender: tableCellStatusRenderer({
          [-1]: 2,
        }),
      },
    }, {
      name: 'tx_processed',
      label: 'Processed',
      options: {
        customBodyRender: tableCellStatusRenderer({
          [-1]: 2,
        }),
      },
    }, {
      name: 'tx_replied',
      label: 'Replied',
      options: {
        display: false,
        customBodyRender: tableCellStatusRenderer({
          [-1]: 2,
        }),
      },
    }, {
      name: 'tripValidated',
      label: 'Validated',
      options: {
        customBodyRender: tableCellStatusRenderer({
          [-1]: 2,
        }),
      },
    }, {
      name: 'tx_license_no',
      label: 'License No',
      options: {
        filter: false,
      },
    }, {
      name: 'adminFee',
      label: 'Admin Fee',
      options: {
        filter: false,
      },
    }, {
      name: 'tx_status_msg',
      label: 'Status Msg',
      options: {
        filter: false,
      },
    }, {
      name: 'tx_entry_plaza',
      label: 'Entry',
      options: {
        filter: false,
      },
    }, {
      name: 'tx_entry_datetime',
      label: 'Entry At',
      options: {
        filter: false,
        customBodyRender: tableCellTimeRenderer(true),
      },
    }, {
      name: 'tx_exit_plaza',
      label: 'Exit',
      options: {
        filter: false,
      },
    }, {
      name: 'tx_exit_datetime',
      label: 'Exit At',
      options: {
        filter: false,
        customBodyRender: tableCellTimeRenderer(true),
      },
    }, {
      name: 'tx_toll_amount',
      label: 'Amount',
      options: {
        filter: false,
      },
    }, {
      name: 'adminFee',
      label: 'Admin Fee',
      options: {
        filter: false,
      },
    }, {
      name: 'agencyAdjacentIntervalInMinutes',
      label: 'Interval (min)',
      options: {
        filter: false,
      },
    }, {
      name: 'created',
      label: 'Created At',
      options: {
        filter: false,
        customBodyRender: tableCellTimeRenderer(true),
      },
    }].map((item) => {
      if (this.props.tripId) {
        if (['tripId'].includes(item.name)) {
          item.options = {
            display: false,
          };
        }
      }
      return item;
    });

    const data = (this.props.data || this.state.data);

    const onItemClick = (rowData, rowMeta) => {
      const item = data[rowMeta.dataIndex];
      console.log(item);
    };

    const options = {
      filterType: 'checkbox',
      onRowClick: onItemClick,
      rowsPerPageOptions: [10, 50, 100],
      rowsPerPage: 10,
      expandableRows: true,
      renderExpandableRow: (rowData, rowMeta) => {
        console.log('renderExpandableRow', rowData, rowMeta);
        const item = data[rowMeta.dataIndex];

        return (
          <TableRow>
            <TableCell colSpan={headers.length + 1} className="nested-table-container">
              <VendorTransactionTable uniqueId={item.tx_serial_no} />
            </TableCell>
          </TableRow>
        );
      },
    };

    data.sort(sortBy('created', true))
      .map((item) => {
        try {
          item.details = JSON.stringify(item.tx_details);
        } catch (e) {
          //
        }
        headers.forEach((header) => {
          item[header.name] = (item[header.name] !== undefined) ? item[header.name] : '';
        });
        return item;
      });

    return (
      <Grid item xs={12}>
        <Table
          title={this.props.title || 'Transactions'}
          data={data}
          columns={headers}
          options={options}
          nested={true}
        />
      </Grid>);
  }
}
