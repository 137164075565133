import React from 'react';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import Table from './Table';
import VendorMappingTable from './VendorMappingTable';
import { sortBy, tableCellBooleanRenderer } from '../utils';

export default function VendorAgencyTable({ agencies = [] }) {
  const onItemClick = (rowData, rowMeta) => {
    const item = agencies[rowMeta.dataIndex];
    console.log(item);
  };

  const headers = [{
    name: 'vendorId',
    label: 'Vendor ID',
    options: {
      filter: true,
      display: false,
    },
  }, {
    name: 'isPrimary',
    label: 'Primary',
    options: {
      filter: true,
      customBodyRender: tableCellBooleanRenderer(),
    },
  }, {
    name: 'agencyId',
    label: 'Agency ID',
    options: {
      filter: false,
    },
  }, {
    name: 'agency.name',
    label: 'Agency Name',
    options: {
      filter: false,
    },
  }, {
    name: 'agency.display',
    label: 'Agency Display',
    options: {
      filter: false,
    },
  }, {
    name: 'agency.etc',
    label: 'ETC',
    options: {
      filter: false,
    },
  }, {
    name: 'adminFee',
    label: 'Admin Fee (cents)',
    options: {
      filter: false,
    },
  }, {
    name: 'refAgencyId',
    label: 'Reference Agency ID',
    options: {
      filter: false,
    },
  }];

  const options = {
    filterType: 'checkbox',
    onRowClick: onItemClick,
    rowsPerPageOptions: [10, 50, 100],
    rowsPerPage: 10,
    expandableRows: true,
    renderExpandableRow: (rowData, rowMeta) => {
      console.log('renderExpandableRow', rowData, rowMeta);
      const item = agencies[rowMeta.dataIndex];

      const copiedData = JSON.parse(JSON.stringify(item));
      delete copiedData.vendor;
      delete copiedData.mappings;

      return (
        <TableRow>
          <TableCell colSpan={headers.length + 1} className="nested-table-container">
            <VendorMappingTable data={item.mappings}/>
          </TableCell>
        </TableRow>
      );
    },
  };

  return (
    <Table
      title={'Agencies'}
      data={agencies.sort(sortBy('isPrimary', true))}
      columns={headers}
      options={options}
      nested={true}
    />);
}

VendorAgencyTable.propTypes = {
  agencies: PropTypes.array,
};
