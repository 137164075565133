import React, { Fragment } from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Apps from '@material-ui/icons/Apps';

import { request } from '../utils';

export default class InternalAPIMenuButton extends React.Component {
  state = {
    anchorEl: null,
    loading: false,
    menu: [{
      label: 'Refund/Reject old GPS trips',
      value: {
        moduleName: 'trips',
        api: 'timerOldGpsTrips',
      },
    }, {
      label: 'Refund/Reject old Asset trips',
      value: {
        moduleName: 'trips',
        api: 'timerOldAssetTrips',
      },
    }, {
      label: 'Update Trip Life Cycle',
      value: {
        moduleName: 'trips',
        api: 'tripsWatchdogCron',
      },
    }, {
      label: 'Create new trips from new asset transactions',
      value: {
        moduleName: 'trips',
        api: 'timerNewAsset',
      },
    }, {
      label: 'ICLP cron',
      value: {
        moduleName: 'asset',
        api: 'iclpCron',
      },
    }].map((item) => {
      item.label = `[${item.value.moduleName}] ${item.value.api} - ${item.label}`;
      return item;
    }).sort((a, b) => {
      return a.label > b.label ? 1 : -1;
    }),
  };

  handleClick = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
      loading: false,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
      loading: false,
    });
  };

  callInternalAPI = (queryData = {}) => {
    this.setState({ loading: true });
    return Promise.resolve()
      .then(() => {
        const options = {
          api: `/api/internal`,
          method: 'GET',
          mode: 'cors',
          query: Object.assign(queryData, {
            api: queryData.api + ((this.props.assetId) ? `?assetId=${this.props.assetId}` : ''),
          }),
        };

        return request(options);
      })
      .then(() => {
        this.setState({ loading: false });
        this.handleClose();
        if (this.props.onUpdate) {
          this.props.onUpdate();
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
        this.handleClose();
      });
  }

  render() {
    return (
      <Fragment>
        <Button
          className="space"
          color="default"
          aria-owns={this.state.anchorEl ? 'simple-menu' : undefined}
          aria-haspopup="true"
          onClick={this.handleClick}>
          <Apps/>
          {/* Internal API Menu */}
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={this.state.anchorEl}
          open={Boolean(this.state.anchorEl)}
          onClose={this.handleClose}>
          {
            (this.state.menu || []).map((item, index) => (
              <MenuItem key={index} onClick={()=>{
                this.callInternalAPI(item.value);
              }} disabled={this.state.loading}>
                {item.label}
              </MenuItem>),
            )
          }
        </Menu>
      </Fragment>
    );
  }
}
