import moment from 'moment';

export default {
  fields: [{
    key: 'programId',
    type: 'String',
    label: 'Program ID',
    isDisabled: true,
  },
  {
    key: 'code',
    type: 'String',
    label: 'Code',
  },
  {
    key: 'title',
    type: 'String',
    label: 'Title',
  },
  {
    key: 'description',
    type: 'String',
    label: 'Code rules',
    isRequired: false,
  },
  {
    key: 'dateStart',
    type: 'String',
    label: 'Date Start (UTC)',
    formType: 'Date',
    formFormatFunction(value) {
      return moment(value).format('YYYY-MM-DD');
    },
    formParseFunction(value) {
      return moment(value).toISOString();
    },
  },
  {
    key: 'dateEnd',
    type: 'String',
    label: 'Date End (UTC)',
    formType: 'Date',
    formFormatFunction(value) {
      return moment(value).format('YYYY-MM-DD');
    },
    formParseFunction(value) {
      return moment(value).toISOString();
    },
  },
  {
    key: 'creditUseDuration',
    type: 'Number',
    label: 'Credit Use Duration in milliseconds',
  },
  {
    key: 'countUses',
    type: 'Number',
    label: 'Count Uses',
    isDisabled: true,
  },
  {
    key: 'usesMax',
    type: 'Number',
    label: 'Max Uses',
  },
  {
    key: 'creditType',
    type: 'String',
    label: 'Credit Type',
    formType: 'Radio',
    formOptions: [
      { label: 'cent', value: 'cent' },
      { label: 'percentage', value: 'percentage' },
      { label: 'trip', value: 'trip' },
    ],
  },
  {
    key: 'creditValue',
    type: 'Number',
    label: 'Credit Value',
  },
  {
    key: 'usesMaxPerAccount',
    type: 'Number',
    label: 'Uses Max per Account',
  },
  {
    key: 'ignoreTripTime',
    type: 'String',
    label: 'Ignore Trip Trime',
    formType: 'Radio',
    formOptions: [
      { label: 'No', value: 0 },
      { label: 'Yes', value: 1 },
    ],
  },
  {
    key: 'assetIds',
    type: 'String',
    label: 'Selected Roadways',
    formType: 'checkbox',
    formOptions: [],
  },

  ],
};
