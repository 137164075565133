import React, { Fragment } from 'react';

import AgencyTable from '../components/AgencyTable';

import './Agency.css';

const Agency = () => {
  return (
    <Fragment>
      <AgencyTable />
    </Fragment>);
};

export default Agency;
