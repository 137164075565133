import React, { useState, useEffect, Fragment } from 'react';
import Countdown from 'react-countdown-now';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import './Environment.css';
import {
  request,
} from '../utils';
import CronJob from '../cronJob/CronJob';
import DatadogMonitors from '../datadog/DatadogMonitors';
import Code from '../components/Code';

export default function Environment() {
  const [loading, setLoading] = useState(false);
  const [config, setConfig] = useState();
  const [isVendorSyncCronsOff, setIsVendorSyncCronsOff] = useState(false);
  const [isInMaintenanceMode, setIsInMaintenanceMode] = useState(false);
  // const [currentStatus, setCurrentStatus] = useState(0);
  const [currentMessage, setCurrentMessage] = useState('N/A');
  const [countdown, setCountdown] = useState();
  const [vendorSyncCronCountdown, setVendorSyncCronCountdown] = useState();
  const [lastUpdatedAt, setLastUpdatedAt] = useState(Date.now());

  const env = window.localStorage.getItem('env');

  const getVendorCronStatus = async () => {
    setLoading(true);

    const options = {
      api: `/api/environment/vendorSyncCronsStatus`,
      method: 'GET',
      mode: 'cors',
    };
    const { data } = await request(options);
    setIsVendorSyncCronsOff(data.status === 'DISABLED');
    setVendorSyncCronCountdown(data.disabledVendorSyncCronsUntil);
    setLoading(false);
  };

  const getConfig = async () => {
    setLoading(true);

    const options = {
      api: `/api/environment/config`,
      method: 'GET',
      mode: 'cors',
    };
    const { data } = await request(options);
    setConfig(data);
    setIsInMaintenanceMode(data.config.maint.overall.status === 1);
    // setCurrentStatus(data.config.maint.overall.status);
    setCurrentMessage(data.config.maint.overall.message);

    setLoading(false);
  };

  const toggleVendorSyncCrons = async () => {
    if (!window.confirm(`Are you sure to toggle the vendor sync crons for ${env}?`)) {
      return;
    }
    setLoading(true);
    const options = {
      api: `/api/environment/toggleVendorSyncCrons`,
      method: 'POST',
      body: JSON.stringify({
        action: isVendorSyncCronsOff ? 'on' : 'off',
        disableTimeInHour: 0.5,
      }),
      mode: 'cors',
    };
    await request(options);
    await getVendorCronStatus();
    setLoading(false);
    setLastUpdatedAt(Date.now());
  };

  const toggleMaintenanceMode = async () => {
    if (!window.confirm(`Are you sure to toggle the maintenance mode for ${env}?`)) {
      return;
    }
    setLoading(true);
    const options = {
      api: `/api/environment/maintenance`,
      method: 'POST',
      body: JSON.stringify({
        status: isInMaintenanceMode ? 0 : 1,
        message: currentMessage,
      }),
      mode: 'cors',
    };
    await request(options);
    setCountdown(Date.now() + 3 * 1000);
    setLoading(false);
  };

  // const cleanup = async () => {
  //   if (!window.confirm(`Are you sure to cleanup ${env}?`)) {
  //     return;
  //   }
  //   setLoading(true);
  //   const options = {
  //     api: `/api/environment/cleanup`,
  //     method: 'DELETE',
  //     mode: 'cors',
  //   };
  //   await request(options);
  //   setLoading(false);
  // };

  useEffect(() => {
    getConfig();
    getVendorCronStatus();
  }, []);

  return (
    <Fragment>
      <Grid container className="environment-container" justify="center" spacing={2}>
        <Grid item xs={12}>
          <Paper className="panel">
            <Typography variant="h5">
              Environment: {env.toUpperCase()}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper className="panel">
            <Typography variant="h6" color={ isInMaintenanceMode ? 'error' : 'primary'}>
              Maintenance Mode is { isInMaintenanceMode ? 'ON': 'OFF' }
            </Typography>
            <Button variant="outlined" size="small" disabled={loading} onClick={toggleMaintenanceMode}>
              Turn {isInMaintenanceMode?'Off':'On'} Maintenance Mode
            </Button>
            { countdown &&
              <p>
                Waiting for API Gateway to purge the config cache. <Countdown date={countdown} onComplete={()=>{
                  setCountdown(undefined);
                  return getConfig();
                }} />
              </p>
            }
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper className="panel">
            <Typography variant="h6" color={ isVendorSyncCronsOff ? 'error' : 'primary'}>
              Vendor Sync Crons are { !isVendorSyncCronsOff ? 'ON': 'OFF' }
            </Typography>
            <Button variant="outlined" size="small" disabled={loading} onClick={toggleVendorSyncCrons}>
              Turn {isVendorSyncCronsOff ? 'On':'Off'} Vendor Sync Crons
            </Button>
            { vendorSyncCronCountdown &&
              <p>
                Vendor Sync Crons will be re-enabled in <Countdown date={vendorSyncCronCountdown} onComplete={() => {
                  setTimeout(()=>{
                    setVendorSyncCronCountdown(undefined);
                    getVendorCronStatus();
                  }, 3000);
                }} />
              </p>
            }
          </Paper>
        </Grid>

        <CronJob lastUpdatedAt={lastUpdatedAt} />
        <DatadogMonitors />

        <Grid item xs={12}>
          <Paper className="panel">
            <Typography variant="h6">Config</Typography>
            {config && <Code code={config}/>}
          </Paper>
        </Grid>
        {/* <Grid item xs={12}>
          <Paper className="panel">
            <Typography variant="h6">Tools</Typography>
            <Button variant="outlined" size="small" disabled={loading} onClick={cleanup}>
              Remove incomplete Data
            </Button>
          </Paper>
        </Grid> */}
      </Grid>
    </Fragment>);
}
