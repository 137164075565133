import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import FormGroup from '@material-ui/core/FormGroup';
import IconButton from '@material-ui/core/IconButton';
import SyncIcon from '@material-ui/icons/Sync';
import TextField from '@material-ui/core/TextField';
import { DebounceInput } from 'react-debounce-input';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import KanbanBoard from './KanbanBoard';
import { request } from '../utils';
import { caseStatus, caseSources } from './constants';

import './Csr.css';

const useStyles = makeStyles((theme) => ({
  main: {
    overflow: 'hidden',
    height: '100%',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
    height: 24,
    minWidth: 10,
  },
  selectFormControl: {
    margin: 0,
    marginRight: 8,
    marginTop: 10,
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, items, theme) {
  return {
    fontWeight: items.indexOf(name) === -1 ?
      theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  };
}

const Csr = ({ history }) => {
  const classes = useStyles();
  const theme = useTheme();

  const [targetStatus, setTargetStatus] = useState(caseStatus.filter((x) => x !== 'Closed'));
  const [cases, setCases] = useState([]);
  const [filteredCases, setFilteredCases] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [targetSources, setTargetSources] = useState(caseSources);
  const [isLoading, setIsLoading] = useState(false);

  const getCasesByStatus = async (inStatus) => {
    const options = {
      api: '/api/customerCases',
      query: {
        status: inStatus,
      },
      method: 'GET',
      mode: 'cors',
    };
    const res = await request(options);
    return res.data;
  };

  const load = useCallback(async (inTargetStatus) => {
    setIsLoading(true);
    let data = [];
    await Promise.all(inTargetStatus.map(async (item) => {
      const result = await getCasesByStatus(item);
      data = [...data, ...result];
    }));

    setCases(data);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await load(targetStatus);
    })();
  }, [targetStatus, load]);

  useEffect(() => {
    if (!searchText || searchText === '') {
      setFilteredCases(cases.filter(({ source }) => targetSources.includes(source)));
    } else {
      setFilteredCases(cases
        .filter(({ source }) => targetSources.includes(source))
        .filter((item) => {
          return Object.keys(item).some((key) => {
            if (typeof item[key] === 'string') {
              return item[key].toLowerCase().includes(searchText.toLowerCase());
            } else {
              return false;
            }
          });
        }));
    }
  }, [searchText, cases, targetSources]);

  return (
    <Grid container className={classes.main} id="csr-page">
      <Grid item xs={12} style={{ padding: 8, paddingLeft: 16, backgroundColor: '#fff' }}>
        <FormGroup row>
          <div>
            <IconButton
              color="inherit"
              onClick={async () => {
                await load(targetStatus);
              }}
              aria-label="open"
              style={{ marginRight: 16 }}
              disabled={isLoading}
            >
              {isLoading ? <CircularProgress size={20} /> : <SyncIcon /> }
            </IconButton>
          </div>
          <FormControl
            className={classes.selectFormControl}
            margin="dense"
            variant="outlined"
          >
            <InputLabel id="status-multiple-label">Status</InputLabel>
            <Select
              labelId="status-multiple"
              id="status-multiple"
              multiple
              value={targetStatus}
              onChange={(e)=> setTargetStatus(e.target.value)}
              input={<Input id="select-multiple-status" />}
              renderValue={(selected) => (
                <div className={classes.chips}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} className={classes.chip} />
                  ))}
                </div>
              )}
              disabled={isLoading}
              MenuProps={MenuProps}
            >
              {caseStatus.map((name) => (
                <MenuItem key={name} value={name} style={getStyles(name, targetStatus, theme)}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl
            className={classes.selectFormControl}
            margin="dense"
            variant="outlined"
          >
            <InputLabel id="status-multiple-label">Source</InputLabel>
            <Select
              labelId="source-multiple"
              id="source-multiple"
              multiple
              value={targetSources}
              onChange={(e)=> setTargetSources(e.target.value)}
              input={<Input id="select-multiple-source" />}
              renderValue={(selected) => (
                <div className={classes.chips}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} className={classes.chip} />
                  ))}
                </div>
              )}
              disabled={isLoading}
              MenuProps={MenuProps}
            >
              {caseSources.map((name) => (
                <MenuItem key={name} value={name} style={getStyles(name, targetStatus, theme)}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <DebounceInput
            debounceTimeout={300}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            element={TextField}
            id={`search-text`}
            label=""
            value={searchText}
            type={'text'}
            placeholder="Search ..."
            variant="outlined"
            margin="dense"
            disabled={isLoading}
          />
        </FormGroup>
      </Grid>
      <Grid item xs={12} style={{ width: 100 }}>
        <KanbanBoard
          cases={filteredCases}
          targetStatus={targetStatus}
        />
      </Grid>
    </Grid>
  );
};

Csr.propTypes = {
  history: PropTypes.object.isRequired,
};

export default Csr;
