import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { request } from '../utils';
import CodeTable from './CodeTable';
import HistoryTable from './HistoryTable';
import CreateProgramModal from './CreateProgramModal';
import CreateCodeModal from './CreateCodeModal';

export default function Program({
  data: inData,
  programId: inProgramId,
  match,
  onUpdate = () => {},
}) {
  const [programId, setProgramId] = useState(inProgramId);
  const [data, setData] = useState(inData);

  useEffect(() => {
    if (programId) {
      (async () => {
        const options = {
          api: `/api/promotion/program/${programId}`,
          method: 'GET',
          mode: 'cors',
        };
        const { data } = await request(options);
        setData(data);
      })();
    } else
    if (inData) {
      setData(inData);
    }
  }, [programId, inData]);

  useEffect(() => {
    if (match && match.params && match.params.id) {
      setProgramId(match.params.id);
    } else {
      const params = new URLSearchParams(window.location.search);
      const id = params.get('programId');
      if (id) {
        setProgramId(id);
      }
    }
  }, [match]);

  if (!data) return null;

  return (
    <div style={{ padding: 16 }}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant="h5">
            {data.title}
          </Typography>
        </Grid>
        <Grid item xs={6} align="right">
          <CreateProgramModal
            data={data}
            onUpdate={onUpdate}/>
          <CreateCodeModal
            program={data}
            onUpdate={onUpdate}/>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CodeTable
            programId={data.id}
            onUpdate={onUpdate}
            nested={true}
          />
        </Grid>
        <Grid item xs={12}>
          <HistoryTable
            program={data}
            nested={true}
          />
        </Grid>
      </Grid>
    </div>);
}

Program.propTypes = {
  data: PropTypes.object,
  programId: PropTypes.string,
  match: PropTypes.object,
  onUpdate: PropTypes.func,
};
