import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import YAML from 'json-to-pretty-yaml';
import Tooltip from '@material-ui/core/Tooltip';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import yaml from 'react-syntax-highlighter/dist/esm/languages/hljs/yaml';
import json from 'react-syntax-highlighter/dist/esm/languages/hljs/json';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import FileCopy from '@material-ui/icons/FileCopy';

import { CopyToClipboard } from 'react-copy-to-clipboard';

import { sortObject } from '../utils';
import JsonViewer from './JsonViewer';

// https://github.com/conorhastings/react-syntax-highlighter/blob/HEAD/AVAILABLE_LANGUAGES_HLJS.MD
SyntaxHighlighter.registerLanguage('javascript', yaml);
SyntaxHighlighter.registerLanguage('javascript', json);

const Code = ({ code = {}, defaultLanguage = 'yaml', hideSelect = false }) => {
  const [language, setLanguage] = useState(defaultLanguage);
  const [string, setString] = useState('');

  useEffect(() => {
    console.log(code);
    const sortedCode = sortObject(code);

    switch (language) {
    case 'yaml':
      setString(YAML.stringify(sortedCode));
      break;
    case 'json':
      setString(JSON.stringify(sortedCode, null, 2));
      break;
    case 'list':
      setString(sortedCode);
      break;
    case 'text':
    default:
      if (typeof sortedCode === 'string') {
        setString(JSON.parse(JSON.stringify(sortedCode)));
      } else {
        setString(JSON.stringify(sortedCode));
      }
      break;
    }
  }, [code, language]);

  const updateLanguage = ({ target: { value } }) => {
    setLanguage(value);
  };

  return (
    <React.Fragment>
      {!hideSelect &&
        <Grid container>
          <FormControl component="fieldset">
            <RadioGroup row aria-label="position" name="position" defaultValue={language}>
              <FormControlLabel value="yaml" control={<Radio color="primary" />} onChange={updateLanguage} label="YAML" />
              <FormControlLabel value="json" control={<Radio color="primary" />} onChange={updateLanguage} label="JSON" />
              <FormControlLabel value="text" control={<Radio color="primary" />} onChange={updateLanguage} label="TEXT" />
              <FormControlLabel value="list" control={<Radio color="primary" />} onChange={updateLanguage} label="LIST" />
            </RadioGroup>
          </FormControl>
          <Tooltip title="Copy to Clipboard">
            <CopyToClipboard text={string}>
              <IconButton component="span">
                <FileCopy />
              </IconButton>
            </CopyToClipboard>
          </Tooltip>
        </Grid>}

      {language === 'list' && typeof string !== 'string' &&
        <JsonViewer jsonObject={string} />}
      {language !== 'list' && typeof string === 'string' &&
        <SyntaxHighlighter language={language} style={{ ...docco, width: '100%' }}>
          {string}
        </SyntaxHighlighter>}
    </React.Fragment>
  );
};

Code.propTypes = {
  code: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
  ]),
  defaultLanguage: PropTypes.string,
  hideSelect: PropTypes.bool,
};

export default Code;
