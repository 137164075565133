import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';

import Table from '../components/Table';
import Code from '../components/Code';

import './Configuration.css';
import {
  request,
  sortBy,
  tableCellTimeRenderer,
} from '../utils';

export default function Configuration() {
  const [data, setData] = useState([]);

  useEffect(() => {
    (async () => {
      const options = {
        api: `/api/configuration`,
        method: 'GET',
        mode: 'cors',
      };
      const { data } = await request(options);
      setData(data.sort(sortBy('name', false)));
    })();
  }, []);

  const headers = [
    {
      name: 'type',
      label: 'Type',
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        display: false,
        filter: false,
      },
    },
    {
      name: 'arn',
      label: 'Arn',
      options: {
        display: false,
        filter: false,
      },
    },
    {
      name: 'key',
      label: 'Key',
      options: {
        filter: false,
        customBodyRender: (value = '') => {
          return value.split('/').pop();
        },
      },
    },
    {
      name: 'value',
      label: 'Value',
      options: {
        filter: false,
        customBodyRender: (value = '') => {
          try {
            const updatedValue = JSON.parse(value);
            if (typeof updatedValue === 'object') {
              return <Code code={updatedValue} hideSelect={true} />;
            } else {
              return value;
            }
          } catch (e) {
            return value;
          }
        },
      },
    },
    {
      name: 'version',
      label: 'Version',
      options: {
        filter: false,
      },
    },
    {
      name: 'updatedAt',
      label: 'Updated At',
      options: {
        filter: false,
        customBodyRender: tableCellTimeRenderer(true),
      },
    },
    {
      name: 'lastUsedAt',
      label: 'Last Used At',
      options: {
        filter: false,
        customBodyRender: tableCellTimeRenderer(true),
      },
    },
  ];

  const onItemClick = (rowData, rowMeta) => {
    const item = data[rowMeta.dataIndex];
    console.log(item);
  };

  const options = {
    filterType: 'checkbox',
    onRowClick: onItemClick,
    rowsPerPageOptions: [100, 500, 1000],
    rowsPerPage: 100,
  };

  return (
    <Grid item xs={12}>
      <Table
        title={'Configuration'}
        data={data}
        columns={headers}
        options={options}
      />
    </Grid>);
}
