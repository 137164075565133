import React, { useState, useEffect } from 'react';

import VerticalTabs from 'components/Tab/VerticalTabs';
import { request } from 'utils';

import Vendor from './Vendor';

const VendorList = () => {
  const [tabs, setTabs] = useState();

  useEffect(() => {
    (async () => {
      const options = {
        api: `/api/vendors`,
        method: 'GET',
        mode: 'cors',
      };
      const { data } = await request(options);
      console.log(data);

      const tabs = data.map((vendor) => {
        return {
          label: vendor.name.toUpperCase(),
          component: (<Vendor vendor={vendor} />),
        };
      });
      setTabs(tabs);
    })();
  }, []);

  if (!tabs) return null;

  return (<VerticalTabs tabs={tabs} />);
};

export default VendorList;
