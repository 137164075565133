const getSampleReport = () => {
  return { current: 0, previous: 0 };
};

export const getChartDataMappings = ({
  data = [],
  prevData = [],
}) => {
  const summary = {
    newAccounts: getSampleReport(),
    newVehicles: getSampleReport(),
    deletedVehicles: getSampleReport(),
    blockedVehicles: getSampleReport(),
    unblockedVehicles: getSampleReport(),
    newTrips: getSampleReport(),
    newAssetTransactions: getSampleReport(),
    newNotifications: getSampleReport(),
    newCustomerCases: getSampleReport(),
    newPaymentTransactions: getSampleReport(),
    paymentTransactionsType: {},
    paymentTransactionsStatus: {},
    assetTransactionsCategory: {},
    vendorAvailability: [],
    vendorVehicleSync: [],
    assetGroups: {},
  };

  [data, prevData].forEach((items, index) => {
    items.forEach((item) => {
      const {
        accounts,
        vehicles,
        trips,
        assetTransactions,
        notifications,
        customerCases,
        paymentTransactions,
        vendors,
        assets,
        states,
      } = JSON.parse(item.data);
      const key = index === 0 ? 'current' : 'previous';

      summary.newAccounts[key] += accounts.count;
      summary.newVehicles[key] += vehicles.add;
      summary.deletedVehicles[key] += vehicles.remove;
      summary.blockedVehicles[key] += vehicles.blocked;
      summary.unblockedVehicles[key] += (vehicles.unblocked || 0);
      summary.newTrips[key] += trips.count;
      summary.newAssetTransactions[key] += assetTransactions.count;
      summary.newNotifications[key] += notifications.count;
      summary.newCustomerCases[key] += customerCases.count;
      summary.newPaymentTransactions[key] += paymentTransactions.count;

      Object.keys(paymentTransactions.type).map((key) => {
        summary.paymentTransactionsType[key] = summary.paymentTransactionsType[key] || {
          value: 0,
        };
        summary.paymentTransactionsType[key].value++;
      });

      Object.keys(paymentTransactions.chaseProcStatus).map((key) => {
        summary.paymentTransactionsStatus[key] = summary.paymentTransactionsStatus[key] || {
          value: 0,
        };
        summary.paymentTransactionsStatus[key].value++;
      });

      Object.keys(assetTransactions.category).map((key) => {
        summary.assetTransactionsCategory[key] = summary.assetTransactionsCategory[key] || {
          value: 0,
        };
        summary.assetTransactionsCategory[key].value++;
      });

      summary.vendorVehicleSync.push({
        name: `GoToll`,
        add: vehicles.add,
        removeOrBlocked: (vehicles.remove + vehicles.blocked),
        pending: 0,
        failed: 0,
      });

      Object.keys(vendors).map((vendorName) => {
        const { accounts } = vendors[vendorName];
        Object.keys(accounts).map((accountName) => {
          const { activeVehicles, maxVehicles, add, remove, pending, failed } = accounts[accountName];
          summary.vendorAvailability.push({
            name: `${vendorName}/${accountName}`,
            usage: (activeVehicles / maxVehicles * 100).toFixed(0),
            activeVehicles,
            maxVehicles,
          });

          summary.vendorVehicleSync.push({
            name: `${vendorName}/${accountName}`,
            add,
            removeOrBlocked: remove,
            pending,
            failed,
          });
        });
      });

      Object.keys(assets).forEach((assetId) => {
        const [key] = assetId.split('-');
        summary.assetGroups[key] = summary.assetGroups[key] || 0;
        summary.assetGroups[key]++;
      });

      summary.states = states || {};
    });
  });

  // process data
  const chartDataMappings = {
    newAccounts: {
      type: 'summary',
      width: 3,
      title: 'New Accounts',
      value: summary.newAccounts.current,
      prevValue: summary.newAccounts.previous,
    },
    newVehicles: {
      type: 'summary',
      width: 3,
      title: 'New Vehicles',
      value: summary.newVehicles.current,
      prevValue: summary.newVehicles.previous,
    },
    deletedVehicles: {
      type: 'summary',
      width: 3,
      title: 'Deleted Vehicles',
      value: summary.deletedVehicles.current,
      prevValue: summary.deletedVehicles.previous,
    },
    unblockedVehicles: {
      type: 'summary',
      width: 3,
      title: 'Unblocked Vehicles',
      value: summary.unblockedVehicles.current,
      prevValue: summary.unblockedVehicles.previous,
    },
    blockedVehicles: {
      type: 'summary',
      width: 3,
      title: 'Blocked Vehicles',
      value: summary.blockedVehicles.current,
      prevValue: summary.blockedVehicles.previous,
    },
    newTrips: {
      type: 'summary',
      width: 3,
      title: 'New Trips',
      value: summary.newTrips.current,
      prevValue: summary.newTrips.previous,
    },
    newAssetTransactions: {
      type: 'summary',
      width: 3,
      title: 'New asset tx',
      value: summary.newAssetTransactions.current,
      prevValue: summary.newAssetTransactions.previous,
    },
    newNotifications: {
      type: 'summary',
      width: 3,
      title: 'New notifications',
      value: summary.newNotifications.current,
      prevValue: summary.newNotifications.previous,
    },
    newCustomerCases: {
      type: 'summary',
      width: 3,
      title: 'New cases',
      value: summary.newCustomerCases.current,
      prevValue: summary.newCustomerCases.previous,
    },
    space: {
      type: 'summary',
      width: 9,
      title: 'Placeholder',
      value: 0,
      prevValue: 0,
    },
    // newPaymentTransactions: {
    //   type: 'summary',
    //   width: 3,
    //   title: 'New payments',
    //   value: summary.newPaymentTransactions.current,
    //   prevValue: summary.newPaymentTransactions.previous,
    // },
    paymentTransactionsType: {
      type: 'pie',
      title: `Payment Transactions Type`,
      width: 4,
      data: summary.paymentTransactionsType,
    },
    paymentTransactionsStatus: {
      type: 'pie',
      title: `Payment Transactions Status`,
      width: 4,
      data: summary.paymentTransactionsStatus,
    },
    assetTransactionsCategory: {
      type: 'pie',
      title: `Asset Tx Category`,
      width: 4,
      data: summary.assetTransactionsCategory,
    },
    vendorAvailability: {
      type: 'bar',
      title: 'Vendor account availability',
      width: 12,
      mode: 'grouped', // 'stacked',
      // dataKeys: ['usage'],
      dataKeys: ['activeVehicles', 'maxVehicles'],
      data: summary.vendorAvailability,
    },
    vendorVehicleSync: {
      type: 'bar',
      title: 'Vendor vehicle sync',
      width: 12,
      mode: 'grouped',
      dataKeys: ['add', 'removeOrBlocked', 'pending', 'failed'],
      data: summary.vendorVehicleSync,
    },
    states: {
      type: 'bar',
      title: 'Asset Transactions by State',
      width: 12,
      mode: 'stacked',
      dataKeys: ['transactions'],
      data: Object.keys(summary.states || {})
        .map((name) => {
          return {
            name,
            transactions: summary.states[name],
          };
        })
        .sort((a, b) => a.transactions > b.transactions ? -1 : 1)
        .filter((x) => x.transactions !== 0),
    },
    assets: {
      type: 'bar',
      title: 'Asset Txs',
      width: 12,
      mode: 'stacked',
      dataKeys: ['transactions'],
      data: Object.keys(summary.assetGroups)
        .map((name) => {
          return {
            name,
            transactions: summary.assetGroups[name],
          };
        })
        .sort((a, b) => a.transactions > b.transactions ? -1 : 1)
        .filter((x) => x.transactions !== 0),
    },
  };

  return chartDataMappings;
};
