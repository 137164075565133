import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DetailForm from 'react-material-final-form';
import to from 'await-to-js';

import { request, formatCurrency } from '../utils';

const metadata = {
  fields: [{
    key: 'tripId',
    type: 'String',
    label: 'Trip ID',
    isDisabled: true,
  }, {
    key: 'amount',
    type: 'String',
    label: 'Refund Amount ($ - USD)',
    isRequired: true,
  }, {
    key: 'description',
    type: 'String',
    label: 'Note',
    isRequired: true,
  }],
};

export default function RefundTripModal({ tripId, cost = {}, transactions = [], onUpdate }) {
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  // const [data, setData] = useState({
  //   tripId,
  //   amount: formatCurrency(cost.total).replace('$', ''),
  //   description: '',
  // });
  const data = {
    tripId,
    amount: formatCurrency(cost.total).replace('$', ''),
    description: '',
  };

  const submit = async (values) => {
    setIsLoading(true);

    const options = {
      api: `/api/trip/${tripId}/refund`,
      query: {
        amountInDollars: values.amount,
        description: values.description,
      },
      method: 'GET',
      mode: 'cors',
    };
    const [err, res] = await to(request(options));

    setIsLoading(false);

    if (err) {
      console.log(err);
    } else {
      console.log(res);
      onUpdate && onUpdate();
      setOpen(false);
    }
  };

  return (
    <Fragment>
      <Button
        className="space"
        variant="outlined"
        color="secondary"
        size="small"
        onClick={() => setOpen(true)}
      >
        Refund
      </Button>
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={open}
        onClose={()=>setOpen(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Refund Trip
        </DialogTitle>
        <DialogContent>
          <DetailForm
            metadata={metadata}
            data={data}
            isLoading={isLoading}
            usePristine={false}
            onSubmit={submit}
            submitButtonText={'Refund'}
            submitButtonProps={{
              variant: 'contained',
              color: 'primary',
              type: 'submit',
              fullWidth: true,
            }}
          />
        </DialogContent>
      </Dialog>
    </Fragment>
  );
}

RefundTripModal.propTypes = {
  tripId: PropTypes.string,
  cost: PropTypes.shape({
    total: PropTypes.number,
    admin: PropTypes.number,
    trip: PropTypes.number,
  }),
  transactions: PropTypes.array,
  onUpdate: PropTypes.func,
};
