import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import moment from 'moment';

import Table from './Table';
import {
  tableCellTimeRenderer,
  tableCellStatusRenderer,
  tableRenderer,
  sortBy,
  request,
} from '../utils';
import CreateCodeModal from './CreateCodeModal';
import CreateCreditModal from './CreateCreditModal';
import HistoryTable from './HistoryTable';
import CreditTable from './CreditTable';

export default class CodeTable extends Component {
  state = {
    env: window.localStorage.getItem('env'),
    canEdit: true, // window.localStorage.getItem('env') !== 'prd',
    data: [],
  }

  componentDidMount() {
    this.load();
  }

  load = () => {
    if (this.props.data) return;
    if (!this.props.programId) return;

    return Promise.resolve()
      .then(() => {
        const options = {
          api: `/api/promotion/program/${this.props.programId}/codes`,
          method: 'GET',
          mode: 'cors',
        };
        return request(options);
      })
      .then((res) => {
        this.setState({
          data: res.data,
        });
      })
      .catch(console.log);
  }
  render() {
    const headers = [{
      name: 'programId',
      label: 'Program ID',
      options: {
        filter: false,
      },
    }, {
      name: 'code',
      label: 'code',
      options: {
        filter: false,
      },
    }, {
      name: 'title',
      label: 'Title',
      options: {
        filter: false,
      },
    }, {
      name: 'description',
      label: 'Description',
      options: {
        filter: false,
        display: false,
      },
    }, {
      name: 'dateStart',
      label: 'Start',
      options: {
        filter: false,
        customBodyRender: tableCellTimeRenderer(true),
      },
    }, {
      name: 'dateEnd',
      label: 'End',
      options: {
        filter: false,
        customBodyRender: tableCellTimeRenderer(true),
      },
    }, {
      name: 'usage',
      label: 'Usage',
      options: {
        filter: false,
        customBodyRender: tableRenderer('center'),
      },
    }, {
      name: 'usesMaxPerAccount',
      label: 'Max per Account',
      options: {
        filter: false,
        customBodyRender: tableRenderer(),
      },
    }, {
      name: 'creditType',
      label: 'Type',
      options: {
        customBodyRender: tableRenderer('center'),
      },
    }, {
      name: 'creditValue',
      label: 'Value',
      options: {
        filter: false,
        customBodyRender: tableRenderer(),
      },
    }, {
      name: 'creditUseDuration',
      label: 'Duration (days)',
      options: {
        customBodyRender: (value) => {
          return (
            <Typography variant="body2" align={'right'}>
              {`${moment.duration(value, 'milliseconds').asDays()}`}
            </Typography>);
        },
      },
      // }, {
      //   name: 'accountIds',
      //   label: 'Account Ids',
      //   options: {
      //     filter: false,
      //     display: false,
      //   }
    },
    {
      name: 'ignoreTripTime',
      label: 'Ignore Trip Time',
      options: {
        filter: true,
        customBodyRender: tableCellStatusRenderer({
          0: 2,
          1: 1,
        }),
      },
    },
    {
      name: 'assetIds',
      label: 'Roadways',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value = []) => {
          return `${value.sort((a, b) => a > b ? 1 : -1).join(', ')}`;
        },
      },
    },
    {
      name: 'itemUpdatedAt',
      label: 'Updated At',
      options: {
        filter: false,
        customBodyRender: tableCellTimeRenderer(true),
      },
    }, {
      name: 'itemCreatedAt',
      label: 'Created At',
      options: {
        filter: false,
        customBodyRender: tableCellTimeRenderer(true),
      },
    }];

    const data = (this.props.data || this.state.data);

    const onItemClick = (rowData, rowMeta) => {
      const item = data[rowMeta.dataIndex];
      console.log(item);
    };

    const options = {
      filterType: 'checkbox',
      onRowClick: onItemClick,
      rowsPerPageOptions: [10, 50, 100],
      rowsPerPage: 10,
      expandableRows: true,
      renderExpandableRow: (rowData, rowMeta) => {
        console.log('renderExpandableRow', rowData, rowMeta);
        const item = data[rowMeta.dataIndex];

        return (
          <TableRow>
            <TableCell colSpan={headers.length + 1} className="nested-table-container">
              {this.state.canEdit &&
              <Grid container>
                <Grid item xs={12}>
                  <CreateCodeModal
                    data={item}
                    onUpdate={()=>{
                      if (this.props.onUpdate) {
                        this.props.onUpdate();
                      }
                    }}/>
                  <CreateCreditModal
                    code={item}
                    onUpdate={()=>{
                      if (this.props.onUpdate) {
                        this.props.onUpdate();
                      }
                    }}/>
                </Grid>
              </Grid>}
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <CreditTable
                    code={item}
                    nested={true} />
                </Grid>
                <Grid item xs={12}>
                  <HistoryTable
                    code={item}
                    nested={true} />
                </Grid>
              </Grid>
            </TableCell>
          </TableRow>
        );
      },
    };

    data
      .sort(sortBy('itemCreatedAt', true))
      .sort(sortBy('itemUpdatedAt', true))
      .map((item) => {
        item.usage = `${item.countUses}/${item.usesMax}`;
        return item;
      });

    return (
      <Grid item xs={12}>
        <Table
          title={this.props.title || 'Codes'}
          data={data}
          columns={headers}
          options={options}
          nested={this.props.nested}
        />
      </Grid>);
  }
}
