import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import {
  sortBy,
  tableCellAccountIdRenderer,
  tableCellTimeRenderer,
  tableCellStatusRenderer,
} from '../utils';
import Table from './Table';
import AccountUpdateTable from './AccountUpdateTable';

const AccountTable = ({ title = 'Account', data = [], disabledNav, onRefresh, nested }) => {
  const headers = [{
    name: 'accountId',
    label: 'ID',
    options: {
      filter: false,
      customBodyRender: tableCellAccountIdRenderer(),
    },
  }, {
    name: 'mobile',
    label: 'Mobile',
    options: {
      filter: false,
    },
  }, {
    name: 'oktaId',
    label: 'Okta ID',
    options: {
      filter: false,
    },
  }, {
    name: 'status',
    label: 'Status',
    options: {
      customBodyRender: tableCellStatusRenderer({
        ACTIVE: 1,
        BLOCKED: 2,
      }),
    },
  }, {
    name: 'firstName',
    label: 'First Name',
    options: {
      filter: false,
    },
  }, {
    name: 'lastName',
    label: 'Last Name',
    options: {
      filter: false,
    },
  }, {
    name: 'email',
    label: 'email',
    options: {
      filter: false,
    },
  }, {
    name: 'zipCode',
    label: 'Zip Code',
    options: {
      filter: false,
    },
  }, {
    name: 'platform',
    label: 'Platform',
    options: {
      filter: false,
    },
  }, {
    name: 'deviceInfo',
    label: 'Device',
    options: {
      filter: false,
      display: true,
    },
  }, {
    name: 'appInfo',
    label: 'App',
    options: {
      filter: false,
      display: true,
    },
  }, {
    name: 'deactivatedAt',
    label: 'Deactivated At',
    options: {
      display: false,
      filter: false,
      customBodyRender: tableCellTimeRenderer(false),
    },
  }, {
    name: 'itemUpdatedAt',
    label: 'Updated At',
    options: {
      display: false,
      filter: false,
      customBodyRender: tableCellTimeRenderer(false),
    },
  }, {
    name: 'createdDate',
    label: 'Created At',
    options: {
      filter: false,
      customBodyRender: tableCellTimeRenderer(false),
    },
  }, {
    name: 'accountId',
    label: 'View',
    options: {
      filter: false,
      display: (disabledNav) ? false : true,
      customBodyRender: tableCellAccountIdRenderer('View'),
    },
  }];

  data
    .filter((item) => item)
    .sort(sortBy('createdDate', true))
    .sort(sortBy('updatedDate', true))
    .map((item) => {
      if (item.device) {
        item.deviceInfo = `${item.device.model} (${item.device.version})`;
      }
      if (item.app) {
        item.appInfo = `${item.app.version} (${item.app.build})`;
      }

      headers.forEach((header) => {
        item[header.name] = (item[header.name] !== undefined) ? item[header.name] : '';
      });

      return item;
    });

  const onItemClick = (rowData, rowMeta) => {
    const item = data[rowMeta.dataIndex];
    console.log(item);
  };

  const options = {
    filterType: 'checkbox',
    onRowClick: onItemClick,
    rowsPerPageOptions: [10, 50, 100],
    rowsPerPage: 10,
    expandableRows: true,
    renderExpandableRow(rowData, rowMeta) {
      const item = data[rowMeta.dataIndex];
      console.log(item);

      return (
        <TableRow>
          <TableCell colSpan={headers.length + 1} className="nested-table-container">
            <AccountUpdateTable accountId={item.accountId} />
          </TableCell>
        </TableRow>
      );
    },
  };

  return (
    <Grid item xs={12}>
      <Table
        title={title}
        data={data}
        columns={headers}
        options={options}
        onRefresh={onRefresh}
        nested={nested}
      />
    </Grid>);
};

AccountTable.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
  disabledNav: PropTypes.bool,
  onRefresh: PropTypes.func,
  nested: PropTypes.bool,
};

export default AccountTable;
