import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Table from './Table';
import {
  sortBy,
  request,
} from '../utils';

export default function AgencyTable({ data: inData }) {
  const [data, setData] = useState([]);

  const load = async () => {
    const options = {
      api: `/api/agency`,
      method: 'GET',
      mode: 'cors',
    };
    const { data } = await request(options);
    return data;
  };

  const onItemClick = (rowData, rowMeta) => {
    const item = data[rowMeta.dataIndex];
    console.log(item);
  };

  const headers = [{
    name: 'primaryVendorId',
    label: 'Primary Vendor ID',
    options: {
      filter: true,
    },
  }, {
    name: 'id',
    label: 'ID',
    options: {
      filter: false,
      display: true,
    },
  }, {
    name: 'name',
    label: 'Name',
    options: {
      filter: false,
    },
  }, {
    name: 'display',
    label: 'Display',
    options: {
      filter: false,
    },
  }, {
    name: 'etc',
    label: 'ETC',
    options: {
      filter: false,
    },
  }, {
    name: 'timeZone',
    label: 'Time Zone',
    options: {
      filter: true,
      sort: true,
    },
  }, {
    name: 'refAgencyId',
    label: 'Reference Agency ID',
    options: {
      filter: false,
    },
  }];

  const options = {
    filterType: 'checkbox',
    onRowClick: onItemClick,
    rowsPerPageOptions: [10, 50, 100],
    rowsPerPage: 10,
  };

  useEffect(() => {
    (async () => {
      const data = inData || await load();
      setData(data);
    })();
  }, [inData]);


  return (
    <Table
      title={'Agencies'}
      data={data.sort(sortBy('id', true))}
      columns={headers}
      options={options}
      nested={true}
    />);
}

AgencyTable.propTypes = {
  data: PropTypes.array,
};
