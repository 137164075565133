import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { ResponsivePie } from '@nivo/pie';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    boxShadow: 'none',
  },
  value: {
    margin: theme.spacing(1),
  },
}));

export default function PieCard({ title, data: inData, data2: inData2 }) {
  const classes = useStyles();

  let data = inData;
  let data2 = inData2;
  if (data && !Array.isArray(data)) {
    data = Object.keys(data).map((key) => {
      const { value, color } = data[key];
      return { id: key, label: key, value, color };
    });
  }

  if (data2 && !Array.isArray(data2)) {
    data2 = Object.keys(data2).map((key) => {
      const { value, color } = data[key];
      return { id: key, label: key, value, color };
    });
  }

  const colorFunc = data[0] && data[0].color ? ({ color }) => color : { scheme: 'nivo' };

  // console.log({ data, data2 });

  return (
    <Paper className={classes.container}>
      <Typography variant="h5" component="h5">
        {title}
      </Typography>
      <Grid container align="center" justifyContent="center">
        <div style={{ width: '100%', height: 300 }}>
          <ResponsivePie
            data={data}
            margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
            pixelRatio={1}
            innerRadius={0.5}
            padAngle={0.7}
            cornerRadius={3}
            colors={colorFunc}
            borderColor={{ from: 'color', modifiers: [['darker', 0.6]] }}
            enableRadialLabels={false}
            radialLabelsSkipAngle={10}
            radialLabelsTextXOffset={6}
            radialLabelsTextColor="#fff"
            radialLabelsLinkOffset={0}
            radialLabelsLinkDiagonalLength={16}
            radialLabelsLinkHorizontalLength={24}
            radialLabelsLinkStrokeWidth={1}
            radialLabelsLinkColor={{ from: 'color' }}
            enableSlicesLabels={false}
            slicesLabelsSkipAngle={10}
            slicesLabelsTextColor="#333333"
            animate={true}
            motionStiffness={90}
            motionDamping={15}
            // legends={[
            //   {
            //     anchor: 'right',
            //     direction: 'column',
            //     translateX: 140,
            //     itemWidth: 100,
            //     itemHeight: 14,
            //     itemsSpacing: 2,
            //     symbolSize: 14,
            //     symbolShape: 'circle',
            //   },
            // ]}
          />
        </div>
      </Grid>
    </Paper>);
}


PieCard.propTypes = {
  title: PropTypes.string,
  data: PropTypes.any,
  data2: PropTypes.any,
};
