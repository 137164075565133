import React, { useState, useEffect, Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import VehicleTable from '../components/VehicleTable';

import {
  request,
} from '../utils';

export default function VehicleSearch() {
  const [loading, setLoading] = useState(false);
  const [lpnState, setLpnState] = useState();
  const [vehicles, setVehicles] = useState([]);

  const getVehicleDetails = async () => {
    if (!lpnState) return;

    setLoading(true);
    // const env = window.localStorage.getItem('env');
    const options = {
      api: '/api/queryVehiclesByLpnByTimestamp',
      method: 'GET',
      mode: 'cors',
      query: {
        vehicleLpnState: lpnState,
      },
    };
    const res = await request(options);

    setVehicles(res.data);
    setLoading(false);
  };

  useEffect(() => {}, []);

  return (
    <Fragment>
      <Grid container style={{ padding: 16 }}>
        <Grid item xs={12}>
          <Paper className="panel">
            <TextField
              fullWidth
              id={`lpnState`}
              label={`License Plate`}
              value={lpnState}
              margin="normal"
              onChange={(e)=>{
                console.log(e);
                setLpnState(e.target.value);
              }}
            />
            <Button
              variant="outlined"
              size="small"
              disabled={loading || !lpnState}
              onClick={getVehicleDetails}
            >
              Query
            </Button>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <VehicleTable
            data={vehicles}
            nested={true}
          />
        </Grid>
      </Grid>
    </Fragment>);
}
