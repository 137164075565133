import React, { Component, Fragment } from 'react';
import Grid from '@material-ui/core/Grid';

import Table from '../components/Table';
import { sortBy } from '../utils';

export default class RoadModel extends Component {
  state = {

  };

  componentDidMount() {}

  render() {
    const { title, data, onSearch } = this.props;
    return (<Fragment>
      {this.renderData(title, data, onSearch)}
    </Fragment>);
  }

  renderData(inTitle, inData, inOnSearch) {
    const data = inData.sort(sortBy('id'));

    const headers = Object.keys(data[0] || {}).map((key) => {
      return {
        name: key,
        label: key,
        options: {
          filter: false,
        },
      };
    });

    const onItemClick = (rowData, rowMeta) => {
      const item = data[rowMeta.dataIndex];
      console.log(item);
    };

    const options = {
      filterType: 'checkbox',
      onRowClick: onItemClick,
      rowsPerPageOptions: [10, 50, 100],
      rowsPerPage: 10,
      onSearchChange: (searchText) => {
        if (inOnSearch) {
          inOnSearch(searchText);
        }
      },
    };

    return (
      <Grid item xs={12}>
        <Table
          title={inTitle}
          data={data}
          columns={headers}
          options={options}
          nested={true}
        />
      </Grid>);
  }
}
