import React from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';

import VendorAgencyTable from 'components/VendorAgencyTable';
import VendorAccountTable from 'components/VendorAccountTable';

const Vendor = ({ vendor }) => {
  return (
    <Grid container spacing={2} style={{ paddingTop: 16, paddingRight: 16 }}>
      <Grid item xs={12}>
        <VendorAccountTable accounts={vendor.accounts}/>
      </Grid>
      <Grid item xs={12}>
        <VendorAgencyTable agencies={vendor.agencies}/>
      </Grid>
    </Grid>);
};

export default Vendor;

Vendor.propTypes = {
  vendor: PropTypes.object,
};
